import { MenuItem } from "../../../Presentational/SideBar/utils"

export type SidebarNames =
  'accounts' | 'live' | 'demo'
  | 'funds' | 'deposit' | 'transfer' | 'transaction_history' | 'bank_accounts' | 'withdraw'
  | 'downloads' | 'tools' | 'vps' | 'trading' | 'partnerships' | "economic_calendal" | "signal_centre"
  | 'support' | 'faq' | 'contact' | 'documents' | 'logout' | 'supportRequest'
  | 'promotions' | 'thinkcapital'
  | 'subscription' | 'thinkCopy' | 'traders_gym' | 'marketing_update' | 'refer_a_friend' | 'ib'

export type DashboardMenuItem = MenuItem<SidebarNames | 'separator'>

export function filterMenuItems(items: DashboardMenuItem[]): DashboardMenuItem[] {
  return items
    .filter(item => {
      return item.shouldDisplay ? item.shouldDisplay() : true
    })
    .map(item => {
      if (item.children) {
        return { ...item, children: filterMenuItems(item.children) }
      }
      return item
    })
}
