/* eslint-disable import/no-anonymous-default-export */
import React from 'react'

type LinkProps = {
    url: string
    target?: string
    download?: boolean
    children?: any
    className?: any
}

export default (props: LinkProps) => {
    return <a className={props.className} target={props.target ? props.target : "_blank"}
        rel="noopener noreferrer"
        onClick={(e) => e.stopPropagation()}
        download={props.download}
        href={props.url}>
        {props.children}
    </a>

}
