import cn from 'classnames'
import { useCallback, useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { appInit } from '../../../config'
import { useAppState } from '../../../utils/hooks'
import Utils from '../../../utils/utils'
import chatIcon from './icons/chat-icon.svg'
import { ReactComponent as ChatNowGreyIcon } from './icons/chat-now-grey.svg'
import chatNowIcon from './icons/chat-now.svg'
import { ReactComponent as CloseIcon } from './icons/close.svg'
import lineIcon from './icons/line.svg'
import telegramIcon from './icons/telegram.svg'
import { ReactComponent as TwitterIcon } from './icons/x-logo.svg'
import styles from './style.module.scss'
import { getZendeskKey, useSocialLinks } from './utils'




const scriptUrl = "https://static.zdassets.com/ekr/snippet.js"

export const useLiveChat = (showChat: boolean, vertical: boolean = true) => {
    const { language } = useAppState()
    const { t } = useTranslation()
    const [isOpened, setIsOpened] = useState(false)
    const socialLinks = useSocialLinks()

    useEffect(() => {
        const unlisten = window.addEventListener('toggleLiveChat', () => {
            setIsOpened(!isOpened)
        })
        return unlisten
    }, [isOpened])


    useEffect(() => {
        const initZendeskScript = async () => {
            await appInit
            const lang = getZendeskKey(language)
            const url = `${scriptUrl}?key=${lang.key}`
            Utils.loadScript(url, () => {
                window.zE('messenger:set', 'locale', lang.value || 'en')
                window.zE('messenger', 'close')
            }, "ze-snippet")
        }

        if (showChat) {
            initZendeskScript()
        }
    }, [language, showChat])

    const openZendeskLiveChat = useCallback(() => {
        if (window.zE && showChat) {
            window.zE('messenger', 'open')
            window.zE('messenger:set', 'locale', language || 'en')
            window.dataLayer?.push({
                'event': 'custom_event',
                'event_name': 'live_chat_clicked',
                'type_of_platform': 'zendesk'
            })
            setTimeout(() => {
                setIsOpened(false)
            }, (500))
        }
    }, [language, showChat])

    const zendeskChat = (
        <div className={styles.zendeskChat} onClick={openZendeskLiveChat}>
            <ChatNowGreyIcon className={cn(styles['social-icon'], styles['zendesk-icon'])} />
            <span className={styles.zendeskCaption}>{t('common:liveChat.title')}</span>
        </div>
    )

    const handleSocialLinkClick = (link: string, platform: string) => {
        window.dataLayer?.push({
            'event': 'custom_event',
            'event_name': 'social_link_click',
            'selected_social_link_options': platform
        })
        window.open(link, '_blank')
    }

    const SocialIcons = ({ className, wrapperClassName }: { className?: string, wrapperClassName?: string }) => (
        <>
            <div className={cn(styles['social-icon'], wrapperClassName)}>
                <img
                    onClick={() => handleSocialLinkClick(socialLinks.line, 'line')}
                    className={cn(className)}
                    src={lineIcon}
                    alt="line-icon"
                />
            </div>
            <div className={cn(styles['social-icon'], wrapperClassName)}>
                <img
                    onClick={() => handleSocialLinkClick(socialLinks.telegram, 'telegram')}
                    className={cn(className)}
                    src={telegramIcon}
                    alt="telegram-icon"
                />
            </div>
            <div className={cn(styles['social-icon'], wrapperClassName)}>
                <TwitterIcon
                    onClick={() => handleSocialLinkClick(socialLinks.twitter, 'twitter')}
                    className={cn(className)}
                />
            </div>
            {/* removed due to THPRT-5384 */}
            {/* <div className={cn(styles['social-icon'], wrapperClassName)}>
                <img
                    onClick={() => handleSocialLinkClick(socialLinks.facebook, 'facebook')}
                    className={cn(className)}
                    src={messengerIcon}
                    alt="mesenger-icon"
                />
            </div> */}
        </>
    )

    const chat = <div className={vertical
        ? styles.vertical
        : styles.horizontal
    } >
        <div className={cn(styles['social-links'], { [styles.opened]: isOpened })}>
            <CloseIcon
                onClick={() => setIsOpened(false)}
                className={styles['social-icon']}
            />
            <SocialIcons />
            <div className={styles['social-divider']} />
            <img
                onClick={openZendeskLiveChat}
                className={cn(styles['social-icon'], styles['chat-now'])}
                src={chatNowIcon}
                alt="chat-now-icon"
            />
        </div>
        <div onClick={() => setIsOpened(!isOpened)} className={cn(styles['live-chat-button'], { [styles.opened]: isOpened })}>
            <img className={styles['live-chat-icon']} src={chatIcon} alt="chat-icon" />
        </div>
    </div >

    const fixedLiveChat = showChat ? (
        <div className={cn(styles.fixed)}>
            {chat}
        </div>
    ) : null

    const floatLiveChat = showChat
        ? chat
        : null

    return { fixedLiveChat, floatLiveChat, SocialIcons, zendeskChat }
}


export default useLiveChat
