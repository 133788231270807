import React from 'react'
import { useTranslation } from 'react-i18next'
export const IndividualTMJP = () => {
    const { t } = useTranslation()
    const prefix = 'footer:individual.TMJP'
    return <>
        <p>
            <strong>{t(`${prefix}.retailTraders.header`)} </strong>
            {t(`${prefix}.retailTraders.text`)}
        </p>
        <p>
            <strong>{t(`${prefix}.profTraders.header`)} </strong>
            {t(`${prefix}.profTraders.text`)}
        </p>
        <p>
            <strong>{t(`${prefix}.company.header`)} </strong>
            {t(`${prefix}.company.text`)}
        </p>
        <p> {t(`${prefix}.text`)}</p>
    </>
}
