import cn from "classnames"
import { useState } from 'react'
import { Link } from 'react-router-dom'

import { useScreen } from '../../../utils/hooks'
import appRoutes from '../../../utils/routes'
import LastLogin from '../../Header/subHeaders/LastLogin'
import AppLogo from '../AppLogo'

import SideBarMenuItem from './MenuItem'
import SubMenu from './SubMenu'
import { MenuItem } from "./utils"

import styles from "./sidebar.module.scss"

export interface SideBarProps<T> {
  languagePrefix: string
  items: MenuItem<T>[]
  className?: string
  handleItemClick: (item: MenuItem<T>) => void
}

const SideBar = <T extends string>(props: SideBarProps<T>) => {
  const [openedMenu, setOpenedMenu] = useState<string | null>(null)
  const { languagePrefix, handleItemClick, items, className } = props
  const { isMobile } = useScreen()

  const handleTopMenuLinkClick = (item: MenuItem<T>) => {
    handleMenuItemClick(item)
    setOpenedMenu(null)
  }

  const handleMenuItemClick = (item: MenuItem<T>) => {
    handleItemClick(item)
  }

  return (
    <div className={styles.sidebar}>
      <div className={cn(styles.logo, { [styles.mobile]: isMobile })}>
        <Link to={appRoutes.dashboard.accounts.root}>
          <AppLogo condensed />
        </Link>
      </div>
      <div className={cn(styles.scroll, className)}>
        <div>
          {items.map((menuItem, index) => {
            if (menuItem.name === 'separator')
              return <div className={styles.separator} key={`separator-${index}`} />
            if (menuItem.children && menuItem.children.length > 0) {
              return (
                <SubMenu
                  key={menuItem.name}
                  item={menuItem}
                  languagePrefix={languagePrefix}
                  onMenuItemClick={() => handleMenuItemClick(menuItem)}
                  openedMenu={openedMenu}
                  setOpenedMenu={setOpenedMenu}
                >
                  {menuItem.children.map(nestedItem => (
                    <SideBarMenuItem
                      key={nestedItem.name}
                      item={nestedItem}
                      onMenuItemClick={() => handleMenuItemClick(nestedItem)}
                      parent={menuItem.name}
                      languagePrefix={languagePrefix}
                    />
                  ))}
                </SubMenu>
              )
            }
            return (
              <SideBarMenuItem
                key={menuItem.name}
                item={menuItem}
                onMenuItemClick={() => handleTopMenuLinkClick(menuItem)}
                parent=""
                languagePrefix={languagePrefix}
              />
            )
          })}
        </div>
      </div>
      <LastLogin />
    </div>
  )
}

export default SideBar
