import { FC, useCallback, useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'
import cn from 'classnames'
import { useHistory } from 'react-router'
import { useDispatch } from 'react-redux'

import api from '../../utils/api'
import Modal from '../Presentational/Modal'
import { useAppState } from '../../utils/hooks'
import { logout } from '../../redux/actions'
import Routes from '../../utils/routes'
import Utils from '../../utils/utils'
import { appError, setNextTermsAndConditions } from '../../redux/slices/app'
import { __STORAGE } from '../../utils/enums'

import styles from "./style.module.scss"

const ls = "dashboard.subscription.termsAndConditions."

const TermsAndConditions: FC = () => {
    const [isOpened, setIsOpened] = useState<boolean>(false)
    const { t } = useTranslation()
    const dispatch = useDispatch()
    const history = useHistory()
    const { nextTermsAndConditions, loggedIn, loggedOut } = useAppState()

    useEffect(() => {
        if (nextTermsAndConditions?.latestVersion) {
            setIsOpened(true)
        }
    }, [nextTermsAndConditions])

    useEffect(() => {
        if (!loggedIn || loggedOut) {
            setIsOpened(false)
        }
    }, [loggedIn, loggedOut])

    useEffect(() => {
        window.addEventListener('storage', (e) => {
            if (e.key === __STORAGE.tAc) {
                if (e.oldValue && !e.newValue) {
                    setIsOpened(false)
                }
            }
        })
    }, [dispatch, history])

    const handleLogoutClick = useCallback(() => {
        setIsOpened(false)
        Utils.deleteKey(__STORAGE.tAc)
        dispatch(logout())
        history.push(Routes.account.login)
    }, [dispatch, history])

    const handleAgreeClick = useCallback(async () => {
        try {
            if (!nextTermsAndConditions) return
            await api.agreeToLatestTermsAndConditions(nextTermsAndConditions.latestVersion)
            dispatch(setNextTermsAndConditions(null))
            Utils.deleteKey(__STORAGE.tAc)
        } catch (error) {
            dispatch(appError(Utils.customError(error.message)))
        }
        setIsOpened(false)
    }, [nextTermsAndConditions, dispatch])

    return isOpened ? (
        <Modal
            isOpen={isOpened}
            shouldCloseOnEsc={false}
            shouldCloseOnOverlayClick={false}
            title={t(`${ls}modalTitle`)}
        >
            <div className={cn(styles.content)}>
                <div className={cn(styles.consent)} dangerouslySetInnerHTML={{ __html: t(`${ls}consent`) }} />
                <div className={cn(styles.buttons)}>
                    <button
                        className={cn('link')}
                        onClick={handleLogoutClick}
                    >
                        {t(`${ls}logOut`)}
                    </button>
                    <button
                        className={cn('green')}
                        onClick={handleAgreeClick}
                    >
                        {t(`${ls}agree`)}
                    </button>
                </div>
            </div>
        </Modal>
    ) : null
}

export default TermsAndConditions
