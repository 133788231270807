import { FC, useEffect, useState } from "react"
import cn from 'classnames'

import styles from './style.module.scss'

interface ProgressBarProps {
    percents: number
    big?: boolean
    className?: string
    barColor?: string
    isLoading?: boolean
}

const ProgressBar: FC<ProgressBarProps> = ({ percents, className, big, barColor, isLoading = true }) => {
    return (
        <div className={cn(styles.progress, className, { [styles.big]: big })}>
            <div className={styles.background} />
            <div
                className={styles.indicator}
                style={{
                    width: percents * 100 + '%',
                    display: percents > 0 ? 'block' : 'none',
                    backgroundColor: barColor || '#14835b',
                }}
            >
                {isLoading && percents < 0.99 && <span></span>}
            </div>
        </div>
    )
}

const InfiniteProgressBar = () => {
    const [percents, setPercents] = useState(0)

    useEffect(() => {
        const interval = setInterval(() => {
            setPercents(p => (p + 10) > 100 ? 0 : p + 10)
        }, 500)
        return () => clearInterval(interval)
    }, [])

    return <div className={styles.progress}>
        <div className={styles.progressIndicator}
            style={{ width: percents + '%' }}>
        </div>
    </div>
}

export { ProgressBar, InfiniteProgressBar }
