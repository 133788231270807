import { createSlice, PayloadAction } from "@reduxjs/toolkit"
import moment from "moment"
import { appConfig } from "../../config"
import { BREAKPOINTS, PortalAccountDomain, ScreenSize, TradeAccountType, __STORAGE } from '../../utils/enums'
import { AccountStat, AppInfo, ApplicationApproval, ApplicationStatus, ApplicationType, AppropriatenessLevel, CreateTradingAccountParams, CurrencyPairRateResponse, FA2, finishedStatuses, GetIPResponse, KYCProvider, LatestTermsAndConditions, LoginResponse, MigrationInfo, NewTradeAccountInfo, Organization, OrganizationFeature, OrganizationSettings, Question, SubscriptionStatus, SupportRequestType, TradingAccountType, TradingPlatform, TradingPlatformName, UserApplication, UserProfile, UserReferrerStatus } from '../../utils/types'
import Utils from "../../utils/utils"
import { AppError, AppState } from '../appState'
import { Country, IBInfo, Notification, NotificationMap, Transaction } from './../../utils/types'
import * as ApiReducers from "./apiReducers"

const getScreenType = (width: number): ScreenSize => {
    switch (true) {
        case width < BREAKPOINTS.MOBILE:
            return ScreenSize.Mobile
        case width > BREAKPOINTS.DESKTOP:
            return ScreenSize.Desktop
        default:
            return ScreenSize.Tablet
    }
}

const initialState: AppState = {
    autoLogout: false,
    debugMode: false,
    busy: false,
    loggedIn: false,
    loggedOut: false,
    sessionChecked: undefined,
    initError: null,
    canCreateAccounts: true,
    language: localStorage.language ? localStorage.language : 'en',
    screen: getScreenType(window.innerWidth),
    rtl: localStorage.language ? localStorage.language === 'ar' : false,
    portalAccountDomain: PortalAccountDomain.Unknown,
    onboardingDomain: null,
    ibInfo: null,
    totalBalanceInUSD: 0,
    appStatus: null,
    oldAppStatus: null,
    appropriatenessLevel: null,
    applicationApproval: null,
    appToken: null,
    error: null,
    userIP: null,
    userLoginResult: "",
    userLoginInfo: null,
    userProfile: null,
    userApps: [],
    uncompletedApplication: {},
    newTradeAccountInfo: null,
    userCountry: undefined,
    demoPlatforms: [],
    livePlatforms: [],
    demoAccounts: [],
    liveAccounts: [],
    accountsLoaded: false,
    bankAccounts: null,
    countries: [],
    phoneCountries: [],
    docTypes: [],
    preferredLanguages: null,
    organizationSettings: null,
    otpCodeSent: false,
    isNewPortalAccount: false,
    pageTitle: null,
    passwordResetToken: null,
    userEmailVerified: false,
    userEmailPreverified: false,
    jumioFailed: false,
    showAppSidebar: false,
    notifications: [],
    notificationMap: [],
    twoFa: {
        enabled: false,
        checked: false,
        valid: false
    },
    creatingLive: false,
    hasCompletedApp: false,
    lastDeposit: null,
    lastWithdraw: null,
    liveAppCompleted: false,
    KYCProvider: null,
    migration: null,
    nextTermsAndConditions: null,
    isDemoExpired: false,
    isSubscriptionTerminated: false,
    isMigrationModalShown: false,
    newCreatedAccount: null,
    tradingTypes: null,
    questions: null,
    subscriptionStatus: 'noRecord',
    liveCongratsShown: false,
    originalExchangeRate: null,
    organizations: [],
    tradingAccountToCreate: "CFD",
    allowThinkInvest: true,
    redirectedFromWTR: false,
    additionalCFD: null,
    saveWtrFundsRedirection: false,
    showFA2Warning: false,
    wtrFundsRoute: '',
    syOrgCountries: [],
    showCryptoBanner: false,
    supportRequestTypes: [],
    blockedDepositAmountEnabled: false,
    userReferrerStatus: null,
    isUKSB: false,
    userInsidePortal: false,
    inactivityTimeout: 360,
    allowedPlatforms: undefined
}


let appState = initialState
if (localStorage[__STORAGE.userLoginInfo]) {
    const info = Utils.loadEncryptedObject<LoginResponse>(__STORAGE.userLoginInfo)
    if (Object.entries(info).length > 0)
        appState = { ...appState, userLoginInfo: info }
}

if (localStorage[__STORAGE.newAccount]) {
    const info = Utils.loadEncryptedObject<NewTradeAccountInfo>(__STORAGE.newAccount)
    appState = { ...appState, newTradeAccountInfo: info }
}

if (localStorage[__STORAGE.liveCongratsShown]) {
    const liveCongratsShown = Utils.getStorageBoolean(__STORAGE.liveCongratsShown)
    appState = { ...appState, liveCongratsShown: liveCongratsShown }
}

if (localStorage[__STORAGE.ukAccount]) {
    const value = Utils.getStorageBoolean(__STORAGE.ukAccount)
    appState = { ...appState, isUKSB: value }
}

const slice = createSlice({
    name: "app",
    initialState: appState,
    reducers: {
        debugMode: (state: AppState, action: PayloadAction<boolean>) => {
            state.debugMode = action.payload
        },
        changeScreen: (state: AppState, action: PayloadAction<ScreenSize>) => {
            state.screen = action.payload
        },
        changeLanguage: (state: AppState, action) => {
            state.language = action.payload === 'zh' ? 'zh-Hans' : action.payload
            state.rtl = action.payload === 'ar'
        },
        sessionChecked: (state: AppState, action) => {
            state.sessionChecked = true
            state.loggedIn = action.payload
        },
        setAppBusy: (state: AppState, action) => {
            state.busy = action.payload
        },
        setTempData: (state: AppState, action) => {
            state.tempData = action.payload
        },
        setPageTitle: (state: AppState, action: PayloadAction<string | null>) => {
            state.pageTitle = action.payload
        },
        initError: (state: AppState, action) => {
            state.initError = action.payload
        },
        appError: (state: AppState, action: PayloadAction<AppError | null>) => {
            const error = action.payload
            if ((error?.type === 'status' && error?.status === 'NOT_AUTHORIZED') ||
                (error?.type === 'custom' && error?.text === 'NOT_AUTHORIZED'))
                return
            state.error = action.payload
        },
        setMigration: (state: AppState, action: PayloadAction<MigrationInfo | null>) => {
            state.migration = action.payload
        },
        setNextTermsAndConditions: (state: AppState, action: PayloadAction<LatestTermsAndConditions | null>) => {
            state.nextTermsAndConditions = action.payload
        },
        setIsDemoExpired: (state: AppState, action: PayloadAction<boolean>) => {
            state.isDemoExpired = action.payload
        },
        setIsSubscriptionTerminated: (state: AppState, action: PayloadAction<boolean>) => {
            state.isSubscriptionTerminated = action.payload
        },
        userLoaded: (state: AppState, action: PayloadAction<UserProfile>) => {
            const profile = action.payload
            state.userProfile = profile
            if (!profile.preferredLanguage)
                profile.preferredLanguage = state.preferredLanguages![0]
            state.userProfile.password = Utils.loadEncryptedString(__STORAGE.u_pass)
            state.userCountry = profile?.country
            state.portalAccountDomain = profile.organization.name
            if (profile.additionalAttributes?.inactivityTimeout)
                state.inactivityTimeout = parseInt(profile.additionalAttributes.inactivityTimeout)
            else state.inactivityTimeout = appConfig.LOGOUT_AFTER_MIN
        },
        setUser: (state: AppState, action: PayloadAction<UserProfile>) => {
            if (!action.payload.preferredLanguage)
                action.payload.preferredLanguage = state.preferredLanguages![0]
            state.userProfile = action.payload
        },
        setNewPortalAccount: (state: AppState, action) => {
            state.isNewPortalAccount = action.payload
        },
        setSubscriptionStatus: (state: AppState, action: PayloadAction<SubscriptionStatus>) => {
            state.subscriptionStatus = action.payload
        },
        userAppsLoaded: (state: AppState, action: PayloadAction<UserApplication[]>) => {
            const apps = action.payload
            if (apps && apps.length) {

                const approved = apps.find(x => x.applicationStatus.toUpperCase() === 'APPROVED')
                if (approved) state.hasCompletedApp = true
                state.userApps = apps
            }
        },
        updateUserAppInfo: (state: AppState, action: PayloadAction<Partial<AppInfo>>) => {
            const { payload } = action
            state.uncompletedApplication = { ...state.uncompletedApplication, ...payload }
        },
        setUserAppInfo: (state: AppState, action: PayloadAction<Partial<AppInfo>>) => {
            const { payload } = action
            if (payload) {
                if (payload.token) {
                    state.appToken = payload.token
                    delete payload.token
                }
                //compatibility with old portal
                if (payload.accountType !== 'corporate') {
                    if (payload.selectedPlatform) payload.selectedPlatform = Utils.string2TradingPlatformName(payload.selectedPlatform)
                    // if (!payload.accountHolderCity && payload.formStep)
                    //     delete payload.formStep
                    if (!payload.accountHolderPhone && payload.accountHolderMobilePhone)
                        payload.accountHolderPhone = payload.accountHolderMobilePhone
                    if (!payload.accountHolderPhoneCode && payload.accountHolderMobilePhoneCode)
                        payload.accountHolderPhoneCode = payload.accountHolderMobilePhoneCode
                }
            }
            state.uncompletedApplication = payload
        },
        setNewTradeAccountInfo: (state: AppState, action) => {
            if (action.payload) {
                const info = { ...action.payload }
                if (!info.email) info.email = Utils.loadEncryptedString(__STORAGE.user)
                state.newTradeAccountInfo = info
                Utils.saveEncryptedObject(__STORAGE.newAccount, info)
            }
            else {
                state.newTradeAccountInfo = null
                localStorage.removeItem(__STORAGE.newAccount)
            }
        },
        docTypesLoaded: (state: AppState, action) => {
            state.docTypes = action.payload
        },
        setCreatingLive: (state: AppState, action) => {
            state.creatingLive = action.payload
        },
        setLastDeposit: (state: AppState, action: PayloadAction<Transaction | null>) => {
            state.lastDeposit = action.payload
            if (state.appStatus === 'APPROVED' && action.payload) state.liveAppCompleted = true
        },
        setLastWithdraw: (state: AppState, action: PayloadAction<Transaction | null>) => {
            state.lastWithdraw = action.payload
        },
        setLiveAppCompleted: (state: AppState, action: PayloadAction<boolean>) => {
            state.liveAppCompleted = action.payload
        },
        setAdditionalCFD: (state: AppState, action: PayloadAction<Partial<CreateTradingAccountParams> | null>) => {
            state.additionalCFD = action.payload
        },
        setOtpCodeSent: (state: AppState, action: PayloadAction<boolean>) => {
            state.otpCodeSent = action.payload
        },
        setKYCProvider: (state: AppState, action: PayloadAction<KYCProvider>) => {
            state.KYCProvider = action.payload
        },
        notificationsLoaded: (state: AppState, action: PayloadAction<Notification[]>) => {
            const active = action.payload.filter(n => n.active)
            try {
                const { notificationMap: map } = state
                if (state.notificationMap.length)
                    active.forEach(n => {
                        const m = map.find(m => m.id === n.notificationId)
                        n.message = m ? m.notification : 'Unknown notification'
                    })
                state.notifications = active
            } catch (e: any) { console.log(e) }
        },
        notificationMapLoaded: (state: AppState, action: PayloadAction<NotificationMap[]>) => {
            state.notificationMap = action.payload
        },
        dismissNotification: (state: AppState, action: PayloadAction<number>) => {
            state.notifications.splice(action.payload, 1)
        },
        setNotificationsRead: (state: AppState) => {
            state.notifications.forEach(n => n.read = true)
        },
        userEmailVerified: (state: AppState, action) => {
            state.userEmailVerified = action.payload
        },
        userEmailPreverified: (state: AppState, action) => {
            state.userEmailPreverified = action.payload
        },
        jumioFailed: (state: AppState, action) => {
            state.jumioFailed = action.payload
        },
        setShowAppSidebar: (state: AppState, action) => {
            state.showAppSidebar = action.payload
        },
        setRedirectedFromWTR: (state: AppState, action) => {
            state.redirectedFromWTR = action.payload
        },
        setSaveWtrFundsRedirection: (state: AppState, action) => {
            state.saveWtrFundsRedirection = action.payload
        },
        setWtrFundsRoute: (state: AppState, action) => {
            state.wtrFundsRoute = action.payload
        },
        setTwoFa: (state: AppState, action: PayloadAction<FA2>) => {
            state.twoFa = action.payload
            Utils.saveEncryptedObject(__STORAGE.fa2, state.twoFa)
        },
        initCountries: (state: AppState, action: PayloadAction<{
            ip?: GetIPResponse,
            countries: Country[]
        }>) => {
            const { ip, countries } = action.payload
            state.userIP = ip?.country_code ? ip : null
            state.countries = countries.filter(c => c.used)
            const country = state.countries.find(c => c.code2.toLowerCase() === ip?.country_code?.toLowerCase())

            if (country && !state.userCountry && !state.portalAccountDomain) {
                state.userCountry = country
                state.portalAccountDomain = country.organization.name
            }

            const orgs: Organization[] = []
            countries.forEach(c => {
                const exists = orgs.find(o => o.name === c.organization.name)
                if (!exists) orgs.push(c.organization)
            })
            state.phoneCountries = countries
            state.organizations = orgs
        },
        organizationSettingsLoaded: (state: AppState, action: PayloadAction<
            {
                settings: OrganizationSettings
                countryId?: number
            }>) => {
            state.organizationSettings = action.payload.settings

            const { organizationId } = action.payload.settings
            const countryId = action.payload.countryId
                ? action.payload.countryId : state.userProfile!.country.id

            const filtered = action.payload.settings
                .features.filter(f =>
                    f.organizations.includes(organizationId) &&
                    !f.hidden &&
                    (!f.countries.length || f.countries.includes(countryId))
                )

            const getPlatforms = (type: TradeAccountType,
                features: OrganizationFeature[]): TradingPlatform[] => {
                const platforms: TradingPlatform[] = []
                const filtered = features.filter(f => f.types.includes(type))
                filtered.forEach(f => {
                    f.platforms.forEach(name => {
                        if (!platforms.find(p => p.name === name))
                            if (state.allowedPlatforms === undefined ||
                                state.allowedPlatforms.find(p => p === name))
                                platforms.push(
                                    {
                                        name: name,
                                        accounts: []
                                    }
                                )
                    })
                })
                const accountTypes: TradingAccountType[] = []
                filtered.forEach(f => {
                    f.accountTypes.forEach(a => {
                        if (!accountTypes.includes(a))
                            accountTypes.push(a)
                    })
                })
                platforms.forEach(platform => {
                    const features = filtered.filter(f => f.platforms.includes(platform.name))
                    features.forEach(f => {
                        f.accountTypes.forEach(a => {
                            const account = platform.accounts.find(acc => acc.type === a)
                            //THPRT-5287 remove mini for demo
                            if (!account && !(type === TradeAccountType.Demo && a === 'Mini')) {
                                const sortedCurrencies = [...f.currencies].sort()
                                const sortedLeverages = [...f.leverages].sort((a, b) => a - b)
                                platform.accounts.push(
                                    {
                                        type: a,
                                        currencies: sortedCurrencies,
                                        leverages: sortedLeverages,
                                    }
                                )
                            }
                        })
                    })
                })
                return platforms
            }

            state.demoPlatforms = getPlatforms(TradeAccountType.Demo, filtered)
            state.livePlatforms = getPlatforms(TradeAccountType.Live, filtered)
        },
        setUserCountry: (state: AppState, action) => {
            state.userCountry = action.payload
        },
        setPortalAccountDomain: (state: AppState, action) => {
            state.portalAccountDomain = action.payload
        },
        setOnboardingDomain: (state: AppState, action: PayloadAction<PortalAccountDomain | null>) => {
            state.onboardingDomain = action.payload
        },
        setPreferredLanguages: (state: AppState, action) => {
            // temp workaround for zh lang
            const langs = action.payload.map((x: any) => {
                if (x.language_code === 'zh') {
                    return {
                        ...x,
                        language_code: 'zh-Hans'
                    }
                }
                return x
            })
            state.preferredLanguages = langs
        },
        setIB: (state: AppState, action: PayloadAction<IBInfo>) => {
            state.ibInfo = action.payload
        },
        setPasswordResetToken: (state: AppState, action) => {
            state.passwordResetToken = action.payload
        },
        setAppStatus: (state: AppState, action: PayloadAction<ApplicationStatus | null>) => {
            if (!state.appStatus || state.appStatus !== action.payload) {
                state.oldAppStatus = state.appStatus
                state.appStatus = action.payload
                if (action.payload)
                    if (['APPROVED', ...finishedStatuses].includes(action.payload))
                        state.hasCompletedApp = true
            }
        },
        setAccounts: (state: AppState, action: PayloadAction<AccountStat[]>) => {
            const process = (accounts: AccountStat[]) => {
                accounts.forEach(a => {
                    a.marginLevel = Utils.calculateMarginLevel(a)
                })

            }
            const demo = action.payload.filter(a => a.account.type.toUpperCase() === 'DEMO')
            process(demo)
            //THPRT-5552 shift expiration date
/*             demo.forEach(a => {
                let numberOfDaysRemaining = a.account.expires?.numberOfDaysRemaining || 0
                if (Math.abs(numberOfDaysRemaining) > 14) {
                    numberOfDaysRemaining += 76
                    if (numberOfDaysRemaining >= 0) {
                        a.account.expires = {
                            accountExpirationDate: moment().utc().format(),
                            numberOfDaysRemaining: 0,
                            isExpired: true
                        }
                    }
                    else {
                        a.account.expires = {
                            accountExpirationDate: moment().add(Math.abs(numberOfDaysRemaining), 'days').utc().format(),
                            numberOfDaysRemaining,
                            isExpired: false
                        }

                    }
                }
            })
 */            state.demoAccounts = demo

            const live = action.payload.filter(a => a.account.type.toUpperCase() === 'LIVE')
            state.totalBalanceInUSD = live.reduce((total, a) => {
                return total + a.balanceInUSD
            }, 0)
            process(live)
            state.liveAccounts = live
            //compatibility with legacy portal accounts
            if (!state.userApps.length && live.length > 0) {
                state.appStatus = "APPROVED"
                state.liveAppCompleted = true
            }
            state.accountsLoaded = true
        },
        clearAccounts: (state: AppState) => {
            state.accountsLoaded = false
        },
        setAppropriatenessLevel: (state: AppState, action: PayloadAction<AppropriatenessLevel>) => {
            state.appropriatenessLevel = action.payload
        },
        setApplicationApproval: (state: AppState, action: PayloadAction<ApplicationApproval>) => {
            state.applicationApproval = action.payload
        },
        setQuestions: (state: AppState, action: PayloadAction<Question[]>) => {
            state.questions = action.payload
        },
        canCreateAccounts: (state: AppState, action) => {
            state.canCreateAccounts = action.payload
        },
        setBankAccounts: (state: AppState, action) => {
            state.bankAccounts = action.payload
        },
        setSyOrgCountries: (state: AppState, action) => {
            state.syOrgCountries = action.payload
        },
        setAutoLogout: (state: AppState, action: PayloadAction<boolean>) => {
            state.autoLogout = action.payload
        },
        cleanupAppState: (state: AppState) => {
            return {
                ...initialState,
                debugMode: state.debugMode,
                userCountry: state.userCountry,
                userIP: state.userIP,
                portalAccountDomain: state.portalAccountDomain,
                countries: state.countries,
                organizations: state.organizations,
                phoneCountries: state.phoneCountries,
                passwordResetToken: state.passwordResetToken,
                docTypes: state.docTypes,
                preferredLanguages: state.preferredLanguages,
                language: state.language,
                initError: state.initError,
                autoLogout: state.autoLogout,
                loggedOut: state.loggedOut,
                userEmailPreverified: state.userEmailPreverified,
                NewTradeAccountInfo: state.newTradeAccountInfo,
                isSubscriptionTerminated: state.isSubscriptionTerminated,
                landingPage: state.landingPage,
                allowedPlatforms: state.allowedPlatforms,
                inactivityTimeout: state.inactivityTimeout
            }

        },
        showMigrationModal: (state: AppState, action) => {
            state.isMigrationModalShown = action.payload
        },
        setNewCreatedAccount: (state: AppState, action) => {
            state.newCreatedAccount = action.payload
        },
        setTradingTypes: (state: AppState, action) => {
            state.tradingTypes = action.payload
        },
        setAllowThinkInvest: (state: AppState, action) => {
            state.allowThinkInvest = action.payload
        },
        setTradingAccountTypeToCreate: (state: AppState, action) => {
            state.tradingAccountToCreate = action.payload
        },
        setApplicationToCreate: (state: AppState, action: PayloadAction<ApplicationType | undefined>) => {
            state.applicationToCreate = action.payload
        },
        setUserInsidePortal: (state: AppState, action) => {
            state.userInsidePortal = action.payload
        },
        setLiveCongratsShown: (state: AppState, action: PayloadAction<boolean>) => {
            state.liveCongratsShown = action.payload
            Utils.setStorageBoolean(action.payload, __STORAGE.liveCongratsShown)
        },
        setShowFA2Warning: (state: AppState, action: PayloadAction<boolean>) => {
            state.showFA2Warning = action.payload
        },
        setUKSB: (state: AppState, action: PayloadAction<boolean>) => {
            state.isUKSB = action.payload
            Utils.setStorageBoolean(action.payload, __STORAGE.ukAccount)
        },
        setOriginalExchangeRate: (state: AppState, action: PayloadAction<CurrencyPairRateResponse | null>) => {
            state.originalExchangeRate = action.payload
        },
        setSupportRequestTypes: (state: AppState, action: PayloadAction<SupportRequestType[]>) => {
            state.supportRequestTypes = action.payload
        },
        setInactivityTimeout: (state: AppState, action: PayloadAction<number>) => {
            state.inactivityTimeout = action.payload
        },
        setBlockedDepositAmountEnabled: (state: AppState, action: PayloadAction<boolean>) => {
            state.blockedDepositAmountEnabled = action.payload
        },
        setUserReferrerStatus: (state: AppState, action: PayloadAction<UserReferrerStatus>) => {
            state.userReferrerStatus = action.payload
        },
        setAllowedPlatforms: (state: AppState, action: PayloadAction<Array<TradingPlatformName> | undefined>) => {
            state.allowedPlatforms = action.payload
        },
        setLandingPage: (state: AppState, action: PayloadAction<{
            path: string
            query?: string
        } | undefined>) => {
            state.landingPage = action.payload
        },
    },
    extraReducers: {
        ...ApiReducers.loginReducers,
        ...ApiReducers.logoutReducers,
        ...ApiReducers.getBankAccounts,
        ...ApiReducers.appStatusReducers
    }
})

const { actions, reducer } = slice

export const { changeLanguage,
    debugMode,
    changeScreen,
    sessionChecked,
    initError,
    setAppBusy,
    setUserCountry,
    userAppsLoaded,
    updateUserAppInfo,
    setUserAppInfo,
    organizationSettingsLoaded,
    setPreferredLanguages,
    setAppStatus,
    canCreateAccounts,
    docTypesLoaded,
    setUser,
    appError,
    cleanupAppState,
    setNewPortalAccount,
    setNewTradeAccountInfo,
    setIB,
    setPageTitle,
    setTempData,
    setPasswordResetToken,
    userEmailVerified,
    userEmailPreverified,
    jumioFailed,
    setAppropriatenessLevel,
    setApplicationApproval,
    setShowAppSidebar,
    notificationsLoaded,
    notificationMapLoaded,
    setTwoFa,
    dismissNotification,
    setNotificationsRead,
    setLastDeposit,
    setLiveAppCompleted,
    setCreatingLive,
    setKYCProvider,
    setMigration,
    setNextTermsAndConditions,
    setIsDemoExpired,
    setIsSubscriptionTerminated,
    userLoaded,
    showMigrationModal,
    setNewCreatedAccount,
    setTradingTypes,
    initCountries,
    setAccounts,
    clearAccounts,
    setAutoLogout,
    setOtpCodeSent,
    setQuestions,
    setLiveCongratsShown,
    setOriginalExchangeRate,
    setPortalAccountDomain,
    setTradingAccountTypeToCreate,
    setAllowThinkInvest,
    setRedirectedFromWTR,
    setAdditionalCFD,
    setLastWithdraw,
    setSubscriptionStatus,
    setSaveWtrFundsRedirection,
    setWtrFundsRoute,
    setOnboardingDomain,
    setShowFA2Warning,
    setSyOrgCountries,
    setSupportRequestTypes,
    setBlockedDepositAmountEnabled,
    setUserReferrerStatus,
    setUKSB,
    setInactivityTimeout,
    setLandingPage,
    setUserInsidePortal,
    setApplicationToCreate,
    setAllowedPlatforms
} = actions

export default reducer
