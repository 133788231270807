import cn from 'classnames'
import { FC, useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { Frames, CardNumber, ExpiryDate, Cvv } from 'frames-react'
import { useDispatch } from 'react-redux'

import Loader from '../../../../Loader'
import { useAppState, useScrollToTop } from '../../../../../utils/hooks'
import Utils from '../../../../../utils/utils'
import Wrapper from '../../../../Presentational/ItemWrapper'
import { AccountLikeObject, ProcessorLikeObject, useInitiatePayment } from '../useInitiatePayment'
import { appError } from '../../../../../redux/slices/app'
import { Currency } from '../../../../../utils/types'
import { getCheckoutComPulicKey } from '../utils'
import { useThemes } from '../../../../../utils/themes'

import styles from "./style.module.scss"

const useColorsForFrames = () => {
    const { theme } = useThemes()
    switch (theme) {
        case 'light':
            return {
                'text': '#0e1d31',
                'input-border-color': '#9fa5ad',
                'background': '#f9f9f9',
                'input-border-color-focused': '#0e1d31',
                'error': '#df1a3c',
                'color-3': '#6e7783'
            }
        case 'dark':
            return {
                'text': '#f9f9f9',
                'input-border-color': '#878f93',
                'background': '#181f25',
                'input-border-color-focused': '#878f93',
                'error': '#d1425a',
                'color-3': '#cfd2d6'
            }
    }
}

const languageString = "dashboard.deposit.checkoutCom."
const CHECKOUT_COM_SCRIPT = 'https://cdn.checkout.com/js/framesv2.min.js'

interface CheckoutFramesProps {
    processor: ProcessorLikeObject
    account: AccountLikeObject
    amount: string
    currency: Currency
    isAmountFormValid: boolean
}

interface CardFormErrors {
    'card-number'?: boolean
    'expiry-date'?: boolean
    'cvv'?: boolean
}

const CheckoutFrames: FC<CheckoutFramesProps> = ({ processor, account, amount, currency, isAmountFormValid }) => {
    const { t } = useTranslation()
    const dispatch = useDispatch()
    const [scriptLoaded, setScriptLoaded] = useState<boolean>(false)
    const [isCheckoutReady, setIsCheckoutReady] = useState<boolean>(true)
    const [isCardFormValid, setIsCardFormValid] = useState<boolean>(false)
    const [isCardFormSubmitting, setIsCardFormSubmitting] = useState<boolean>(false)
    const [cardErrors, setCardErrors] = useState<CardFormErrors>({})
    const { portalAccountDomain } = useAppState()
    const themeColors = useColorsForFrames()

    useEffect(() => {
        Utils.loadScript(CHECKOUT_COM_SCRIPT, () => {
            setScriptLoaded(true)
        })
    }, [])

    useScrollToTop()

    const handleFormSubmitClick = async () => {
        const { token } = await Frames.submitCard()
        await initiatePayment({
            amount: {
                amount: amount,
                currency: currency,
            }
        }, undefined, undefined, {
            token: token,
            type: 'checkout',
        })
        setIsCardFormSubmitting(false)
    }

    const { initiatePayment, captchaModal } = useInitiatePayment(processor, account, handleFormSubmitClick)

    return (
        <div>
            {captchaModal}
            {(isCardFormSubmitting || !scriptLoaded || !isCheckoutReady) && <Loader />}

            {scriptLoaded && (
                <div className={styles['credit-card-section']}>
                    <Frames
                        config={{
                            publicKey: getCheckoutComPulicKey(portalAccountDomain),
                            localization: {
                                cardNumberPlaceholder: t(`${languageString}cardNumber.placeholder`),
                                expiryMonthPlaceholder: t(`${languageString}expiryDate.monthPlaceholder`),
                                expiryYearPlaceholder: t(`${languageString}expiryDate.yearPlaceholder`),
                                cvvPlaceholder: t(`${languageString}cvv.placeholder`),
                            },
                            style: {
                                base: {
                                    color: themeColors.text,
                                    fontSize: '18px',
                                    border: 'solid 1px',
                                    borderColor: themeColors['input-border-color'],
                                    borderRadius: '4px',
                                    padding: '12px 16px',
                                    backgroundColor: themeColors.background,
                                    width: '99.9%',
                                },
                                focus: {
                                    borderColor: themeColors['input-border-color-focused']
                                },
                                valid: {
                                    borderColor: themeColors['input-border-color'],
                                },
                                invalid: {
                                    borderColor: themeColors.error,
                                },
                                placeholder: {
                                    base: {
                                        color: themeColors['color-3'],
                                    },
                                },
                            },
                        }}
                        ready={() => setIsCheckoutReady(true)}
                        frameValidationChanged={(e) => {
                            const shouldShowError = e.isValid || e.isEmpty ? false : true
                            setCardErrors((prev) => ({ ...prev, [e.element]: shouldShowError }))
                        }}
                        cardValidationChanged={(e) => setIsCardFormValid(e.isValid)}
                        cardSubmitted={() => setIsCardFormSubmitting(true)}
                        cardTokenizationFailed={(e) => {
                            dispatch(appError(Utils.customError(e.message)))
                            setIsCardFormSubmitting(false)
                        }}
                    >
                        <Wrapper
                            name="cardNumber"
                            insideCaption={false}
                            caption={t(`${languageString}cardNumber.caption`)}
                            error={cardErrors['card-number'] && t(`${languageString}cardNumber.error`) as string}
                            showError={cardErrors['card-number']}
                        >
                            <CardNumber className={styles.field} />
                        </Wrapper>

                        <div className={styles['date-and-code']} >
                            <Wrapper
                                name="expiryDate"
                                insideCaption={false}
                                caption={t(`${languageString}expiryDate.caption`)}
                                error={cardErrors['expiry-date'] && t(`${languageString}expiryDate.error`) as string}
                                showError={cardErrors['expiry-date']}
                            >
                                <ExpiryDate className={styles.field} />
                            </Wrapper>

                            <Wrapper
                                name="cvv"
                                insideCaption={false}
                                caption={t(`${languageString}cvv.caption`)}
                                error={cardErrors['cvv'] && t(`${languageString}cvv.error`) as string}
                                showError={cardErrors['cvv']}
                            >
                                <Cvv className={styles.field} />
                            </Wrapper>
                        </div>

                    </Frames>
                </div>
            )}

            <button
                type="button"
                onClick={handleFormSubmitClick}
                disabled={!isAmountFormValid || isCardFormSubmitting || !isCardFormValid}
                className={cn('button', 'green', styles.button)}
            >
                {t(`${languageString}payNow`)}
            </button>
        </div>
    )
}

export default CheckoutFrames
