import Utils from "../../utils/utils"

export enum IB_SCREEN_BREAKPOINTS {
    MOBILE = 600,
    DESKTOP = 1200,
}

export const LINK_SEPARATOR = ','

export const DEFAULT_CURRENCY = 'USD'

export const HELP_CENTER_LINK = "https://support.thinkmarkets.com/hc/en-gb/categories/11612734181905-Partnerships"

export const IB_SUPPORT_EMAIL = "partnerships@thinkmarkets.com"

export const IB_PENDING_STATUSES = [
    'INCOMPLETE',
    'LEVEL1_APPROVED',
    'PENDING',
    'PENDING_ID_ADDRESS',
    'PENDING_ID',
    'PENDING_ADDRESS',
    'PENDING_PROPER_DOCUMENTS',
    'PENDING_E_VERIFICATION_RETRY',
    'PENDING_KYC',
    'MANUAL_DOCS',
]

export const IB_DENIED_STATUSES = [
    'DENIED',
]
