import React, { memo, useCallback, useMemo, useRef, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { useDispatch } from 'react-redux'
import { Link } from 'react-router-dom'
import { setNotificationsRead } from '../../../redux/slices/app'
import api from '../../../utils/api'
import { useAppState } from '../../../utils/hooks'
import routes from '../../../utils/routes'
import { Notification } from '../../../utils/types'
import Dropdown, { DropdownHandles } from '../../Dropdown'
import { Item } from '../../Notifications'
import SVG from '../../Presentational/SVG'
import styles from "./header.module.scss"



const NotificationsSubHeader = memo(() => {
    const { t } = useTranslation('notifications')
    const [listShown, setlistShown] = useState(false)
    const { notifications, appStatus } = useAppState()
    const dropdown = useRef<DropdownHandles>(null)
    const dispatch = useDispatch()

    const activeNotifications = useMemo<Notification[]>(() => {
        //TGD-3107: We need to remove notification with id=14 for green id. Dirty hack approved by Strahil
        return appStatus === 'APPROVED' ? notifications.filter(x => x.notificationId !== 14) : notifications
    }, [notifications, appStatus])

    const unread = useMemo(() => activeNotifications.reduce((prev, curr) => prev + (!curr.read ? 1 : 0), 0), [activeNotifications])

    const clear = useCallback(() => {
        api.clearNotifications()
        dispatch(setNotificationsRead())
    }, [dispatch])

    const iconComponent = useMemo(() => {
        return (
            <div className={styles.notificationsSubHeader} onClick={unread ? clear : undefined}>
                <SVG name='bell' />
                {unread > 0 && (
                    <div className={styles.bage}>
                        <span> {unread}</span>
                    </div>
                )}
                {listShown && <div className={styles.arrow} />}
            </div>
        )
    }, [clear, listShown, unread])

    const hideDropdown = useCallback(() => {
        dropdown.current?.hide()
        setlistShown(false)
    }, [])

    return activeNotifications.length ? (
        <Dropdown
            position="right"
            topOffset={10}
            ref={dropdown}
            iconComponent={iconComponent}
            onShow={() => setlistShown(true)}
            onHide={() => setlistShown(false)}
            contentClassName={styles.notifications}
        >
            <div className={styles['notifications-title']}>{t('notificationsTitle')}</div>
            <div className={styles.items}>
                {activeNotifications.map((n, i) => <Item key={i} item={n} onAction={hideDropdown} index={i} />)}
            </div>
            <div className={styles['notifications-footer']}>
                <Link to={routes.dashboard.notifications} onClick={hideDropdown}>
                    {t('viewAll')}
                </Link>
            </div>
        </Dropdown>
    ) : (
        <Dropdown
            position="right"
            topOffset={10}
            ref={dropdown}
            iconComponent={iconComponent}
            onShow={() => setlistShown(true)}
            onHide={() => setlistShown(false)}
            contentClassName={styles.empty}
        >
            <div>
                <SVG name='bell' />
                <div dangerouslySetInnerHTML={{ __html: t('noMessages') }} />
            </div>
        </Dropdown>
    )

})

export default NotificationsSubHeader