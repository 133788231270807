import React from 'react'
import PerfectScrollbar from 'react-perfect-scrollbar'
import 'react-perfect-scrollbar/dist/css/styles.css'
import { components } from 'react-select'
import { MenuListComponentProps, MenuProps } from "react-select/src/components/Menu"
import styles from './style.module.scss'

const Menu = (props: MenuProps<any>) => {
    const { menuSeparator = true } = props.selectProps
    return (
        <components.Menu {...props}>
            <>
                { menuSeparator && <div className={styles.menuSeparator} />}
                {props.children}
            </>
        </components.Menu>
    )
}

const MenuList = (props: MenuListComponentProps<any> & {
    scroll_height?: string
}) => {
    const scroll_height = props.scroll_height || '400px'
    return (
        <components.MenuList {...props}>
            <PerfectScrollbar options={{
                wheelSpeed: 2,
                wheelPropagation: false,
                swipeEasing: false,
                minScrollbarLength: 30
            }}
                style={{ maxHeight: scroll_height }}>
                {props.children}
            </PerfectScrollbar>
        </components.MenuList>
    )
}

export { MenuList, Menu }

