import React from 'react'
import { useTranslation } from 'react-i18next'
import { useDangerousHTML } from '../../utils/hooks'
export const IndividualUK = () => {
    const { t } = useTranslation()
    const euPrefix = 'footer:individual.TMEU'
    const { p } = useDangerousHTML()
    const prefix = 'footer:individual.UK'
    return <>
        <p>
            <strong>{t(`${prefix}.retailTraders.header`)} </strong>
            {t(`${prefix}.retailTraders.text`)}
        </p>
        <p>
            <strong>{t(`${prefix}.profTraders.header`)} </strong>
            {t(`${prefix}.profTraders.text`)}
        </p>
        <p>
            <strong>{t(`${prefix}.company.header`)} </strong>
            {t(`${prefix}.company.text`)}
        </p>
        {p(t(`${euPrefix}.cyprus`))}
        <p> {t(`${prefix}.text`)}</p>
    </>
}
