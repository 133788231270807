import React, { createContext, FC, useCallback, useContext, useEffect, useState } from "react"
import queryString from "query-string"

export type ColorTheme = 'light' | 'dark'

const ThemesContext = createContext<{
    theme: ColorTheme
    toggleTheme: (theme: ColorTheme) => void
}>({
    theme: 'light',
    toggleTheme: () => { }
})

const ThemeProvider: FC = ({ children }) => {
    const [theme, setTheme] = useState<ColorTheme>(localStorage.theme || 'light')
    const [externalTheme, setExternalTheme] = useState(false)

    const toggleTheme = useCallback((tm) => {
        if (!externalTheme) {
            localStorage.setItem("theme", tm)
            document.documentElement.setAttribute("theme", tm)
        }
    }, [externalTheme])

    useEffect(() => {
        const query = queryString.parse(window.location.search)
        let queryTheme = query.theme
        if (typeof queryTheme === 'string') {
            queryTheme = queryTheme.toLowerCase()
            if (queryTheme === 'light' || queryTheme === 'dark') {
                setExternalTheme(true)
                setTheme(queryTheme)
                document.documentElement.setAttribute("theme", queryTheme)
                return
            }
        }
        else {
            const config = { attributes: true, childList: false, subtree: false }
            const callback = (mutations: MutationRecord[]) => {
                for (const mutation of mutations) {
                    if (mutation.type === "attributes") {
                        if (mutation.attributeName === "theme") {
                            setTheme(document.documentElement.getAttribute("theme") as ColorTheme || 'light')
                        }
                    }
                }
            }

            const observer = new MutationObserver(callback)
            observer.observe(document.documentElement, config)
            const prefersDarkMode = window.matchMedia("(prefers-color-scheme:dark)").matches
            const tm = localStorage.theme
                ? localStorage.theme
                : prefersDarkMode
                    ? 'dark'
                    : 'light'
            toggleTheme(tm)
            return () => observer.disconnect()
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [])

    return (
        <ThemesContext.Provider value={{ theme, toggleTheme }}>
            {children}
        </ThemesContext.Provider>
    )

}
const useThemes = () => {
    return useContext(ThemesContext)
}
export { ThemeProvider, useThemes }

