import React, {
    PropsWithChildren, CSSProperties, memo, useState, useRef, forwardRef,
    useImperativeHandle
} from 'react'
import Modal from 'react-modal'
import ReactResizeDetector from 'react-resize-detector'
import cn from 'classnames'
import { useSelector, shallowEqual } from "react-redux"

import { Store } from "../../redux/store"
import { ScreenSize } from '../../utils/enums'

import styles from "./style.module.scss"

type DropdownProps = {
    className?: string
    style?: CSSProperties
    contentClassName?: string
    iconComponent?: JSX.Element
    position?: 'center' | 'right'
    topOffset?: number
    adjustMobileWidth?: boolean
    onShow?: () => void
    onHide?: () => void
}

const modalStyleBase = {
    content: {
        bottom: 'unset',
        padding: '24px 32px',
        borderRadius: '8px',
        backgroundColor: 'var(--card-background)',
        border: 'solid 1px transparent',
        boxShadow: '2px 2px 20px 0 rgb(0 0 0 / 8%)',
    },
    overlay: {
        backgroundColor: 'none',
        zIndex: 10
    }
}

export interface DropdownHandles {
    hide: () => void
    show: () => void
}

const Dropdown = memo(forwardRef<DropdownHandles, PropsWithChildren<DropdownProps>>(
    (props, ref) => {
        const [modalVisible, setModalVisible] = useState(false)
        const [modalPosition, setModalPosition] = useState<ClientRect>()
        const dropdownRef = useRef<HTMLDivElement>(null)

        const rtl = useSelector((state: Store) => state.App.rtl, shallowEqual)
        const screenSize = useSelector((state: Store) => state.App.screen, shallowEqual)
        const isMobile = screenSize === ScreenSize.Mobile

        useImperativeHandle(ref, () => ({
            hide: () => hideDropdown(),
            show: () => showDropdown()
        }))

        const showDropdown = () => {
            if (!dropdownRef || !dropdownRef.current)
                return
            setModalPosition(dropdownRef.current!.getBoundingClientRect())
            setModalVisible(true)
            props.onShow && props.onShow()
        }

        const hideDropdown = () => {
            props.onHide && props.onHide()
            setModalVisible(false)
        }


        const modalClassName = cn(
            props.contentClassName,
            { [styles.rtl]: rtl }
        )

        let content: Partial<ClientRect> = {}
        let modalWidth = undefined
        const { adjustMobileWidth = true } = props

        if (modalPosition) {
            content.top = modalPosition.top + (modalPosition.bottom - modalPosition.top)
            if (props.topOffset) content.top += props.topOffset
            switch (props.position) {
                case 'center':
                    content.left = undefined
                    content.right = window.innerWidth - modalPosition.right - 200 + modalPosition.width / 2
                    break
                case "right":
                    content.left = undefined
                    content.right = window.innerWidth - modalPosition.right - 100
                    break
                default:
                    if (!rtl) {
                        content.left = undefined
                        content.right = window.innerWidth - modalPosition.right
                    } else {
                        content.right = undefined
                        content.left = modalPosition.left
                    }
                    break
            }

            if (isMobile && adjustMobileWidth) {
                const mobileHeader = document.getElementById('mobile-header')
                modalWidth = mobileHeader?.clientWidth
                if (!rtl) {
                    content.right = 0
                } else {
                    content.left = 0
                }
            }
        }

        const onResize = () => {
            if (!dropdownRef || !dropdownRef.current)
                return
            setModalPosition(dropdownRef.current!.getBoundingClientRect())
        }

        const modalStyle = {
            ...modalStyleBase,
            content: {
                ...modalStyleBase.content,
                width: modalWidth,
                ...content
            }
        }

        return <div>
            <ReactResizeDetector
                handleWidth
                refreshMode="debounce"
                refreshRate={100}
                onResize={onResize} />

            <div ref={dropdownRef} onClick={showDropdown}>
                {props.iconComponent ? props.iconComponent : null}
            </div>

            <Modal
                isOpen={modalVisible}
                onRequestClose={hideDropdown}
                style={modalStyle}
            >
                <div
                    className={modalClassName}
                    onClick={_ => hideDropdown()}
                >
                    {props.children}
                </div>
            </Modal>
        </div>
    }))

export default Dropdown