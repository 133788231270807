import cn from 'classnames'
import { FC } from 'react'

import Modal from '../Modal'
import SVG, { SVGName } from '../SVG'

import styles from './style.module.scss'

interface ModalConfirmProps {
    isOpen: boolean
    title?: string
    showCross?: boolean
    icon?: SVGName
    iconClass?: string
    className?: string
    onModalClose?(): void
}

const ModalConfirm: FC<ModalConfirmProps> = ({ isOpen, title, className,
    showCross = true, onModalClose, iconClass, children, icon }) => {
    return (
        <Modal
            isOpen={isOpen}
            onModalClose={onModalClose}
            showCross={showCross}
            title={title}
        >
            <div className={cn(styles.content, className)}>
                {icon && <SVG name={icon} className={cn(styles.icon, iconClass)} />}
                {children}
            </div>
        </Modal>
    )
}

export default ModalConfirm
