import { configureStore } from "@reduxjs/toolkit"
import { combineReducers } from "redux"
import createSagaMiddleware, { Saga } from "redux-saga"
import { AppState } from "./appState"
import IBReducer, { IBState } from "../ib/redux/state"
import rootSaga, { onStartSaga } from "./sagas"
import * as ApiSlices from "./slices/api"
import { ApiSliceState } from "./slices/api"
import AppReducer from "./slices/app"
import DashboardReducer, { DashboardState } from "./slices/dashboard"
import LoginReducer, { LoginState } from "./slices/login"
import LoaderReducer, { LoaderState } from "./slices/loader"

const sagaMiddleware = createSagaMiddleware()

const middleware = [sagaMiddleware]

export type Store = {
    App: AppState
    IB: IBState
    UI: {
        Login: LoginState,
        Loader: LoaderState,
        Dashboard: DashboardState
    },
    Api: {
        Login: ApiSliceState
        Logout: ApiSliceState
        GetAccounts: ApiSliceState,
    }
}

const store = configureStore({
    reducer: {
        App: AppReducer,
        IB: IBReducer,
        UI: combineReducers({
            Login: LoginReducer,
            Loader: LoaderReducer,
            Dashboard: DashboardReducer,
        }),
        Api: combineReducers({
            Login: ApiSlices.login.reducer,
            Logout: ApiSlices.logout.reducer,
            GetAccounts: ApiSlices.getAccounts.reducer,
            GetBankAccounts: ApiSlices.getBankAccounts.reducer,
            ProcessorsByAccount: ApiSlices.getProcessorsByAccount.reducer,
            WithdrawalOptions: ApiSlices.getWithdrawalOptions.reducer,
            OrganizationSettings: ApiSlices.getOrganizationSettings.reducer,
        })
    },
    middleware
})

sagaMiddleware.run(rootSaga)
sagaMiddleware.run(onStartSaga as Saga)

export default store
