import React, { CSSProperties, useCallback, useMemo } from 'react'
import ReactCountryFlag from "react-country-flag"
import { useTranslation } from 'react-i18next'
import { shallowEqual, useDispatch, useSelector } from 'react-redux'
import ReactSelect, { components, SingleValueProps, StylesConfig } from 'react-select'
import { OptionProps } from "react-select/src/components/Option"
import { SelectComponentsProps } from "react-select/src/Select"
import { changeLanguage } from '../../../redux/slices/app'
import { Store } from '../../../redux/store'
import { ie11 } from '../../../utils/constants/browser'
import { Language, LanguageNames } from '../../../utils/enums'
import { SelectOption } from '../../../utils/types'
import Utils from '../../../utils/utils'
import Select, { SelectProps } from '../Select/index'
import styles from './style.module.scss'
import arFlag from '../../../resources/images/flags/ae.png'
import zhFlag from '../../../resources/images/flags/cn.png'
import csFlag from '../../../resources/images/flags/cz.png'
import deFlag from '../../../resources/images/flags/de.png'
import enFlag from '../../../resources/images/flags/en.png'
import esFlag from '../../../resources/images/flags/es.png'
import elFlag from '../../../resources/images/flags/gr.png'
import idFlag from '../../../resources/images/flags/id.png'
import itFlag from '../../../resources/images/flags/it.png'
import jaFlag from '../../../resources/images/flags/jp.png'
import msFlag from '../../../resources/images/flags/my.png'
import plFlag from '../../../resources/images/flags/pl.png'
import ptFlag from '../../../resources/images/flags/pt.png'
import thFlag from '../../../resources/images/flags/th.png'
import viFlag from '../../../resources/images/flags/vn.png'

const Country = (props: OptionProps<any>) => {
    let code = 'GB'
    switch (props.data.value) {
        case 'ru': code = 'RU'
            break
    }
    return <components.Option {...props}>
        <div className={styles.row}>
            <ReactCountryFlag
                countryCode={code} svg
                style={{
                    width: '2em',
                    height: '1em',
                    marginRight: '10px'
                }}
            />
            {props.label}
        </div>
    </components.Option>
}

const LangSelect = (props: Partial<SelectProps>) => {
    const dispatch = useDispatch()
    const language = useSelector(
        (state: Store) => ({
            name: LanguageNames[state.App.language as Language],
            value: state.App.language
        }),
        shallowEqual
    )
    const languages = useSelector(
        (state: Store) => state.App.preferredLanguages?.map(x => ({
            name: LanguageNames[x.language_code as Language],
            value: x.language_code
        })),
        shallowEqual
    )


    return <Select {...props}
        isSearchable={Utils.isTestBuild()}
        caption={props.caption}
        name="language" value={language}
        validation={false}
        onSelect={(name: string, option: SelectOption) => {
            dispatch(changeLanguage(option.value as Language))
        }}
        components={{ Option: Country }}
        // upgradeSelectStyles={upgradeSelectStyleToTransparent}
        options={languages || []} />
}

export const compactStyles = (): StylesConfig => {
    return {
        control: (provided: CSSProperties, state: SelectComponentsProps) => {
            const result = {
                ...provided,
                height: '32px',
                minHeight: '32px',
                width: '130px',
                fontSize: '16px',
                border: 'none',
                boxShadow: 'none',
                cursor: 'pointer',
                backgroundColor: 'transparent',
            }
            return result
        },

        menu: (provided: CSSProperties, state: SelectComponentsProps) => {
            const result: CSSProperties = {
                ...provided,
                borderRadius: '5px',
                boxShadow: '0 2px 2px 0 rgba(33, 120, 131, 0.3)',
                border: 'solid 1px var(--color-4)',
                backgroundColor: 'var(--background)',
                zIndex: 2,
                left: '20px',
                width: '150px'
            }
            return result
        },

        dropdownIndicator: (provided: CSSProperties, state: SelectComponentsProps) => {
            const result = {
                ...provided,
                color: 'var(--text) !important',
                padding: '5px 0 5px 5px'
            }

            return result
        },

        indicatorSeparator: (_: CSSProperties) => {
            return { display: 'none' }
        },

        singleValue: (provided: CSSProperties) => {
            return {
                ...provided,
                color: 'var(--text)',
                left: ie11 ? "20px" : "unset"
            }
        },

        valueContainer: (provided: CSSProperties) => {
            return {
                ...provided,
                height: '100%', paddingRight: '2px', justifyContent: 'flex-end', position: 'static'
            }
        },

        menuList: (provided: CSSProperties) => {
            return {
                ...provided,
                maxHeight: 'unset',
                padding: '8px 0',
            }
        },

        option: (provided: CSSProperties, state: SelectComponentsProps) => {
            const isTitle = state.data.value === "Title"
            const result = isTitle ? {
                ...provided,
                cursor: 'auto',
                padding: '5px 21px 10px',
                fontSize: '16px',
                fontWeight: 600,
                lineHeight: 1.2,
                color: 'var(--text)',
                borderBottom: '1px solid grey',
            } : {
                ...provided,
                cursor: 'pointer',
                padding: '6px 21px',
                fontSize: '16px',
                fontWeight: 300,
                lineHeight: 1.41,
                color: 'var(--text)',
                ':active': {
                    backgroundColor: 'transparent',
                },
            }

            if (state.isFocused) {
                result.backgroundColor = isTitle ? 'var(--background)' : 'var(--selection-hover)'
            }

            if (state.isSelected) {
                result.fontWeight = 600
                result.color = 'var(--selected-color)'
                result.backgroundColor = 'var(--selected-background)'
            }

            return result
        }
    }
}

interface IOption {
    name: string
    value: string
}

interface ValueProps {
    option: IOption
    valueClassName?: string
    hideBalance?: boolean
    isSelected?: boolean
}

interface CompactLangSelectProps {
    className?: string
}

const CompactLangSelect = ({ className }: CompactLangSelectProps) => {
    const { t } = useTranslation(['account'])
    const dispatch = useDispatch()

    const { language: storeLang, preferredLanguages } = useSelector((state: Store) => state.App)

    const flags: any = useMemo(() => ({
        arFlag,
        csFlag,
        deFlag,
        elFlag,
        enFlag,
        esFlag,
        itFlag,
        idFlag,
        jaFlag,
        msFlag,
        plFlag,
        ptFlag,
        thFlag,
        viFlag
    }), [])
    const Value = useCallback((props: ValueProps) => {
        const { option } = props
        const name = LanguageNames[option.value as Language]
        const flag = (option.value === 'zh-Hans' || option.value === 'zh-Hant') ? zhFlag : flags[option.value + 'Flag']
        return (
            <div className={styles.language} >
                {flag ?
                    <img src={flag} alt='Flag' className={styles.flags} /> : ''}{name ? name : option.name}
            </div> ?? ''
        )
    }, [flags])

    const Option = (props: OptionProps<IOption>) => {
        return (
            <components.Option {...props}>
                {Value({
                    isSelected: props.isSelected,
                    valueClassName: styles.option,
                    option: props.data
                })}
            </components.Option>
        )
    }

    const SingleValue = useMemo(() => (props: SingleValueProps<IOption>) => {
        return (
            <components.SingleValue {...props}>
                {Value({
                    valueClassName: styles.option,
                    option: props.data
                })}
            </components.SingleValue>
        )
    }, [Value])

    const language = useMemo(() => {
        return {
            name: LanguageNames[storeLang as Language],
            value: storeLang
        }
    }, [storeLang])

    const languages = useMemo(() =>
        [{
            name: t('login.selectLanguage'),
            value: "Title",
            isDisabled: true,
            isFixed: true
        }, ...(preferredLanguages?.map(x => {
            return {
                name: x.language_name,
                value: x.language_code
            }
        }).filter(l => LanguageNames[l.value as Language]) || [])]
        , [preferredLanguages, t])

    return <ReactSelect<SelectOption>
        isSearchable={false}
        styles={compactStyles()}
        value={language}
        className={className}
        id="language"
        getOptionLabel={option => option.name as any}
        components={{ Option: Option, SingleValue: SingleValue }}
        menuPlacement="auto"
        onChange={o => {
            dispatch(changeLanguage((o as SelectOption).value))
            setTimeout(() => {
                window.location.reload()
            }, (200))
        }}
        options={languages || []}
    />
}

export { LangSelect, CompactLangSelect }
