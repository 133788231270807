import React, { FC } from 'react'
import { useDispatch, useSelector } from "react-redux"
import { Link } from 'react-router-dom'
import { toggleSidebar } from "../../redux/slices/dashboard"
import { Store } from "../../redux/store"
import appRoutes from '../../utils/routes'
import * as Enums from '../../utils/enums'
import AppLogo from "../Presentational/AppLogo"
import styles from "./style.module.scss"

const MobileHeader: FC<{ sidebar?: boolean }> = ({ sidebar = true }) => {
    const dispatch = useDispatch()
    const store = useSelector((state: Store) => state)
    const { screen } = store.App

    //   dispatch(toggleSidebar())
    return screen === Enums.ScreenSize.Mobile ? (
        <div id="mobile-header" className={styles['mobile-header']}>
            <Link className={styles.logo} to={appRoutes.dashboard.accounts.root}>
                <AppLogo />
            </Link>
            {sidebar && <div className={styles.bars}
                onClick={() => dispatch(toggleSidebar())}>
                <div className={styles.menu} />
            </div>

            }
        </div >
    ) : null
}


export default MobileHeader
