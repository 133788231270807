import cn from "classnames"
import React, { FC, useMemo } from 'react'
import { useSelector } from "react-redux"
import { useLocation } from "react-router"
import { Store } from "../../redux/store"
import * as Enums from '../../utils/enums'
import { scrollRef } from "../../utils/scroll"
import AppErrorBoundary from "../Container/App/AppErrorBoundary"
import Footer from '../Footer'
import Header from "../Header"
import MobileHeader from "../MobileHeader"
import TopNavigation from "../TopNavigation"
import appRoutes from '../../utils/routes'
import styles from "./page.module.scss"
import Sticky from "../Presentational/Sticky"
import useLiveChat from "../Presentational/LiveChat"
interface PageLayoutProps {
  sidebar: React.ReactNode
}

const PageLayout: FC<PageLayoutProps> = ({ children, sidebar }) => {
  const store = useSelector((state: Store) => state)
  const { screen, redirectedFromWTR } = store.App
  const { showSidebar } = store.UI.Dashboard
  const location = useLocation()
  const isFundsPages = [appRoutes.dashboard.funds.transfer, appRoutes.dashboard.funds.withdraw, appRoutes.dashboard.funds.deposit]
    .includes(location.pathname)
  const WTRPageArray = ['account/', 'trade-summary', 'demo', 'create', 'accounts/new']
  const noSideBarPagesWTR = WTRPageArray.find(e => location.pathname.includes(e))
  const mobile = screen === Enums.ScreenSize.Mobile
  const { fixedLiveChat } = useLiveChat(true)

  const hideWtrSideBar = redirectedFromWTR && noSideBarPagesWTR

  const sbClasses = cn(styles.sidebar,
    {
      [styles.mobile]: mobile,
      [styles.open]: showSidebar
    },
    { [styles.wtr]: hideWtrSideBar })

  const { pageFooter, pageBanner } = useSelector((state: Store) => state.UI.Dashboard)

  const mainClasses = cn(styles.main,
    { [styles.mobile]: mobile },
    { [styles.wtr]: redirectedFromWTR })

  const content = useMemo(() => {
    return <div id="content" className={styles.content}>
      <div className={cn(styles.inner, { [styles.enlarged]: isFundsPages })}>
        {children}
      </div>
      {pageFooter && pageFooter}
      <Footer />
    </div>
  }, [children, isFundsPages, pageFooter])

  return (
    <div className={cn(styles['page-layout'], { [styles.mobile]: mobile })}>
      {!mobile && <div className={sbClasses}>
        {sidebar}
      </div>}
      {mobile && !redirectedFromWTR && <>
        <MobileHeader />
        <div className={sbClasses}>
          {sidebar}
        </div>
      </>}
      <div className={mainClasses} ref={scrollRef}>
        <AppErrorBoundary>
          <Sticky className={styles["page-top"]} stick={!mobile} position="top">
            {pageBanner}
            <Header />
            <TopNavigation />
          </Sticky>
          {content}
          {fixedLiveChat}
        </AppErrorBoundary>
      </div>
    </div>
  )
}


export default PageLayout
