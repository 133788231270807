import { FC, useEffect, useState } from 'react'
import queryString from "query-string"
import { useLocation } from 'react-router'
import api from '../../../../utils/api'
import { hideGlobalLoader } from '../../../GlobalLoader'
import GreenId from '.'

const GreenIdPage: FC<any> = () => {
    const location = useLocation()
    const [applicationID, setApplicationID] = useState<number | null>(null)
    const query = queryString.parse(location.search)

    useEffect(() => {
        hideGlobalLoader()
    }, [])

    useEffect(() => {
        const session = typeof query.session === 'string' ? query.session : null
        const token = typeof query.token === 'string' ? query.token : null
        const applicationID = typeof query.applicationID === 'string' ? +query.applicationID : null
        setTimeout(() => {
            if (session && token && applicationID) {
                api.setTFBOAuth(session, token)
                setApplicationID(applicationID)
            }
        }, 500)
    }, [query.session, query.token, query.applicationID])

    return applicationID ? (
        <GreenId applicationID={applicationID} isMobile />
    ) : null
}


export default GreenIdPage