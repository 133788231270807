import { FC } from 'react'
import Modal from '../Modal'
import SVG from '../SVG'
import styles from './style.module.scss'
interface ErrorModalProps {
  text?: string
  htmlFormattedText?: string
  type?: ModalType
  title?: string
  className?: string
  showCross?: boolean
  onClose?(): void
}

export type ModalType = 'error' | 'warning' | 'success' | "title"

const ErrorModal: FC<ErrorModalProps> = ({ onClose, text, htmlFormattedText, children, type = 'erorr',
  title, className, showCross = true }) => {

  const icon = (() => {
    switch (type) {
      case 'error':
        return <SVG name='errorOutline' className={styles.error} />
      case 'warning':
        return <SVG name='alert' className={styles.alert} />
      case 'success':
        return <SVG name='basicDone' className={styles.success} />
      case 'title':
        return <h2>{title}</h2>
    }
  })()
  return (
    <Modal
      isOpen
      showCross={showCross}
      className={className}
      title={title}
      onModalClose={onClose}
    >
      <div className={styles['error-modal']}>
        {icon}
        <div className={children ? styles.text : undefined}>
          {text}
          {htmlFormattedText && <span dangerouslySetInnerHTML={{ __html: htmlFormattedText }} />}
        </div>
        {children}
      </div>
    </Modal>
  )
}

export default ErrorModal
