import React, { useMemo, VFC } from 'react'
import { useTranslation } from 'react-i18next'
import StickyWarning from './StickyWarning'

export const IndividualTMCY: VFC<{ stickWarning?: boolean }> = ({ stickWarning }) => {
    const { t } = useTranslation()
    const prefix = 'footer:individual.TMCY'

    const warning = useMemo(() =>
        <p>
            <strong>{t(`${prefix}.retailTraders.header`)} </strong>
            <span dangerouslySetInnerHTML={{ __html: t(`${prefix}.retailTraders.text`) }} />
        </p>, [t])

    return <>
        {stickWarning
            ? <StickyWarning>
                {warning}
            </StickyWarning>
            : warning}
        <p>
            <strong>{t(`${prefix}.profTraders.header`)} </strong>
            {t(`${prefix}.profTraders.text`)}
        </p>
        <p>
            <strong>{t(`${prefix}.company.header`)} </strong>
            {t(`${prefix}.company.text`)}
        </p>
        <p> {t(`${prefix}.text`)}</p>
    </>
}
