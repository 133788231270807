import ReactTooltip from "react-tooltip"
import { RequestType } from "../components/Container/Dashboard/ClientRequests/NewSupportRequest"
import { Phone as PhoneInputData } from "../components/Presentational/PhoneInput"
import * as Enums from "./enums"
export * from '../ib/utils/types'

export interface ExposeValidation {
        validate(): boolean
        isValid(): boolean
}

export type StringFunc = () => string

export type SelectOption<T = any, N = string> = {
        name: N,
        value: T,
        hint?: React.ReactNode,
        note?: React.ReactNode,
        description?: React.ReactNode,
        hintPlace?: ReactTooltip.Place,
        disabled?: boolean,
        isDisabled?: boolean,
        isFixed?: boolean
}

export interface EmailAndPassword {
        email: string
        password: string
        recaptchaResponse?: string
}

export interface LoginActionParams extends EmailAndPassword {
        delay?: boolean
        keepLogged?: boolean
        firstLogin?: boolean
}

export type AppSubmitedParams = {
        appStatus: ApplicationStatus
        countryId: number
        applicationId: number
        account: NewCreatedAccount | null
}

export interface NewCreatedAccount {
        accountNumber: number
        id: number
        type: 'LIVE' | 'DEMO'
}

export interface ValidateEmailParams {
        email: string
}
export interface CreateTradingAccountParams {
        type: Enums.TradeAccountType
        currency: string
        leverage: number
        balance?: number
        platform: TradingPlatformName
        platformAccountType: TradingAccountType
        source: string
        accPassword?: string //MT optional password
        //demo contest
        fromContest: boolean
        username: string
}

export interface IndexedObject {
        [key: string]: any
}
export interface IndexedGeneric<T> {
        [key: string]: T
}

export type UserRecord<T> = {
        email?: string
        value?: T
}

export interface PostMessageToWTR {
        [key: string]: any
        message: string
}


export interface RequestBody {
        module: 'authentication' |
        'account' |
        'profile' |
        'payment' |
        'utility' |
        'application' |
        'emailvalidation' |
        'migration' |
        'subscription' |
        'partnership' |
        'phoneVerification' |
        'supportrequest' |
        'referafriend' |
        'ibcommissioncportalmanagement' |
        'UserPartnerApplicationController' |
        'PartnerApplicationQuestionnaireController' |
        'PartnerApplicationDocumentController' |
        'userpartneraddresscontroller' |
        'PartnerController' |
        'UserPartnerAddressController' |
        'PartnerTrackingLinkController'
        action: string
        parameters?: object
}

export type LoginParams = {
        email_id: string
        password: string
}


export interface LoginResponse {
        last_name: string
        status_page?: ApplicationStatus
        session_life: number
        id: number
        first_name: string
        last_login_date: string
        token: string
        keep_alive_interval: number
        remainingAttempts?: number
}

export type ResponseStatus = 'OK' |
        'PENDING_APPROVAL' |
        'SYS_ERR' |
        'NOT_FOUND' |
        'CHALLENGE' |
        'VALIDATION_ERROR' |
        'ALREADY_REGISTERED' |
        'ACCOUNT_CREATION_ERR' |
        'PROCESSOR_ERROR' |
        'MAXLIVE_ACCOUNTS_REACHED' |
        'ACCOUNT_CREATION_FAILED' |
        'Max_Simplified_Amount' |
        'NOT_AUTHORIZED' |
        'NO_ACCOUNT' |
        'DENIED'

export interface APIResponse<T> extends Response {
        id: number
        prv_id: number
        transmission_time: number
        session_id: string
        token: string
        payload: APIResponsePayload<T>[]
}

export interface APIResponsePayload<T> {
        module: string
        action: string
        result: T & { message?: string }
        status: ResponseStatus
}


export type ApplicationApproval = 'AUTO' | 'MANUAL' | 'MANUAL_AFTER_FAILED_AUTO' | 'MANUAL_AFTER_SUCCESS_AUTO' | 'WAITING_DOCUMENT_UPLOAD' | 'NULL'

export type ApplicationStatusResponse = {
        application_id: string
        application_type: 'individual' | 'corporate'
        application_status: ApplicationStatus | 'NOT_FOUND' | 'ERROR'
        organization_id: string
        appropriateness_level: AppropriatenessLevel
        preKycRequired: boolean
        application_approval: ApplicationApproval
        client_boarded_green_id: boolean
        green_id_status: ApplicationStatus | 'NOT_FOUND' | 'ERROR' | 'ABORTED' | 'LOCKED_OUT'


}

export type AppStatusesResponse = {
        application_status: ApplicationStatus | 'NOT_FOUND' | 'ERROR'
        application_approval: ApplicationApproval
        organizationId: number
        appropriateness_level: AppropriatenessLevel
        preKycRequired: boolean
        client_boarded_green_id: boolean
        green_id_status: ApplicationStatus | 'NOT_FOUND' | 'ERROR' | 'ABORTED' | 'LOCKED_OUT'
        application_id: string

}
export type AppExistsResponse = {
        "applicant_exists": boolean
        "applicant_does_not_have_live_accounts": boolean
        "applicant_does_not_have_applications": boolean
        "applicant_organization_id": number
        "applicant_country": number
        "applicant_does_not_have_st_demo_accounts": boolean
}

export type AppropriatenessLevel = 'PASS' | 'REFER' | 'FAIL'

export type Organization = {
        id: number
        name: Enums.PortalAccountDomain
        guid: string
        defaultLeverage: string
}

export type AccountTradingTypeUnion = 'CE' | 'CFD'

export interface AccountTradingType {
        accountTradingType: AccountTradingTypeUnion
        calculateMargin: boolean
        id: number
}

export interface WtrPostMessageType {
        origin: string
        data: {
                message: 'funds' | 'deposit' | 'reports' | 'openLive' | 'openDemo' | 'uploadDocuments' |
                'completeOnboarding' | 'status'
                accountId?: string
                lang?: string
                status?: string | null
                transactionId?: string | null
        }
}

export type AccountType = "LIVE" | "DEMO"
export type Account = {
        id: number
        accountNumber: number
        accountTradingType: AccountTradingType
        type: AccountType
        platform: {
                id: number
                name: TradingPlatformName
                code: string
        }
        organization: Organization
        currency: Currency
        brand: string
        hidden: boolean
        transferred: boolean
        manager: boolean
        canChangePassword: boolean
        leverage: number
        expires?: {
                accountExpirationDate: string
                numberOfDaysRemaining: number
                isExpired: boolean
        }
        isWithdrawAllowed: boolean
        isDepositAllowed: boolean

}

export type AccountStat = {
        enabled: boolean
        readOnly: boolean
        platformAccountType?: TradingAccountType
        tradeNumber: number
        margin: number
        marginFree: number
        leverage: number
        balance: number
        balanceInUSD: number
        grossPnl?: number
        grossUnrealisedPnl?: number
        equity: number
        group: string
        archived: boolean
        hasOpenPositions: boolean
        account: Account
        marginLevel?: number
        wdbalance: number
}

export type AccountStatResponse = {
        draw: number
        recordsFiltered: number
        recordsTotal: number
        data: AccountStat[]
}


export type GetUserCreditCardsRequestParams = {
        subProcessorId?: number
        hideExpired?: boolean
        accountId: number
}


export interface SubmitWithdrawalParams {
        accountId: number
        sourcePortal: 'C_PORTAL'
        methods: SubmitMethod[]
}

export interface SubmitMethod {
        methodId: number
        amount: string
        currency: string
        attributes?: {
                cardNumber?: string
                email?: string
                accountName?: string
                accountNumber?: string
                bankCode?: string
                branchName?: string
                province?: string
                city?: string
                bankName?: string
                bankBranch?: string
                cardNo?: string
                cardName?: string
                bankAccountName?: string
                swiftCode?: string
                iban?: string
                bankAddressCity?: string
                BANK?: string
                BANK_NAME?: string
                BANK_ACCOUNT?: string
                bankCountry?: number
                number?: number
                bankProvince?: string
                bankCity?: string
                subBankName?: string
                bankNo?: string
                receiveName?: string
                address?: string
                accountType?: string
                branchCode?: string
                walletID?: string
                walletMemberName?: string
                BeneficiaryFirstName?: string
                BeneficiaryLastName?: string
                DocumentType?: string
                DocumentNumber?: string
                Email?: string
                BankName?: string
                AccountNumber?: string
                PaymentReason?: string
                PayoutAccountTypeCode?: string
                City?: string
                Address?: string
                Phone?: string
                PayoutChannel?: string
                AccountAgencyNumber?: string
                PixPayment?: string
                RecipientPixKey?: string
                accountHolderName?: string
                PayoutBeneficiaryTypeCode?: string
                walletAddress?: string
                phone?: string
                state?: string
                street?: string
                postalCode?: string
                branchNumber?: string
                ifsc?: string
                firstName?: string
                lastName?: string
                country?: string
                bankBranchCode?: string
        }
}

export type getPaymentInfoParams = {
        transaction_id?: string
        id?: number
        reference_id?: string
}

export type getUserAccountsRequestParams = {
        include_master: boolean
}

export interface CompanyInfo {
        id: number
        name: string
        country: Country
        incorporationDate: string
        australianDomesticCorporation: boolean
        natureOfBusinessActivities: string
        placeOfBusinessAddress: {
                city: string
                postalCode: string
                state: string
                address1: string
                address2?: string
        }
        registeredWithASIC: boolean
        companyRegistrationNumber: string
        type: Enums.CompanyType
}

export type UserProfile = {
        additionalAttributes?: {
                appropriatenessTest?: string
                hasAdditionalQsStep?: string
                isUserOnboarding?: string
                inactivityTimeout?: string
                introducedBy?: string
                [key: string]: any
        }
        id: number
        firstName: string
        lastName: string
        fullName: string
        firstNameOptional?: string
        secondNameOptional?: string
        companyPhoneNumber?: string
        email: string
        cif: string
        title: string
        brand: string
        dateOfBirth: string
        employmentStatus: Enums.EmploymentStatus
        country: Country
        currentEquity: number
        maxLiveAccounts: number
        maxDemoAccounts: number
        maxInterAccountTransfers: number
        organization: Organization
        address?: {
                city?: string
                postalCode?: string
                region?: string
                state?: string
                building?: string
                subBuilding?: string
                street?: string
                address1?: string
                address2?: string
                premise?: string
                subCity?: string
        }
        phone?: {
                mobile: string
                mobileCountryCode: number
                phone: string
                phoneCountryCode: number
        }
        classification: string
        partnerStatus: string
        thinkTraderEULA: boolean
        approved: boolean
        passportId?: string
        passportIdExpiryDate?: string
        idNumber?: string
        nationality?: Country
        isProfessionalClient: false
        preferredLanguage: PreferredLanguage
        avatar?: IAvatarImage
        visitorId: string
        occupation?: string
        industry?: string
        employerName?: string
        company?: CompanyInfo
        password?: string
        phoneVerified?: boolean
}

export interface EVerificationType {
        id: number
        isAsync: boolean
        type: KYCProvider
}

export type Country = {
        id: number
        name: string
        code2: string
        code3: string
        phoneCode: number
        organization: Organization
        legacyOrganization: Organization
        cTraderCountryId: number
        used: boolean
        european: boolean
        isRequireEnglishNames: boolean
        currency: Currency
        riskProfile: string
        defaultLeverage?: string
        maxLeverage?: string
        forceEmailValidate: boolean
        forcePhoneNumberValidate?: boolean
        preKycFunds?: number
        eVerificationStatus: boolean
        demoCurrency: Currency
        liveCurrency: Currency
        demoBalance: number
        tfaEnabled?: boolean
        isSimplifyOnboarding?: boolean
        eVerificationType?: EVerificationType
}

export type KYCProvider = 'GBG' | 'JUMIO' | 'ThisIsMe' | 'TRUSTDOCK' | 'SumSub' | 'GreenID' | ''

export type Currency = {
        id: number,
        code: string,
        name?: string, // make it not optional
        symbol?: string
}

export type Limits = {
        accountGroup: 'standard' | '????'
        initialMinDeposit: number
        minDeposit: number
        maxDeposit: number
        // added on front side from hardcoded values
        // for processors which have hardcoded separated currency for limits
        currency?: string
}

export type AccountProcessor = {
        processorFee: number
        conversionFee: number
        limit?: Limits
        bankInfo?: Partial<BankInfo>
        id: number
        name: string
        type: 'CC' | 'Bank' | 'e-Wallet'
        used: boolean
        bank: boolean
        refundAllowed: boolean
        deposit24Limit: number
        autoWithdrawalAllowed: boolean
        creditCard: boolean
        isClientDepositAllowed: boolean
        isClientWithdrawalAllowed: boolean
        displayName: string
        behavingLikeBankWire: boolean
        //added for GUI purposes
        icon?: string
}

export interface WithdrawalTemplateValues {
        cardNumber?: string
        email?: string
        accountName?: string
        accountNumber?: string
        bankCode?: string
        branchName?: string
        iban?: string
        // FastPay2p2
        bankName?: string
        bankBranch?: string
        cardName?: string
        cardNo?: string
        city?: string
        province?: string
        // Bank Wire
        bankAccountName?: string
        swiftCode?: string
        bankAddressCity?: string
        bankCountry?: number
        // FasaPay
        number?: number
        // XPay QR
        BANK?: string
        BANK_ACCOUNT?: string
        BANK_NAME?: string
        // Nova2Pay
        bankProvince?: string
        bankCity?: string
        subBankName?: string
        bankNo?: string
        receiveName?: string
        address?: string
        // TMJP bank wire
        accountType?: string
        branchCode?: string
        // PerfectMoney
        walletID?: string
        walletMemberName?: string
        // Pay Retailers
        BeneficiaryFirstName?: string
        BeneficiaryLastName?: string
        DocumentType?: string
        DocumentNumber?: string
        Email?: string
        BankName?: string
        AccountNumber?: string
        PaymentReason?: string
        PayoutAccountTypeCode?: string
        City?: string
        Address?: string
        Phone?: string
        PayoutChannel?: string
        AccountAgencyNumber?: string
        PixPayment?: string
        RecipientPixKey?: string
        accountHolderName?: string
        PayoutBeneficiaryTypeCode?: string
        // DivePay
        walletAddress?: string
        // Transact365
        phone?: string
        state?: string
        street?: string
        postalCode?: string
        branchNumber?: string
        ifsc?: string
        firstName?: string
        lastName?: string
        country?: string
}

export type WithdrawalOption = {
        subProcessor: SubProcessor
        currencies: Currency[],
        minAmount?: number
        processingTimeId: number
        transactionFee: number
        conversionFee: number
        priority: number
        limit?: number
        withdrawalTemplate?: WithdrawalTemplateValues
}

export type WithdrawalOptions = {
        options: WithdrawalOption[]
        rates: Rates
}

export interface BankInfo {
        accountNumber: number
        bankName: string
        iban?: string
        accountName: string
        swiftCode: string
        ibanCode: string
        bankCode: number
        sortCode: number
        address1: string
        city: string
        postalCode: string
        country: Country,
        beneficiaryAddress1: string
        beneficiaryCity: string
        beneficiaryPostalCode: string
        beneficiaryCountry: Country
        referenceId?: string
}

export type Bank = {
        id: number
        name: string
        type: string //???
        used: true,
        bankInfo: Partial<BankInfo>,
        bank: boolean
        refundAllowed: boolean
        deposit24Limit: number
        autoWithdrawalAllowed: boolean
        creditCard: boolean
        isClientDepositAllowed: boolean
        isClientWithdrawalAllowed: boolean
        displayName: string
}

export type OrganizationFeature = {
        types: string[]
        countries: number[],
        organizations: number[]
        platforms: TradingPlatformName[]
        leverages: number[]
        currencies: string[]
        accountTypes: TradingAccountType[]
        hidden: boolean
        brand: string
}

export type OrganizationSettings = {
        organizationId: number
        features: OrganizationFeature[]

}

export type TwilioSmsReponse = {
        channel: string
        createdDate: string
        id: number
        response: string
        sid: string
        status: string
        to: string
        updatedDate?: string
        userId: number
}

export type CheckVerificationCodeResponse = {
        twilioUserLog: TwilioSmsReponse
        checkVerificationCodeResult: boolean
}

export type SendTwilioSmsParams = {
        userId: number
        to: string
}

export type CheckTwilioSmsParams = {
        userId: number
        to: string
        code: string
        sid: string
}


export interface UpdateUserProfileParams {
        approved: boolean
        title: 'Mrs' | 'Mr'
        employmentStatus: string
        phone: {
                phone: string
                phoneCountryCode: number
                mobile: string
                mobileCountryCode: number
        },
        address: object,
        passportId: string
        nationality: {
                id: number
        },
        preferredLanguage: {
                id: number
        },
        passportIdExpiryDate: string

}

export interface PreferredLanguage {
        id: number
        language_name: string
        language_code: string
}

export interface GetIPResponse {
        country?: string
        country_code?: string

}

export interface UpdateTradingAccountParams {
        account: number
        enable?: boolean
        enable_change_password?: boolean
        enable_read_only?: boolean
        leverage?: number
        password?: string
}

export interface ChangePasswordParams {
        password: string
        new_password: string
}

export interface VerifyPasswordParams {
        password: string
}

export interface ResetPassword {
        email_id: string
        password_reset_token: string
        password: string
        response: string
}

export interface ReCaptchaResponse {
        password_reset: "OK" | "NOK"
}
export interface TransferMoneyStepOne {
        amount: number
        from_account: number
        to_account: number
        sourcePortal: 'C_PORTAL'
}

export interface TransferMoneyStepTwo {
        transfer_confirm: boolean
        transferId: number
}

export interface TransferMoneyResponse {
        status: 'INCOMPLETE' | 'OK'
        statusCode: string
        transfer: Transfer
        toAmount: number
        marginAlert: boolean
        message?: string
}

export interface Transfer {
        id: number
        user: CreatedBy
        referenceId: string
        fromAccount: Account
        toAccount: Account
        amount: number
        toAmount: number
        status: string
        statusCode: string
        createdDate?: string
        exchangeRate: number
        transactionFee: number
        sourcePortal: string
        createdBy: CreatedBy
        notes: any[]
        processedDate?: string
}

export interface TransefrsHistoryResponse {
        data: Transfer[]
        draw: number
        recordsFiltered: number
        recordsTotal: number
}

export interface CreatedBy {
        id: number
        firstName: string
        lastName: string
        fullName: string
        cif: Cif
        email: string
        brand: string
        createdDate: string
        country: Country
        liveAccountCount: number
        demoAccountCount: number
        organization: Organization
        registered: boolean
        title: string
        address: Address
        phone: Phone
        employmentStatus: string
        disabled: boolean
        maxLiveAccounts: number
        maxInterAccountTransfers: number
        dateOfBirth: string
        classification: string
        partnerStatus: string
        idNumber: string
        passportId: string
        nationality: Country
        passportIdExpiryDate: string
        isProfessionalClient: boolean
        preferredLanguage: PreferredLanguage
        allowedForMP: boolean
        withdrawAllowed: boolean
        occupation: string
        industry: string
        employerName: string
}

export interface Address {
        address1: string
        city: string
        postalCode: string
        region: string
        building: string
        subBuilding: string
        street: string
}

export interface Cif {
        id: number
        value: string
}

export interface Phone {
        phone: string
        phoneCountryCode: number
        mobile: string
        mobileCountryCode: number
}

export interface PreferredLanguage {
        id: number
        language_name: string
        language_code: string
}

export interface GetPendingWithdrawalSumParams {
        accountId: number
}

export interface VerifyAccountPasswordParams {
        account: number
        password: string
}

export interface GetNotificationsParams {
        startDate?: string
        endDate?: string
}

export interface Notification {
        notificationId: number
        repeatable: boolean
        active: boolean
        read?: boolean
        createdDate: string
        message: string
}

export interface NotificationMap {
        notification: string
        id: number
}

export interface DeleteCreditCardParams {
        id: number
}

export interface GetTransactionsParams {
        trading_account?: number
        start_date?: string
        stop_date?: string
}

export type SubProcessor = {
        id: number
        name: string
        type: string
        used: boolean
        bank: boolean
        refundAllowed: boolean
        autoWithdrawalAllowed: boolean
        behavingLikeBankWire: boolean
        creditCard: boolean
        isClientDepositAllowed: boolean
        isClientWithdrawalAllowed: boolean
        displayName: string
}

// to be completed later
export type TransactionStatus = 'APPLIED' | 'INCOMPLETE' | 'APPROVED' | 'CANCELED' | 'DENIED' | 'PENDING' | 'PENDING_REMITTANCE'
export type TransactionType = 'WITHDRAWAL' | 'DEPOSIT'

export interface Transaction {
        id: number
        account: Account
        referenceId: string
        batchId: string
        transactionId: string
        type: TransactionType
        currency: Currency
        subProcessor: SubProcessor
        amount: number
        exchangeRate: number
        transactionFee: number
        processorFee: number
        status: TransactionStatus
        createdDate: string
        applyManually: boolean
        processorTransactionId?: string
        sourcePortal: 'C_PORTAL_UK', // C_PORTAL_UK ?????
}

export interface UserApplication {
        id: number
        accountTradingType: AccountTradingType
        annualIncome: string
        applicationStatus: ApplicationStatus
        appropriatenessLevel: AppropriatenessLevel
        createdDate: string
        currency: Currency
        country?: Country
        employerName: string
        investmentKnowledgeRisks: boolean
        netWorth: string
        organization: Organization
        platformAccountType: TradingAccountType
        platformId: number
        status: 'approved' | 'incomplete' | 'pending_id_address'
        type: ApplicationType
        introducedBy?: string
        // tbd
        professionalQualification: boolean
        sourceOfFunds: string// Savings, ????
        forexExperience: string// 3-5 Years, ???
        securitiesBondExperience: string// 3-5 Years, ???
        educationQualifications: string//FCA Registered, ???
        isSimplifiedOnboarding?: boolean
}

export interface getIPResponse {
        country?: string
        country_code?: string
}

export type TradingAccountType =
        'think_invest' |
        'ThinkZero' |
        'trade_interceptor' |
        'standard' |
        'ThinkTrader' |
        'spreadBetting' |
        'PAMM_Master' |
        'PAMM_Master_Rebate' |
        'PAMM_Investor' |
        'CFD_Copy' |
        'Classic' |
        'Mini' |
        'Zero' |
        'DemoContest' |
        'Commission' |
        'JapanSpread'

export type TradingPlatformName =
        'MT4' |
        'MT5' |
        'ThinkTrader' |
        'ThinkInvest' |
        'TradeInterceptor' |
        'PAMM_TRADING' |
        'PAMM_Trading' | //name of the platform is camel-cased
        'THINK_COPY'

export type TradingPlatformID =
        'MT4' |
        'MT5' |
        'ThinkTrader' |
        'ThinkInvest' |
        'TradeInterceptor' |
        'PAMM_TRADING' | //id is upper case
        'THINK_COPY'

export const UpperPlatformName: Record<string, TradingPlatformID> = {
        'MT4': 'MT4',
        'MT5': 'MT5',
        'THINKTRADER': 'ThinkTrader',
        'THINKINVEST': 'ThinkInvest',
        'TRADEINTERCEPTOR': 'TradeInterceptor',
        'PAMM_TRADING': 'PAMM_TRADING',
        'THINK_COPY': 'THINK_COPY',
}
export type TradingApp = Extract<TradingPlatformName, 'ThinkTrader' | 'MT4' | 'MT5'> | 'TradingView'

export type ApplicationType = 'demo' | 'individual' | 'corporate' | 'joint' | 'partner'
export type QuestionsIDs = {
        question?: number
        answer?: number
        others?: string
}

export interface AppInfo extends IBInfo {
        consentAccepted?: string
        secondaryConsentAccepted?: string
        referrerId?: string
        isMarketingOptOut?: boolean
        nonProfessionalTraderAgreement?: boolean
        accountApplicationQuestionDetails?: QuestionsIDs[]
        acceptThinkInvestTC?: boolean
        accountCurrency?: string
        recaptchaResponse?: string
        incomingFundCountry?: number
        outgoingFundCountry?: number
        accountHolderBuilding?: string
        accountCurrencies?: string[]
        accountHolderCity?: string
        accountHolderCountryOfBirth?: string
        accountHolderDayOfBirth?: number
        accountHolderEmail?: string
        accountHolderEmploymentStatus?: string
        accountHolderFirstName?: string
        firstNameOptional?: string
        secondNameOptional?: string
        accountHolderGender?: string
        accountHolderFloor?: string
        accountHolderIdNumber?: string
        accountHolderLastName?: string
        accountHolderMiddleName?: string
        accountHolderMobilePhone?: string
        accountHolderMobilePhoneCode?: number
        accountHolderMonthOfBirth?: number
        accountHolderMunicipalityOfBirth?: string
        accountHolderNationality?: number
        accountHolderPassportIdNumber?: string
        accountHolderPassword?: string
        accountHolderPhone?: string
        accountHolderPhoneCode?: number
        accountHolderPostalCode?: string
        accountHolderPremise?: string
        accountHolderProvinceOfBirth?: string
        accountHolderRegion?: string
        accountHolderSecondCitizenship?: number
        accountHolderSecondCitizenshipId?: number
        accountHolderSecondIdNumber?: string
        accountHolderSecondLastName?: string
        accountHolderStateProvince?: string
        accountHolderStreet?: string
        accountHolderStreetAddress?: string
        accountHolderStreetAddress2?: string
        accountHolderSubBuilding?: string
        accountHolderSubCity?: string
        accountHolderTitle?: string
        accountTradingTypes?: Array<{ id: Enums.AccountTradingTypes } | Enums.AccountTradingTypes>
        accountHolderYearOfBirth?: number
        accountType?: ApplicationType
        agreeToAllTerms?: boolean
        annualIncome?: string
        employmentDetails?: string
        companyPhoneNumber?: string
        appropriatenessLevel?: AppropriatenessLevel
        approximateIncomeValue?: string
        bondsExperience?: string
        brand?: 'ThinkMarkets'
        companyAddress?: string
        CompanyCity?: string
        companyIncorporationDay?: number
        companyIncorporationMonth?: number
        companyIncorporationYear?: number
        companyName?: string
        companyNatureOfBusinessActivities?: string
        companyPlaceOfBusinessAddress?: string
        companyPlaceOfBusinessAddress2?: string
        companyPlaceOfBusinessCity?: string
        companyPlaceOfBusinessCountry?: number
        companyPlaceOfBusinessPostalCode?: string
        companyPlaceOfBusinessBuilding?: string
        companyPlaceOfBusinessSubBuilding?: string
        companyPlaceOfBusinessStreet?: string
        companyPlaceOfBusinessStateProvince?: string
        companyPlaceOfBusinessStreetAddress?: string
        // companyRegistrationExpirationDate?: string
        companyRegistrationNumber?: string
        CompanyStateProvince?: string
        completed?: boolean
        corporateContact1_Address?: string
        corporateContact1_Address2?: string
        corporateContact1_City?: string
        corporateContact1_DayOfBirth?: number
        corporateContact1_Email?: string
        corporateContact1_FirstName?: string
        corporateContact1_LastName?: string
        corporateContact1_MonthOfBirth?: number
        corporateContact1_OriginCountry?: number
        corporateContact1_Phone?: string
        corporateContact1_Position?: string
        corporateContact1_PostalCode?: string
        corporateContact1_StateProvince?: string
        corporateContact1_YearOfBirth?: number
        corporateContact2_Address?: string
        corporateContact2_Address2?: string
        corporateContact2_City?: string
        corporateContact2_DayOfBirth?: number
        corporateContact2_Email?: string
        corporateContact2_FirstName?: string
        corporateContact2_LastName?: string
        corporateContact2_MonthOfBirth?: number
        corporateContact2_OriginCountry?: number
        corporateContact2_Position?: string
        corporateContact2_PostalCode?: string
        corporateContact2_YearOfBirth?: number
        corporateContact3_Address?: string
        corporateContact3_Address2?: string
        corporateContact3_City?: string
        corporateContact3_DayOfBirth?: number
        corporateContact3_Email?: string
        corporateContact3_FirstName?: string
        corporateContact3_LastName?: string
        corporateContact3_MonthOfBirth?: number
        corporateContact3_OriginCountry?: number
        corporateContact3_Position?: string
        corporateContact3_PostalCode?: string
        corporateContact3_YearOfBirth?: number
        educationQualifications?: string
        employmentStatus?: string
        estimatedNetWorth?: string
        forexExperience?: string
        formLastField?: string //
        formStep?: number
        gender?: 'M' | 'F'
        introducedByBroker?: IBInfo
        investmentKnowledgeRisk?: 'yes' | 'no'
        jointAccountHolderDayOfBirth?: number
        jointAccountHolderFirstName?: string
        jointAccountHolderLastName?: string
        jointAccountHolderMonthOfBirth?: number
        jointAccountHolderOriginCountry?: number
        jointAccountHolderYearOfBirth?: number
        leverage?: number
        originCountry?: number
        originHost?: string
        originIPAddress?: string
        platformAccountType?: TradingAccountType
        preferredLanguage?: number
        prevAccountHolderBuilding?: string
        prevAccountHolderCity?: string
        prevAccountHolderPoBox?: string
        prevAccountHolderPostalCode?: string
        prevAccountHolderRegion?: string
        prevAccountHolderStateProvince?: string
        prevAccountHolderStreetAddress?: string
        prevAccountHolderStreetAddress2?: string
        prevAccountHolderSubBuilding?: string
        prevOriginCountry?: number
        risksUnderstanding?: 'yes' | 'no'
        securitiesBondsExperience?: string
        selectedPlatform?: TradingPlatformName | TradingPlatformID
        smartTraderEULA?: boolean
        sourceOfFunds?: string
        sourceOfFundsOtherExplanation?: string
        token?: string
        taxIdentificationNumber?: string
        userIp?: string
        volumesOfTradesPerMonth?: string
        //???????
        tradeDerivatives?: boolean
        regulation?: boolean
        subjectToBankruptcy?: boolean
        disciplinaryAction?: boolean
        companyEmail?: string
        companyWebsite?: string
        registeredAddressCountry?: number
        registeredAddress?: string
        registeredAddress2?: string
        registeredAddressCity?: string
        registeredAddressPostalCode?: string
        regulatorName?: string
        registrationNumber?: string
        subjectToBankruptcyExplanation?: string
        disciplinaryActionExplanation?: string
        accountHolderIdExpirationDate?: string
        employerName?: string
        occupation?: string
        solicitationMethod?: string
        industry?: string
        usCitizen?: boolean
        workInForeignExchange?: boolean
        politicallyExposedPerson?: boolean
        professionalQualification?: boolean
        companyRegistrationExpirationDateDay?: number
        companyRegistrationExpirationDateMonth?: number
        companyRegistrationExpirationDateYear?: number
        disableGBG?: boolean
        visitorId?: string
        source?: string
        ce_currentSponsoringBroker?: string,
        ce_participantIdentificationNumber?: string,
        ce_holderIdentificationNumber?: string,
        additionalTaxIds?: AdditionalTaxId[]
        // postal address
        accountHolderPostalStreetAddress?: string
        accountHolderPostalStreetAddress2?: string
        accountHolderPostalCity?: string
        accountHolderPostalStateProvince?: string
        accountHolderPostalPostalCode?: string
        accountHolderPostalRegion?: string
        accountHolderPostalSubCity?: string
        accountHolderPostalBuilding?: string
        accountHolderPostalSubBuilding?: string
        accountHolderPostalPremise?: string
        accountHolderPostalStreet?: string
        accountHolderPostalFloor?: string
        accountHolderPostalFax?: string
        accountHolderPostalPoBox?: string
        // ce_accountHolder
        ce_accountHolderStreetAddress?: string
        ce_accountHolderStreetAddress2?: string
        ce_accountHolderCity?: string
        ce_accountHolderStateProvince?: string
        ce_accountHolderPostalCode?: string
        ce_accountHolderRegion?: string
        ce_accountHolderSubCity?: string
        ce_accountHolderBuilding?: string
        ce_accountHolderSubBuilding?: string
        ce_accountHolderPremise?: string
        ce_accountHolderStreet?: string
        ce_accountHolderFloor?: string
        ce_accountHolderPoBox?: string
        ce_originCountry?: number
        process?: string
        //NL
        preferredOrganization?: number

        // corporate
        companyACN?: string
        companyABN?: string
        companyTFN?: string
        companyType?: string
        companyTaxResidenceCountry?: number
        companyTaxInAU?: boolean
        directorHolding25?: boolean
        directorsHolding25?: Partial<Person>[]
        shareholderHolding25?: boolean
        shareholdersHolding25?: Partial<Person>[]
        annualNetworth?: string
        purposeOfAccount?: string
        valueOfFundsAvailableToTrade?: string
        applicationId?: number

        primaryContact?: {
                isAccountHolder: boolean
                firstName?: string
                lastName?: string
                address?: AddressData
                dateOfBirth?: DateData
                position?: string
                email?: string
                country?: number
                phone?: {
                        code?: number
                        number: string
                }
        }
}

export interface AdditionalTaxId {
        tax_number: string
        country_id: number
}

export type VerifyEmailParams = Pick<AppInfo,
        "originCountry" |
        "accountHolderFirstName" |
        "accountHolderLastName" |
        "preferredLanguage" |
        "accountHolderEmail" |
        "process">


export type LoginResponseStatus = 'OK' |
        'PENDING_APPROVAL' |
        'PENDING_REVIEW' |
        'PENDING_ID_ADDRESS' |
        'PENDING_ID' |
        'PENDING_ADDRESS' |
        'NOT_AUTHORIZED' |
        'TFA_REQUIRED'

export const loginStatuses = [
        'OK',
        'PENDING_APPROVAL',
        'PENDING_REVIEW',
        'PENDING_ID_ADDRESS',
        'PENDING_ID',
        'PENDING_ADDRESS',
        'NOT_AUTHORIZED'
] as const


export const ApplicationStatuses = [
        'APPROVED',
        'ALREADY_REGISTERED',
        'PENDING_REVIEW',
        'PENDING',
        'DENIED',
        'PENDING_ID_ADDRESS',
        'PENDING_ID',
        'PENDING_ADDRESS',
        'PENDING_ADDRESS_MISMATCH',
        'PENDING_APPROPRIATENESS_TEST',
        'PENDING_PROPER_DOCUMENTS',
        'PENDING_TRANSFER',
        'PENDING_NEW_TnC_ACCEPTANCE',
        'PENDING_IP_MISMATCH',
        'PENDING_DOB_MISMATCH',
        'PENDING_BLURRY_POA',
        'PENDING_BLURRY_CROPPED_ID',
        'PENDING_E_VERIFICATION',
        'PENDING_E_VERIFICATION_RETRY',
        'DUPLICATE',
        'INCOMPLETE',
        'INTERNAL_TESTING',
        'PENDING_TRANSLATION',
        'PENDING_RG227',
        'LEVEL1_APPROVED',
        'PENDING_KYC',
        'MANUAL_DOCS',
        'FAILED'] as const

export type ApplicationStatus = typeof ApplicationStatuses[number]

export type GBGResult = 'APPROVE' | 'DOUBLE_CHECK' | 'DECLINE' | 'UNKNOWN'
export interface IncrementalSubmitResponse {
        sso_token: string
        token: string
        applicationStatus: ApplicationStatus
        app_id: number
        applicationId: number
        gbg_result: GBGResult
        accountApplications: Array<{
                accountTradingType: AccountTradingTypeUnion
                applicationId: number
                id: number
                status: string
        }>
        account?: {
                accountNumber: number
                id: number
                type: 'LIVE' | 'DEMO'
        }
}

export interface SimplifiedSubmitLevelOneResponse {
        applicationId: number
}

export interface AccountTradingTypeModel {
        id: Enums.AccountTradingTypes
        accountTradingType: AccountTradingTypeUnion
        accountLimit: number
        applicationAge: number
        calculateMargin: boolean
        countryRestricted: boolean
        initialAppRequired: boolean
        countryIdList?: string
        description: string

}

export type JumioAcceptedDocument = 'PASSPORT' | 'DRIVING_LICENSE' | 'ID_CARD'
export interface JumioUrlResponse {
        iframeRedirectUrl: string
        usercountry: string
}

export interface JumioDocumentTypesResponse {
        documentTypes: JumioAcceptedDocument[]
}

export interface TradingAccount {
        type: TradingAccountType
        currencies: string[]
        leverages: number[]
}

export interface TradingPlatform {
        name: TradingPlatformName
        accounts: TradingAccount[]
}

export interface AddressLookupParams {
        originCountry?: number
        accountHolderPostalCode: string
        accountHolderBuilding: string
        accountHolderStreet: string
}

export interface AddressLookupRecord {
        country: string
        accountHolderStateProvince: string
        accountHolderCity: string
        accountHolderSubCity: string
        accountHolderRegion: string
        accountHolderPostalCode: string
        accountHolderStreet: string
        subStreet: string
        accountHolderBuilding: string
        accountHolderSubBuilding: string
        accountHolderPoBox: string
        accountHolderPremise: string
        principality: string
        dpsZipPlus: string
        cedexMailsort: string
        department: string
        company: string
}

export interface IsValidOtpCodeResponse {
        isValid: boolean,
        date: string
}


export interface UploadedFileInfo {
        length: number
        mime_type: string
        name: string
}

export type IDOrAddressProof = 'ID' | 'ADDRESS'

export interface ClaimParams {
        files: UploadedFileInfo[]
        fileTypes: IDOrAddressProof[]
        applicationId?: number
        document_description?: string
        document_type?: number //3
}
export interface IndividualClaimParams extends ClaimParams {
        app_id?: number | string
        user_id?: number
        disableGBG?: boolean
}
export interface IBClaimParams extends ClaimParams {
}

export interface Payload {
        module: string
        action: string
        result: Result
        status: string
}

export interface Result {
        "img100.jpg": number
}



export interface AccountValidationField {
        fieldName: string
        optional: boolean
        orderNo?: number
        values?: string[]
}

export interface AccountValidationRules {
        addressFormat: string // "FIXED" | "??????"
        lookupSupported: boolean
        externalLookupSupported: boolean
        fields: AccountValidationField[]
}

export type FieldValidation = {
        optional: boolean
        orderNo?: number
        values?: string[]
}

export type AddressFields = {
        accountHolderSubBuilding?: FieldValidation
        accountHolderBuilding?: FieldValidation
        accountHolderStreet?: FieldValidation
        accountHolderCity?: FieldValidation
        accountHolderPostalCode?: FieldValidation
        accountHolderRegion?: FieldValidation
        accountHolderStreetAddress?: FieldValidation
        accountHolderStreetAddress2?: FieldValidation
        accountHolderStateProvince?: FieldValidation
        accountHolderSubCity?: FieldValidation
        accountHolderPremise?: FieldValidation
}

export interface AddressBase<V = string> {
        SubBuilding: V
        Building: V
        Street: V
        City: V
        Region: V
        StreetAddress: V
        StreetAddress2: V
        StateProvince: V
        PostalCode: V
        SubCity?: V
        Premise?: V
}


export type AddressData = AddressBase<string>
export type AddressTouched = Partial<AddressBase<boolean>>
export type AddressErrors = Partial<AddressBase<string>>
export type DateData = {
        day?: number
        month?: number
        year?: number
}

export type DepositData = {
        accountId: number
        amount: number
        currency: Currency
        processor: AccountProcessor
}

export interface Answer<T extends string = string> {
        id: number
        answer: string
        other: string
        label: T
        score: number
}

export interface Question<T extends string = string> {
        id: number
        question: string
        label: string
        answers: Answer<T>[]
        topic?: string
        isMandatory?: boolean
}

export type IBInfo = GlobalIBInfo

export type DocType = {
        id: number
        name: string
}

export interface PrefillCreatePageParams {
        brand: string
        primaryApplicant__address__countryId: string
        primaryApplicant__firstName: string
        primaryApplicant__lastName: string
        primaryApplicant__phoneCode: string
        primaryApplicant__phoneNumber: string
        primaryEmail: string
        visitorId: string
}

export interface AvatarImageType {
        id: number
        name: string
}

export interface IAvatarImage {
        id: number
        originalFilename: string
        createdDate: string
        updatedDate: string
        description: string
        originalName: string
        size: number
        href: string
        type: AvatarImageType
}

export interface AddTMJPBankAccountParams {
        accountNumber: string
        accountName: string
        accountType: string
        bankName: string
        country: { id: number }
        branchName: string
        branchCode: string
        bankCode?: string
        document_id: number
}

export interface AddGeneralBankAccountParams {
        accountNumber: string
        swiftCode: string
        ibanCode: string
        bankName: string
        bankAddress: string
        country: { id: number }
        city: string
        state: string
        document_id: number
}

export type AddBankAccountParams = AddGeneralBankAccountParams | AddTMJPBankAccountParams

// Generated by https://quicktype.io

export interface BankAccount {
        id: number
        accountNumber: string
        swiftCode: string
        ibanCode: string
        bankName: string
        bankAddress: string
        accountType?: string
        branchCode?: string
        accountName?: string
        branchName?: string
        bankCode?: string
        country: Country
        city: string
        state: string
        file: DocumentFile
}

export interface DocumentFile {
        id: number
        originalFilename: string
        user: User
        type: {
                id: number
                name: string
        }
        createdDate: string
        updatedDate: string
        description: string
        originalName: string
        size: number
        href: string
}
export interface User {
        id: number
        firstName: string
        lastName: string
        fullName: string
        cif: Cif
        email: string
        brand: string
        createdDate: string
        country: Country
        liveAccountCount: number
        demoAccountCount: number
        organization: Organization
        registered: boolean
        title: string
        address: Address
        phone: Phone
        employmentStatus: string
        disabled: boolean
        maxLiveAccounts: number
        maxInterAccountTransfers: number
        dateOfBirth: string
        classification: string
        partnerStatus: string
        idNumber: string
        passportId: string
        nationality: Country
        passportIdExpiryDate: string
        isProfessionalClient: boolean
        preferredLanguage: PreferredLanguage
        allowedForMP: boolean
        withdrawAllowed: boolean
        occupation: string
        industry: string
        employerName: string
}

export interface Address {
        address1: string
        city: string
        postalCode: string
        region: string
        building: string
        subBuilding: string
        street: string
}

export interface Cif {
        id: number
        value: string
}
export interface Phone {
        phone: string
        phoneCountryCode: number
        mobile: string
        mobileCountryCode: number
}
export interface PreferredLanguage {
        id: number
        language_name: string
        language_code: string
}

export interface InitPaymentParams {
        accountId: number
        currencyId: number
        amount: string
        processorId: number
        sourcePortal: 'C_PORTAL'
        resultPage?: string
        options?: {
                nonce?: string,
                locale?: string,
                creditCardId?: number,
                payment_method?: string,
                bank_code?: string,
                idcard_number?: string,
                bank_account_number?: string,
                bankCode?: string,
                paymentProvider?: string,
                hash?: string,
                tokenData?: string,
                token?: string,
                type?: 'google' | 'checkout' | 'apple'
                transactionID?: string,
                ozowCustomerId?: string,
        }
}

export interface GetBitGoAddressParams {
        userId: number
        cryptoCurrency: Currency
        tradingAccountId: number
}

export interface GetBitGoAddressResponse {
        address: string
        memoId?: number
}

export interface LatamPaymentMethod {
        value: string
        name: string
}

export interface PaymentPostAction {
        type: 'post'
        url: string
        fields: {
                signData: string
                txnCd: string
                merCd: string
                merOrderNo: string
                versionNo: string
                notifyUrl: string
                signType: string
                txnAmt: string
                pageBackUrl: string
        }
}

export interface PaymentNopAction {
        type: 'nop'
}

export interface PaymentIframeAction {
        type: 'iframe'
        url: string
}

export interface PaymentRedirectAction {
        type: 'redirect'
        url: string
}

export interface InitPaymentResponse {
        transactionId: string
        options: {
                clientIp?: string
                nonce: string
        }
        action: PaymentPostAction | PaymentNopAction | PaymentRedirectAction | PaymentIframeAction
        payment: Transaction
}

export interface InitiateParams {
        trading_account_row_id: number
        formReady: boolean
        validationIsWorking: boolean
        amount: string
        subProcessorId: number
        currency: number
        trading_account: number
        processor: number
        processorFee: number
        ipToken: null
        selectedAccount: any
        conversionFee: number
        subProcessorName: string
        sourcePortal: string
        returnPage: string
        requestUserProfile?: boolean
        selectedCreditCardId?: number
        previous_payment?: number
        netellerEmail?: string
        properties?: {
                address_city?: string,
                address_street?: string,
                address_zip?: string,
                country?: number
        },
}

export interface InitiateResponse {
        redirect_uri?: string
        transactionId: string
        payment_params: {
                clientIp: string
                // FasaPay
                redirectUri?: string
                fp_store?: string
                fp_amnt?: string
                fp_currency?: string
                fp_acc?: string
                fp_merchant_ref?: string
                fp_transaction_id?: string
                fp_fee_mode?: string
        }
}

export interface Otc365Response {
        code: number
        msg: string
        success: boolean
        data?: {
                link: string
                orderNo: string
        }
}

export interface GetUserCreditCardsParams {
        // accountId: number
        subProcessorId?: number
        hideExpired?: boolean
}

export interface CreditCard {
        id: number
        description: string
        last4Digits: string
        expMonth: number
        expYear: number
        type: 'VISA' | 'MASTER'
        subProcessorId: number

}

export interface XPayQRBank {

        bank_name: string
        bank_code: string
}

export interface BJPBank {

        bankNameEnglish: string
        bankNameJapan: string
        bankCode: string
        id: number
        isEnabled: boolean
        isDeposit: boolean
        isWithdrawal: boolean
}

export interface Rates {
        [index: string]: {
                [index: string]: number
        }
}

export interface CreateDemoPortalAccountParams extends IBInfo {
        first_name: string
        last_name: string
        firstNameOptional: string
        secondNameOptional: string
        email: string
        phone: string
        phonecode: number
        country: number
        currency: string
        balance: number
        leverage: number
        preferredLanguageId: number
        platformCode: TradingPlatformName
        isMarketingOptOut: boolean
        password: string
        brand: "ThinkMarkets"
        visitorId: string
        afs_clickid?: string
        afsAexid?: string
        afs_aid?: string
        platformAccountType: TradingAccountType
        source: string
        recaptchaResponse: string
        //demo contest
        fromContest: boolean
        username: string
}

export interface CreateTradeAccountResponse {
        accountNumber: number
        server: Partial<Server>
        email: string
        password: string
        type: string
        tiPassword: string
        tiEmail: string
}

export interface NewTradeAccountInfo extends CreateTradeAccountResponse {
        type: 'DEMO' | 'LIVE'
}

export interface Server {
        id: number
        guid: string
        name: string
        serverName: string
        type: string
        platform: Platform
        isDefault: boolean
        instance: Instance
}

export interface Instance {
        id: number
        name: string
}

export interface Platform {
        id: number
        name: TradingPlatformName
        code: string
}

export interface RegisterUserParams {
        accountHolderTitle: string
        accountHolderDayOfBirth: number
        accountHolderMonthOfBirth: number
        accountHolderYearOfBirth: number
        brand: 'ThinkMarkets'
        country: number
        email_id: string
        first_name: string
        last_name: string
        password: string
}

export interface ExchangeRate {
        ask: number
        bid: number
        symbol: string
        reversed: boolean
}

export interface CurrencyGraph {
        [code: string]: {
                [code: string]: number
        }
}

export type SortDirection = 'ascend' | 'descend'

export interface ISortInfo {
        column: string
        direction: SortDirection
}

export interface UploadDocModel {
        id: number
        name: string
        doubleSided: boolean
        icon?: string
        hint?: string
        priority?: number
}

export type DocTypes =
        'passport' |
        'license' |
        'id' |
        'address' |
        'addressSecondary' |
        'addressCompany' |
        'addressCorporation' |
        'card' |
        'bank' |
        'other' |
        'photo' |
        'photoSecondary' |
        'incorporationArticles' |
        'postOfficeBox' |
        'visa' |
        'Proof of ID' |
        'avatar'

export const DocTypesMap: Record<DocTypes, UploadDocModel> = {
        'passport': {
                id: 1,
                name: "Passport",
                doubleSided: false,
                icon: 'file-folder-open.svg'
        },
        'license': {
                id: 2,
                name: "Driver's License",
                doubleSided: true,
                icon: 'system-window.svg'
        },
        'id': {
                id: 3,
                name: "Government ID",
                doubleSided: true,
                icon: 'user-id-card.svg'
        },
        'address': {
                id: 4,
                name: "Proof of Address",
                doubleSided: false,
                icon: 'basic-mail-open.svg'
        },
        'card': {
                id: 5,
                name: "Debit / Credit Card",
                doubleSided: false,
                icon: 'basic-credit-card-alt.svg'
        },
        'bank': {
                id: 6,
                name: "Bank Statement",
                doubleSided: false,
                icon: 'file-file-blank.svg'
        },
        'other': {
                id: 7,
                name: "Other",
                doubleSided: false,
                icon: ''
        },
        'photo': {
                id: 8,
                name: "Photo ID",
                doubleSided: false,
                icon: ''
        },
        'photoSecondary': {
                id: 9,
                name: "Photo ID - Secondary",
                doubleSided: false,
                icon: ''
        },
        'incorporationArticles': {
                id: 10,
                name: "Incorporation Articles",
                doubleSided: false,
                icon: ''
        },
        'addressSecondary': {
                id: 11,
                name: "Proof of Address - Secondary",
                doubleSided: false,
                icon: ''
        },
        'addressCompany': {
                id: 12,
                name: "Proof of Address - Company",
                doubleSided: false,
                icon: ''
        },
        'addressCorporation': {
                id: 13,
                name: "Proof of Address - Corporation",
                doubleSided: false,
                icon: ''
        },
        'postOfficeBox': {
                id: 20,
                name: "Proof of Post-office box",
                doubleSided: false,
                icon: ''
        },
        'avatar': {
                id: 21,
                name: "Avatar",
                doubleSided: false,
                icon: ''
        },
        'visa': {
                id: 22,
                name: "Visa",
                doubleSided: false,
                icon: ''
        },
        'Proof of ID': {
                id: 23,
                name: 'Proof of ID',
                doubleSided: true,
                icon: ''
        }
}

export const JapanDocMap: UploadDocModel[] = [
        {
                id: 81,
                name: "drivingHistoryCertificate",
                doubleSided: true,
                // hint: "drivingHistoryCertificate"
                // priority: 1,
        },
        {
                id: 85,
                name: "myNumber",
                doubleSided: false,
                // priority: 1,
        },
        {
                id: 89,
                name: "passport",
                doubleSided: true,
                // priority: 1,
        },
        {
                id: 93,
                name: "greenCard",
                doubleSided: true,
                // priority: 2,
        },
        {
                id: 97,
                name: "residenceCard",
                doubleSided: true,
                // priority: 2,
        },
        {
                id: 101,
                name: "healthInsuranceCard",
                doubleSided: true,
                // hint: "healthInsuranceCard"
                // priority: 3,
        },
        {
                id: 105,
                name: "residenceCardForJapaneseNationals",
                doubleSided: false,
                // hint: "residenceCardForJapaneseNationals",
                // priority: 3,
        },
        {
                id: 2,
                name: "driverLicence",
                doubleSided: true,
                // priority: 1,
        },
]

export type GetTradingCentralLinkParams = {
        usi: string
        lang: string
        page: string// 'av_forex_ideas' | 'featured_forex' | 'economic_calendar' | 'buzz'
}

export type GetTradingCentralLoginLinkParams = {
        lang: string
        clientId: number
}

export type TwoFAEnabledResponse = {
        email: string
        enabled: boolean
        status: "OK" | number
}

export type SetTwoFAEnabledResponse = {
        userId: string
        secretKey: string
        barcodeKey: string
        enabled: boolean
        success: boolean
        status: "OK" | 'INVALID_CODE'
}

export type TwoFAValidateResponse = {
        status: "INVALID" | "VALID"
        success: boolean
}

export interface GenerateKeyResponse {
        userId: string
        secretKey: string
        barcodeKey: string
        enabled: boolean
        success: boolean
        status: string
}

export interface FA2QRCode {
        qrCode: string
        key: string
}
export interface FA2Status {
        status: 'ENABLED' | 'DISABLED'
}

export interface FA2VerifyRequest {
        email: string
        code: string
        recaptchaResponse?: string
}

export type TradingAccountTempPasswordResponse = {
        tempPassword: string
        serverName?: string
}

export interface AddressLookupAutocompleteParams {
        country: number
        addr_string: string
        organization: number
        email: string
        limit: number
}

export interface AddressLookupAutocompleteResponse {
        result: 'experian' // ??
        success: boolean
        addressResults?: string[]
}

export interface AddressLookupComponentsParams {
        country: number
        addr_string: string
        organization: number
        email: string
}

export interface AddressLookupComponentsResponse {
        address1: string
        address2: string
        building: string
        subBuilding: string
        street: string
        city: string
        subCity: string
        state: string
        stateProvince: string
        province: string
        zipCode: string
        countryCode2: string
        countryCode3: string
        country: string
        premise: string
        success: boolean
        pobox: boolean
        result: 'experian' // ?? should be more
}

export const finishedStatuses: ApplicationStatus[] = [
        'DENIED',
        'FAILED',
        'PENDING_APPROPRIATENESS_TEST'
]

export const pendingStatuses: ApplicationStatus[] = [
        'PENDING',
        'INCOMPLETE',
        'PENDING_ADDRESS',
        'PENDING_ID',
        'PENDING_ID_ADDRESS',
        'PENDING_KYC',
        'MANUAL_DOCS',
        'PENDING_NEW_TnC_ACCEPTANCE',
        'PENDING_IP_MISMATCH',
        'PENDING_BLURRY_POA',
        'PENDING_BLURRY_CROPPED_ID',
        'PENDING_DOB_MISMATCH',
        'PENDING_PROPER_DOCUMENTS',
        'PENDING_REVIEW',
        'PENDING_RG227'
]

export type ConfirmParams = {
        name: string
        country?: Country
        isCEflow?: boolean
        ib: IBInfo | null
}

export type UserMigrationResponse = 'ACCEPT' | 'DECLINE' | 'DEFER'

export interface MigrationInfo {
        isEligible: boolean
        isReview: boolean
        fromOrg: Enums.PortalAccountDomain
        toOrg: Enums.PortalAccountDomain
        userMigrationResponse?: UserMigrationResponse
}


export type FA2 = {
        enabled: boolean
        checked: boolean
        valid: boolean
}

export interface ChangePhoneState extends Partial<WithdrawCryptoState> {
        phone: string
        phoneCountryCode: number
        mobile: string
        mobileCountryCode: number
        page?: string
}

export type WithdrawCryptoState = {
        query: string
        amount: string
        cryptoId: number
        processorName: string
        address: string
}

export interface TrustDockResponse {
        publicId: string
        planIds: string[]
}

export interface DocJPN {
        countryId: number
        createdDate: string
        type: number
        id: number
        bothSides: boolean
        description?: string
        attachmentType: AttachmentType
}

export interface AttachmentType {
        name: string
        allowClientView: boolean
        createdDate: string
        isJumioType: boolean
        id: number
}

export interface CorrespondenceMap {
        [index: string]: Array<number | null>
}

export interface DocsJPNResponse {
        documents: DocJPN[]
        mappingLevel2: CorrespondenceMap
        mappingLevel1: Array<number | null>
}

export interface CurrencyPairRateParams {
        amount: string
        from: string
        to: string
        direction: 'sell' | 'buy'
}

export interface CurrencyPairRateResponse {
        convertedAdjustedAmount: number
        convertedAmount: number
        exchangeRate: number
}

export interface GenerateSsoTokenParams {
        email: string
}

export interface GenerateSsoTokenResponse {
        ssoToken: string
}

export interface ValidateSsoTokenParams {
        ssoCode: string
}

export interface ValidateSsoTokenResponse {
        sessionId: string
        email: string
}
export interface AccountDetails {
        currency: string
        balance: number
        equity: number
        pl: number
        realizedGains: number
        unrealizedGains: number
        realizedLosses: number
        unrealizedLosses: number
        marginLevelPercent: number
        usedMargin: number
        freeMargin: number
}

export interface AccountTrade {
        changePercent: number
        closePrice: number
        closeTime?: string
        commission: number
        openPrice: number
        openTime: string
        orderId: number
        pnl: number
        quantity: number
        side: 'SELL' | 'BUY'
        sl: number
        state: "CLOSED" | '???'
        swap: number
        symbol: string
        tp: number
        spread: number
        cumulativeCosts: number
}

export interface GetAllAccountTradesParams {
        startDate?: string
        endDate?: string
        tradingId?: number
}
export type Person = {
        firstName?: string
        lastName?: string
        dateOfBirth?: Date
        address: AddressData
        country?: Country
        phone: PhoneInputData
        email: string
        title?: string
        TFN?: string
        position?: 'director' | 'shareholder' | string
        ///////////////////////
        dayOfBirth?: number
        monthOfBirth?: number
        yearOfBirth?: number
        country_id?: number
}

export interface GreenID {
        accountID: string
        apiCode: string
        token: string
}

export type WtrAdditionalParams = {
        targetPage?: string
        status?: string
        transactionId?: string
        email?: string
        accountNumber?: number
}

export interface Tier {
        id: SubscriptionTier
        pricePerMonth: number
        ccyPairs: string
        minPerOrder: string
        maxPerOrder: string
        monthlyAmount: string | null
        maxPosition: string
}

export interface SubscriptionSubscribeParams {
        provider: string
        tier: string
        accountNumber: number
        source: 'thinkPortal'
}

export interface SubscriptionSubscribeResponse {
        traceId: number
        restrictions: any[]
        manualRefundRequired: boolean
        action: {
                type: 'post'
                url: string
                fields: { [key: string]: string }
        }
}

export interface EditBankInfoParams {
        provider: string
        accountNumber: number
}

export interface EditBankInfoResponse {
        provider: string
        accountNumber: number
}

export type SubscriptionTier = 'Tier1' | 'Tier2' | 'Tier3'
export type SubscriptionStatus = 'noRecord' | 'ACTIVE' | 'CANCELED' | 'INCOMPLETE' | 'STOPPED' | 'UNPAID' | 'SUSPENDED' | 'DENIED' | 'TERMINATED' | 'EXISTING' | 'IN_PROGRESS'

export interface GetSubscriptionStatusParams {
        provider: string
        accountNumber: number
}

export interface GetSubscriptionStatusResponse {
        currentSubscriptionTier: SubscriptionTier
        nextSubscriptionTier: SubscriptionTier
        status: SubscriptionStatus
}

export interface ChangeTierParams {
        provider: string
        accountNumber: number
        requestedTier: SubscriptionTier
}

type GeneralSubscriptionResultStatus = 'noRecord' | 'notAllowed' | 'notApplicable'

export interface ChangeTierResponse {
        result: 'changed' | GeneralSubscriptionResultStatus
}

export interface StopSubscriptionParams {
        provider: string
        accountNumber: number
}

export interface StopSubscriptionResponse {
        result: 'stopped' | 'openPositions' | 'remainingBalance' | 'pendingOrders' | GeneralSubscriptionResultStatus
}

export interface CheckOpenPositionsTTParams {
        provider: string
        accountNumber: number
}

export interface CheckOpenPositionsTTResponse {
        status: 'noOpenPositions' | 'openPositions'
}

export interface GetPaymentDetailsParams {
        provider: string
        accountNumber: number
        requestedTier: SubscriptionTier
}

export interface GetPaymentDetailsResponse {
        customerName: string
        accountNumber: string
        bankName: string
}

export interface GetBillingHistoryParams {
        provider: string
        accountNumber: number
}

export interface SubscriptionBillingHistoryItem {
        amount: string
        billingTier: SubscriptionTier
        id: number
        month: string
        year: string
}

export interface RestartSubscriptionParams {
        provider: string
        accountNumber: number
}

export interface RestartSubscriptionResponse {
        result: 'restarted' | GeneralSubscriptionResultStatus
}

export interface CancelSubscriptionParams {
        provider: string
        accountNumber: number
}

export interface CancelSubscriptionResponse {
        result: 'canceled' | 'openPositions' | 'remainingBalance' | GeneralSubscriptionResultStatus
}

export interface LatestTermsAndConditions {
        latestVersion: string
        url: string
}

export interface UserTermsAndConditions {
        customerVersion?: string
        latestVersion?: string
}

export interface TrustedDeviceListResponse {
        id: number
        addedAt: string
        validUntil: string
        userAgent: string
        location: string
}

export interface ClientRequest {
        createdDate: string
        description?: string
        id: number
        isRatingRequested: boolean
        subject: string
        requestNumber?: string
        ticketNumber?: string
        leverage?: string
        tradingAccountNumber?: number
        requestType: SupportRequestType
        rating?: string
        ratingComment?: string
        status: SyncStatus
}

export type SyncStatus = 'Closed' | 'Open' | 'In Progress'

export interface SupportRequestType {
        id: number
        name: string
        code: RequestType
}

export interface SaveSupportRequest {
        requestTypeId: number
        tradingAccountId?: number
        amount?: number
        subject: string
        description?: string
        tradeTicketNumber?: string | null
        leverage?: string
        files?: string[]
}

export interface GetAllSupportRequests {
        currentPage?: number
        orderBy?: 'REQUEST_TYPE_NAME' | 'TRADING_ACCOUNT_NUMBER' | 'REQUEST_NUMBER' | 'CREATED_DATE' | 'SUBJECT' | 'STATUS' | 'RATING'
        orderDirection?: 'ASC' | 'DESC'
        // filters
        tradingAccountId?: string
        requestTypeId?: string
        fromCreatedDate?: string
        toCreatedDate?: string
        requestNumber?: string
        status?: string
        subjectAndDescription?: string
}

export interface GetAllSupportRequestsResponse {
        currentPage: number
        totalPages: number
        data: ClientRequest[]
}

export interface RateSupportRequestParameters {
        id: number
        rating: number
        comment: string
}

export type ErrorAuthResponse = {
        code: string
        description: string
}

export type AuthTokens = {
        accessToken: string
        refreshToken: string
        refreshTokenValidUntil: string
}
export interface AuthResponse {
        tokens: AuthTokens
        status: string | number
        redirectURI: string
}

export interface JWTTokenData {
        sub: string
        tsc: string
        iss: string
        exp: number
        iat: number
        jti: string
}
export interface GetUserTradingVolumeResponse {
        startDate?: string
        currency?: string
        statusMsg?: string
        depositAmountSinceStartDate?: number
        tradedVolumeSinceStartDate?: number
        remainingMinTradedVolume?: number
        blockedDepositAmount?: number
        isSuccessCalculation?: boolean
        existUserConfiguration?: boolean
}
export interface UserReferrerStatus {
        allowedToBeReferrer: boolean
        existingReferrer: boolean
}

export interface QualificationForTheReferrer {
        isFullyKYCed: boolean
        sumOfAllDepositsIsMoreThan250USD: boolean
        userTradingVolumeIsMoreThan100kUSD: boolean
        overallStatus: boolean
}

export interface AddNewReferrerResponse {
        userId: string
        statusMsg: string
}

export interface GenerateRefLinkResponse {
        refLink: string
}

export interface ReferralActivity {
        amount: number
        currencyCode: string
        refereeName: string
        refereeCountry: string
        applicationDate: string
        applicationStatus: string
        depositMade: boolean
        tradedOneLot: boolean
        paidOutStatus: 'Unpaid' | 'Paid'
        statusMsg: string
}

export type SignalCenterEnabledResponse = {
        allowed: boolean
}
export interface RemainingDeposit {
        netSumOfAppliedDeposits: number
        netMinDepositAmount: number
        currencyCode: string
}

export type BrokerName = string