import { useCallback, useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'

import Utils from '../../../../utils/utils'
import { useCurrencyConverter, useFetchCurrencyPairRate } from '../../../../utils/useCurrencyConverter'
import { useUserApplications } from '../../../../utils/hooks'
import api from '../../../../utils/api'
import { useCompleteOnboardingModal } from './CompleteOnboardingNotice'

export const TOTAL_DEPOSITS_LIMIT_CURRENCY = 'USD'
export const TOTAL_DEPOSITS_LIMIT_AMOUNT = 10000
export const TOTAL_DEPOSITS_LIMIT_AMOUNT_FORMATTED = '10,000'

export const useTotalDepositsLimit = (accountCurrency?: string) => {
  const { t } = useTranslation()
  const { simplifiedAppNotApprovedYet } = useUserApplications()
  const { fetchCurrencyPairRate, exchangeRate } = useFetchCurrencyPairRate('sell')
  const { getExchangeRate } = useCurrencyConverter()
  const [totalDepositsInAccountCurrency, setTotalDepositsInAccountCurrency] = useState<number>(0)
  const cryptoExchangeRate = exchangeRate?.exchangeRate ? 1 / exchangeRate?.exchangeRate : 1
  const { noticeModal, setNoticeModalOpened } = useCompleteOnboardingModal('deposit')

  const getRateFuncForTotalDeposits = useCallback((isCrypto: boolean) => {
    return isCrypto ? () => cryptoExchangeRate : getExchangeRate
  }, [cryptoExchangeRate, getExchangeRate])

  useEffect(() => {
    const getTransactions = async () => {
      const transactionResult = await api.getTransactions()
      if (Utils.validResponse(transactionResult)) {
        const totalDepositsInAccountCurrency = transactionResult.payload[0].result
          .filter(x => (x.type === 'DEPOSIT' && ['APPLIED', 'APPROVED'].includes(x.status)))
          .reduce((prev, curr) => prev + curr.amount * curr.exchangeRate, 0)
        setTotalDepositsInAccountCurrency(totalDepositsInAccountCurrency)
      }
    }

    getTransactions()
  }, [])

  const fetchRateForTotalDepositsLimitCheck = useCallback((currencyCode: string) => {
    if (simplifiedAppNotApprovedYet) {
      fetchCurrencyPairRate('1', currencyCode, 'USD')
    }
  }, [fetchCurrencyPairRate, simplifiedAppNotApprovedYet])

  const getTotalDepositsLimit = (resultCurrency: string, isCrypto: boolean, limitAmount: number) => {
    const getRate = getRateFuncForTotalDeposits(isCrypto)
    const rate = getRate(TOTAL_DEPOSITS_LIMIT_CURRENCY, resultCurrency)
    const result = limitAmount * rate
    return +result.toFixed(2)
  }

  const getDepositLimitError = (resultCurrency: string, normalizedAmount: number, isCrypto: boolean) => {
    if (!simplifiedAppNotApprovedYet) return undefined

    const totalDepositsLimit = getTotalDepositsLimit(resultCurrency, isCrypto, TOTAL_DEPOSITS_LIMIT_AMOUNT)
    if (totalDepositsLimit && normalizedAmount > totalDepositsLimit) {
      return t(`common:errors:totalDepositsLimitForSimplified`) + ` ${TOTAL_DEPOSITS_LIMIT_CURRENCY} ${TOTAL_DEPOSITS_LIMIT_AMOUNT_FORMATTED}`
    }
  }

  const checkIfExceedsTotalDepositsAmount = (resultCurrency: string, normalizedAmount: number, isCrypto: boolean) => {
    if (!simplifiedAppNotApprovedYet || !accountCurrency) return false

    const rate = getExchangeRate(accountCurrency, 'USD')
    const alreadyHasInUSD = rate * totalDepositsInAccountCurrency

    const totalDepositsLimit = getTotalDepositsLimit(resultCurrency, isCrypto, TOTAL_DEPOSITS_LIMIT_AMOUNT - alreadyHasInUSD)
    if (normalizedAmount > totalDepositsLimit) {
      setNoticeModalOpened(true)
      return true
    }

    return false
  }

  const openExceededTotalModal = () => setNoticeModalOpened(true)

  return { fetchRateForTotalDepositsLimitCheck, getDepositLimitError, checkIfExceedsTotalDepositsAmount, exceededTotalModal: noticeModal, openExceededTotalModal }
}
