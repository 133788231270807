import { useCallback, useEffect, useState } from "react"
import { useDispatch } from "react-redux"
import { appConfig } from "../config"
import { setOriginalExchangeRate } from "../redux/slices/app"
import api from "./api"
import { CROSS_RATE_CURRENCY } from "./enums"
import { CurrencyGraph, CurrencyPairRateParams, CurrencyPairRateResponse, ExchangeRate } from "./types"

export const makeCurrencyGraph = (pairs: ExchangeRate[]): CurrencyGraph => {
  const graph: CurrencyGraph = {}
  pairs.forEach(x => {
    const baseCurrency = x.symbol.slice(0, 3)
    const counterCurrency = x.symbol.slice(3, 6)

    if (graph[baseCurrency] === undefined) {
      graph[baseCurrency] = {}
    }
    if (graph[counterCurrency] === undefined) {
      graph[counterCurrency] = {}
    }

    graph[baseCurrency][counterCurrency] = x.bid
    graph[counterCurrency][baseCurrency] = 1 / x.ask
  })

  return graph
}

export const makeExchangeRateGetter = (currencyGraph: CurrencyGraph) =>
  (fromCurrency: string, toCurrency: string) => {
    try {
      if (fromCurrency === toCurrency) {
        return 1
      }

      if (!currencyGraph[fromCurrency]) {
        return 1
      }

      if (currencyGraph[fromCurrency][toCurrency]) {
        return currencyGraph[fromCurrency][toCurrency]
      }

      return currencyGraph[fromCurrency][CROSS_RATE_CURRENCY] * currencyGraph[CROSS_RATE_CURRENCY][toCurrency]
    } catch (error) {
      console.error('Error during conversion from ', fromCurrency, ' into ', toCurrency, ' with ', currencyGraph)
      return 1
    }
  }

export const useCurrencyConverter = () => {
  const [isLoading, setIsLoading] = useState(false)
  const [currencyGraph, setCurrencyGraph] = useState<CurrencyGraph>({})

  useEffect(() => {
    const fetchData = async () => {
      setIsLoading(true)
      const response = await fetch(appConfig.RATES_URL)
      const result: ExchangeRate[] = await response.json()
      const graph = makeCurrencyGraph(result)
      setCurrencyGraph(graph)
      setIsLoading(false)
    }
    fetchData()
  }, [])

  const getExchangeRate = makeExchangeRateGetter(currencyGraph)

  return { isLoading, currencyGraph, getExchangeRate }
}

export const useFetchExchangeRate = () => {
  const [isLoading, setIsLoading] = useState(false)

  const fetchExchangeRate = useCallback(async (from: string, to: string) => {
    setIsLoading(true)
    const response = await fetch(`${appConfig.RATES_URL}/${from}/${to}`)
    const result: number | null = await response.json()
    setIsLoading(false)
    return result || 0
  }, [])

  return { isLoading, fetchExchangeRate }
}

export const useFetchCurrencyPairRate = (direction: CurrencyPairRateParams['direction']) => {
  const [rate, setRate] = useState<CurrencyPairRateResponse | null>(null)
  const dispatch = useDispatch()

  const fetchCurrencyPairRate = useCallback(async (amount: string, from: string, to: string) => {
    setRate(null)
    try {
      const response = await api.getCurrencyPairRate({
        amount: amount,
        direction: direction,
        from: from,
        to: to,
      })
      setRate(response)
    } catch (error) {
      console.log(error)
    }
  }, [direction])

  useEffect(() => {
    dispatch(setOriginalExchangeRate(rate || null))
  }, [rate, dispatch])

  return { fetchCurrencyPairRate, exchangeRate: rate }
}

export const useFetchBidAskRate = () => {
  const [isLoading, setIsLoading] = useState(false);

  const fetchBidAskRate = useCallback(async (from: string, to: string) => {
    setIsLoading(true)
    const response = await fetch(`${appConfig.RATES_URL}/${from}${to}`)
    const result: number | null = await response.json()
    setIsLoading(false)
    return result || 0
  }, [])

  return { isLoading, fetchBidAskRate }
}