import { apm } from '@elastic/apm-rum'
import React, { Component, Dispatch, ErrorInfo, PropsWithChildren } from 'react'
import { WithTranslation, withTranslation } from 'react-i18next'
import { connect } from 'react-redux'
import ContactUsInfo from '../../Presentational/ContactUsInfo'
import styles from './style.module.scss'

type State = {
    error: Error | null
    errorInfo: ErrorInfo | null
}

interface Props extends WithTranslation { dispatch: Dispatch<any> }

class AppErrorBoundary extends Component<PropsWithChildren<Props>, State> {
    constructor(props: PropsWithChildren<Props>) {
        super(props)
        this.state = { error: null, errorInfo: null }
    }
    // close() {
    // dispatch(appError(null))
    // }

    componentDidCatch(error: Error, errorInfo: any) {
        if (!this.state.error) apm.captureError(error)
        this.setState({
            error: error,
            errorInfo: errorInfo
        })
    }


    render() {
        if (this.state.errorInfo) {
            const { t } = this.props
            /*
            return <Modal
                isOpen={true}
                // onModalClose={close}
                showCross={false}
            >
                {<div className={styles.container}>
                    {t('common:appError.caption')}

                    <button className={cn('black-border', styles.button)} onClick={this.close}>
                        {t('common:appError.button')}
                    </button>
                </div>
                }
            </Modal>
*/
            return (
                <div className={styles.error}>
                    <ContactUsInfo className={styles.contact} buttonClassName={styles.liveChatButton} />
                    <h2>{t('runtime_error')}</h2>
                    <br />
                    {this.state.errorInfo.componentStack}
                </div>
            )
        }
        return this.props.children
    }
}

export default connect()(withTranslation('common')(AppErrorBoundary))