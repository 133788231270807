import queryString from "query-string"
import { FC, useEffect, useState } from 'react'
import { useLocation } from 'react-router'
import api from '../../../../../utils/api'
import { hideGlobalLoader } from '../../../../GlobalLoader'
import Image from "../../../../Presentational/Image"
import CheckoutFrames from './CheckoutFrames'
import styles from "./style.module.scss"




const CheckoutFramesPage: FC = () => {
    const location = useLocation()
    const [processorID, setProcessorID] = useState<number | null>(null)
    const [accountID, setAccountID] = useState<number | null>(null)
    const [amount, setAmount] = useState<string | null>(null)
    const [currencyID, setCurrencyID] = useState<number | null>(null)
    const query = queryString.parse(location.search)

    useEffect(() => {
        hideGlobalLoader()
    }, [])

    useEffect(() => {
        const session = typeof query.session === 'string' ? query.session : null
        const token = typeof query.token === 'string' ? query.token : null
        const processorID = typeof query.processorID === 'string' ? +query.processorID : null
        const accountID = typeof query.accountID === 'string' ? +query.accountID : null
        const amount = typeof query.amount === 'string' ? query.amount : null
        const currencyID = typeof query.currencyID === 'string' ? +query.currencyID : null
        setTimeout(() => {
            if (session && token) {
                api.setTFBOAuth(session, token)
                setProcessorID(processorID)
                setAccountID(accountID)
                setAmount(amount)
                setCurrencyID(currencyID)
            }
        }, 500)
    }, [query.session, query.token, query.processorID, query.amount, query.currencyID, query.accountID])

    return (processorID && amount && currencyID && accountID) ? (
        <div className={styles.checkoutpage}>
            <Image className={styles.thinklogo} name='tmLogo' />
            <div className={styles.frames}>
                <CheckoutFrames
                    account={{
                        account: {
                            id: accountID
                        }
                    }}
                    processor={{
                        id: processorID,
                        name: 'CheckoutCom'
                    }}
                    amount={amount}
                    currency={{
                        code: '',
                        id: currencyID,
                    }}
                    isAmountFormValid
                />
            </div>
        </div>
    ) : null
}


export default CheckoutFramesPage