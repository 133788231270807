import moment from 'moment'
import 'moment/locale/ar'
import 'moment/locale/cs'
import 'moment/locale/de'
import 'moment/locale/el'
import 'moment/locale/es'
import 'moment/locale/id'
import 'moment/locale/it'
import 'moment/locale/ms'
import 'moment/locale/pl'
import 'moment/locale/pt'
import 'moment/locale/th'
import 'moment/locale/vi'
import 'moment/locale/zh-cn'
import 'moment/locale/ja'
import React, { memo, useMemo } from 'react'
import { useTranslation } from 'react-i18next'
import { useSelector } from 'react-redux'
import { Store } from '../../../redux/store'
import styles from "./header.module.scss"
import cn from 'classnames'

type LastLoginProps = {
    className?: string
}

const LastLogin = memo((props: LastLoginProps) => {
    const { t } = useTranslation('account')
    const store = useSelector((state: Store) => state.App)
    const { userLoginInfo, language } = store

    const date = useMemo(() => {
        let locale: string = language
        switch (language) {
            case 'zh-Hans':
            case 'zh-Hant':
                locale = 'zh-cn'
                break
            default:

        }
        if (userLoginInfo) {
            const date = moment.utc(userLoginInfo.last_login_date)
            date.locale(locale)
            return date.format('llll')
        }
        return null
    }, [language, userLoginInfo])


    return userLoginInfo
        ? <div className={cn(styles['last-login'], props.className)}>{`${t('lastLogin')}: ${date}`}</div>
        : null
})

export default LastLogin