import { useCallback } from 'react'
import { useHistory } from 'react-router-dom'

import appRoutes from "../utils/routes"
import { useAppState } from './hooks'

export const useEmailRedirect = () => {
    const history = useHistory()
    const { userProfile, userEmailVerified, userEmailPreverified } = useAppState()

    const redirect = useCallback(async () => {
        if (
            !userEmailPreverified &&
            (!userEmailVerified && userProfile?.country?.forceEmailValidate)
        ) {
            history.push(appRoutes.account.validateEmail)
            return false
        }

        return true
    }, [history, userEmailPreverified, userEmailVerified, userProfile])

    return redirect
}