import React, { FC } from 'react'
import Sticky from 'react-sticky-el'
import styles from './style.module.scss'

const StickyWarning: FC<{
    disabled?: boolean
    scrollElement?: string
}> = ({ disabled = false, scrollElement = '#base', children }) => {
    return <Sticky
        mode='bottom'
        scrollElement={scrollElement}
        stickyClassName={styles.stickyWarning}
        positionRecheckInterval={500}
        disabled={disabled}
    >
        {children}
    </Sticky>
}


export default StickyWarning