export interface ConfigFile {
    ENV: string
    API_URL: string
    UPLOAD_URL: string
    CLIENT_REQUEST_UPLOAD_URL: string
    PAYMENT_URL: string
    RATES_URL: string
    CAPTCHA_URL: string
    WITHDRAWAL_RECEIPT_URL: string
    CLIENT_STATEMENT_URL: string
    WTR_URL: string
    TRADE_STATEMENT_CSV_URL: string
    FA2_URL: string
    AUTH_URL: string
    ADDRESS_LOOKUP_URL: string
    EXCHANGE_RATES_URL: string
    CORPORATE_URL: string
    PULL_NOTIFICATIONS_SEC: number
    PULL_APP_STATUS_SEC: number
    DEFAULT_LANGUAGE: string
    APM_URL: string
    VERIFICATION_ATTEMPTS: number
    VERIFICATION_INTERVAL_SEC: number
    LOGOUT_AFTER_MIN: number
    REACT_APP_RECAPTCHA_SITE_KEY_V3: string
    REACT_APP_HCAPTCHA_KEY: string
    CORPORATE?: boolean
    SIGNALS_URL: string
    IB_INVOICE_URL: string
}

let appConfig: ConfigFile
const configFileName = process.env.NODE_ENV === 'production' ? 'config.json' : 'config.dev.json'

const appInit = (() => {
    return fetch(`/${configFileName}?v=${new Date().getTime()}`)
        .then(response => response.json())
        .then((response: ConfigFile) => {
            appConfig = {
                ...response,
                AUTH_URL: response.AUTH_URL,
                API_URL: response.API_URL + '/nsdata',
                UPLOAD_URL: response.API_URL + '/upload',
                CLIENT_REQUEST_UPLOAD_URL: response.API_URL + '/supportRequestFileUpload',
                PAYMENT_URL: response.API_URL + '/payment',
                CAPTCHA_URL: response.API_URL + '/captcha',
                RATES_URL: response.RATES_URL + '/exchangerates',
                WITHDRAWAL_RECEIPT_URL: response.API_URL + '/withdrawalReceipt',
                IB_INVOICE_URL: response.API_URL + '/invoiceDownload',
                CLIENT_STATEMENT_URL: response.API_URL + '/clientstatement',
                TRADE_STATEMENT_CSV_URL: response.API_URL + '/tradeStatementCsv',
            }
            if (process.env.NODE_ENV === 'production' && appConfig.ENV === 'dev')
                throw new Error('Development configuration file in production build')
            return appConfig
        })
        .catch(e => {
            const m = `Error fetching configuration from server\n${e.message}`
            throw new Error(m)
        })
})()

export { appConfig, appInit }
