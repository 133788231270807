import cn from 'classnames'
import React, { FC, PropsWithChildren } from 'react'
import styles from './sticky.module.scss'
type StickyProps = {
    className?: string; stick?: boolean, position?: 'top' | 'bottom'
}

const Sticky: FC<PropsWithChildren<StickyProps>> = ({ className, stick = false, position, children }) => {
    const style = position
        ? position === 'top' ? { top: 0 } : { bottom: 0 }
        : {}
    return stick
        ? <div className={cn(className, styles.sticky)} style={style}>
            {children}
        </div>
        : <div className={className} >
            {children}
        </div >
}


export default Sticky