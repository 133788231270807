import cn from 'classnames'
import React, { FC } from 'react'
import { useSelector } from 'react-redux'
import { Store } from '../../redux/store'
import { ReactComponent as Image } from "../../resources/images/loader.svg"
import { ProgressBar } from '../Presentational/ProgressBar'
import styles from "./style.module.scss"

export enum LoaderMode { CoverParent, StandAlone, FullScreen }
interface LoaderProps {
    className?: string
    imgClassName?: string
    mode?: LoaderMode
    mini?: boolean
}

const Loader: FC<LoaderProps> = ({ className, imgClassName, mode = LoaderMode.FullScreen, mini = false }) => {
    const state = useSelector((state: Store) => state.UI.Loader)

    const classes = cn(
        className,
        styles.loader,
        {
            [styles.cover]: mode === LoaderMode.CoverParent,
            [styles.full]: mode === LoaderMode.FullScreen,
            [styles.mini]: mini
        }
    )

    return (
        <div className={classes}>
            <Image className={imgClassName} />
            {state.showProgress &&
                <ProgressBar big percents={state.progressStep / state.progressSteps} className={styles.progress} />
            }
        </div>
    )
}

export default Loader