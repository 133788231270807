import { PayloadAction } from '@reduxjs/toolkit'
// import { PayloadAction } from '@reduxjs/toolkit'
import { createSlice } from '@reduxjs/toolkit'
import { NavigationButton, NavigationLink } from '../../components/TopNavigation'

export type AccountsView = "LIVE" | "DEMO" | "ARCHIVED"

export type DashboardState = {
    showSidebar: boolean
    links: NavigationLink[] | null
    buttons: NavigationButton[] | null
    pageFooter: React.ReactNode | null
    pageBanner: React.ReactNode | null
    simplifiedLevelTwoRootPage?: string
    accountsView?: AccountsView
}

const initialState: DashboardState = {
    showSidebar: true,
    links: null,
    buttons: null,
    pageFooter: null,
    pageBanner: null,
    simplifiedLevelTwoRootPage: undefined,
    accountsView: undefined
}

const slice = createSlice({
    name: 'dashboard',
    initialState: initialState,
    reducers: {
        toggleSidebar: (state: DashboardState) => {
            state.showSidebar = !state.showSidebar
        },
        hideSidebar: (state: DashboardState) => {
            state.showSidebar = false
        },
        showSidebar: (state: DashboardState) => {
            state.showSidebar = true
        },
        clearNavigation: (state: DashboardState) => {
            state.links = null
            state.buttons = null
        },
        setNavigation: (state: DashboardState, action: PayloadAction<{
            links: NavigationLink[] | null
            buttons: NavigationButton[] | null
        }>) => {
            state.links = action.payload.links
            state.buttons = action.payload.buttons
        },
        setPageFooter: (state: DashboardState, action: PayloadAction<React.ReactNode | null>) => {
            state.pageFooter = action.payload
        },
        setPageBanner: (state: DashboardState, action: PayloadAction<React.ReactNode | null>) => {
            state.pageBanner = action.payload
        },
        setSimplifiedLevelTwoRootPage: (state: DashboardState, action: PayloadAction<string | undefined>) => {
            state.simplifiedLevelTwoRootPage = action.payload
        },
        setAccountsView: (state: DashboardState, action: PayloadAction<AccountsView | undefined>) => {
            state.accountsView = action.payload
        },

    }
})

const { actions, reducer } = slice

export const { toggleSidebar,
    showSidebar,
    hideSidebar,
    clearNavigation,
    setPageFooter,
    setPageBanner,
    setSimplifiedLevelTwoRootPage,
    setNavigation,
    setAccountsView
} = actions
export default reducer