import React, { useCallback, useMemo } from 'react'
import { renderToString } from 'react-dom/server'
import { useTranslation } from 'react-i18next'
import { useDangerousHTML, useRenderLink } from '../../utils/hooks'

const prefix = 'footer:individual.NZ'
const links = `${prefix}.links`

export const IndividualNZ = () => {
    const { t } = useTranslation()
    const { renderLink: render } = useRenderLink()

    const renderLink = useCallback((name) => {
        return render(t(`${links}.${name}.link`), t(`${links}.${name}.text`))
    }, [render, t])

    const privacyPolicy = useMemo(() => renderLink('privacyPolicy'), [renderLink])
    const complaints = useMemo(() => renderLink('complaints'), [renderLink])
    const productDisclosure = useMemo(() => renderLink('productDisclosure'), [renderLink])
    const insurance = useMemo(() => renderLink('insurance'), [renderLink])
    const mail = useMemo(() =>
        renderToString(<a href={`mailto:${t(`${links}.mail.link`)}`}>
            {t(`${links}.mail.text`)}
        </a>)
        , [t])

    const { p } = useDangerousHTML()

    return <>
        {p(t(`${prefix}.text1`))}
        {p(t(`${prefix}.text2`, { link: productDisclosure }))}
        {p(t(`${prefix}.text3`, { privacyPolicy, complaints }))}
        {p(t(`${prefix}.text4`, { link: insurance }))}
        {p(t(`${prefix}.text5`, { mail }))}
        {p(t(`${prefix}.text6`))}
        {p(t(`${prefix}.text`))}
    </>
}
