import { ApmRoute } from '@elastic/apm-rum-react'
import React, { memo, useCallback, useMemo } from 'react'
import { useSelector } from 'react-redux'
import { Redirect, Route, RouteComponentProps, Switch } from "react-router-dom"
import { AppState } from '../../../redux/appState'
import { Store } from '../../../redux/store'
import { PortalAccountDomain } from '../../../utils/enums'
import { useLandingPage } from '../../../utils/hooks'
import r from "../../../utils/routes"
import Layout from './Layout'
import Login from './Login'

const ClientJourneySteps = React.lazy(() => import('./ClientJourneySteps'))
const Create = React.lazy(() => import('./Create'))
const CreateDemo = React.lazy(() => import('./Demo'))
const Email = React.lazy(() => import('./Email'))
const Check2FA = React.lazy(() => import('./Check2FA'))
const ViewResetPasswordStep1 = React.lazy(() => import('./ResetPassword/Step1'))
const ViewResetPasswordStep2 = React.lazy(() => import('./ResetPassword/Step2'))
const ViewResetPasswordStep3 = React.lazy(() => import('./ResetPassword/Step3'))
const ViewResetPasswordStep4 = React.lazy(() => import('./ResetPassword/Step4'))
const SelectPlatform = React.lazy(() => import('./SelectPlatform'))
const FinancialDetails = React.lazy(() => import('./FinancialDetails'))
const FinancialDetailsSecondPage = React.lazy(() => import('./FinancialDetails/secondStep'))
const UsaCitizenship = React.lazy(() => import('./UsaCitizenship'))
const ForeignExchangeBusiness = React.lazy(() => import('./ForeignExchangeBusiness'))
const PepLegalConsent = React.lazy(() => import('./PepLegalConsent'))
const ForexConfirmation = React.lazy(() => import('./ForexConfirmation'))
const ChessSponsorship = React.lazy(() => import('./ChessSponsorship/MainAreas/Individual'))
const TraderClassification = React.lazy(() => import('./TraderClassification'))
const AppropriatenessTest = React.lazy(() => import('./AppropriatenessTest'))
const IndividualAdditionalTestTMAU = React.lazy(() => import('./AppropriatenessTest/IndividualAdditionalTestTMAU'))
const Experience = React.lazy(() => import('./Experience'))
const AccountTypeSelectionJPN = React.lazy(() => import('./AccountTypeSelectionJPN'))
const IndividualAURefer = React.lazy(() => import('./Experience/fails/IndividualAURefer'))
const IndividualFailed = React.lazy(() => import('./Experience/fails/IndividualFailed'))
const IndividualAUFailed = React.lazy(() => import('./Experience/fails/IndividualAUFailed'))
const IndividualTMCYFailed = React.lazy(() => import('./Experience/fails/IndividualTMCYFailed'))
const IndividualTMJPFailed = React.lazy(() => import('./Experience/fails/IndividualTMJPFailed'))
const IndividualUKRefer = React.lazy(() => import('./Experience/fails/IndividualUKRefer'))
const IndividualTMCYRefer = React.lazy(() => import('./Experience/fails/IndividualTMCYRefer'))
const Consent = React.lazy(() => import('./Consent'))
const UploadDocs = React.lazy(() => import('./UploadDocs'))
const Deposit = React.lazy(() => import('./Deposit'))
const Congrats = React.lazy(() => import('./Congrats'))
const AccountCreatedLevelOne = React.lazy(() => import('./AccountCreatedLevelOne'))
const PersonalInformation = React.lazy(() => import('./PersonalInformation'))
const ContactAndAddress = React.lazy(() => import('./ContactAndAddress'))
const PhoneNumber = React.lazy(() => import('./PhoneNumber'))
const SmsVerification = React.lazy(() => import('./SmsVerification'))
const CorporateInfo = React.lazy(() => import('./Corporate/CorporateInfo'))
const Contacts = React.lazy(() => import('./Corporate/Contacts'))
const CorporateAddress = React.lazy(() => import('./Corporate/Address'))
const PlaceOfBuisiness = React.lazy(() => import('./Corporate/PlaceOfBusiness'))
const Regulation = React.lazy(() => import('./Corporate/Regulation'))
const Representative = React.lazy(() => import('./Corporate/Representative'))
const Directors = React.lazy(() => import('./Corporate/Directors'))
const Shareholders = React.lazy(() => import('./Corporate/Shareholders'))
const CorporateFinDetails = React.lazy(() => import('./Corporate/FinancialDetails'))
const SimplifiedContactAndAddress = React.lazy(() => import('./SimplifiedContactAndAddress'))
const SimplifiedAccountPendingApproval = React.lazy(() => import('./SimplifiedAccountPendingApproval'))
const PAMMQuestionnaire = React.lazy(() => import('./PAMMQuestionnaire'))
const IBQ1 = React.lazy(() => import('../../../ib/onboarding/Q1'))
const IBQ2 = React.lazy(() => import('../../../ib/onboarding/Q2'))
const IBLegal = React.lazy(() => import('../../../ib/onboarding/Legal'))
const IBAddress = React.lazy(() => import('../../../ib/onboarding/Address'))
const IBAccountCreated = React.lazy(() => import('../../../ib/onboarding/AccountCreated'))
const IBPendingApproval = React.lazy(() => import('../../../ib/onboarding/PendingApproval'))
const IBUploadDocs = React.lazy(() => import('../../../ib/onboarding/UploadDocs'))
// const CorporateExperience = React.lazy(() => import('./Corporate/Experience'))

interface AccountProps extends RouteComponentProps { }

const Account = (_: AccountProps) => {
       const store: AppState = useSelector(
              (state: Store) => state.App)

       const { portalAccountDomain, loggedIn } = store
       const landingPage = useLandingPage()

       const redirectUrl = useCallback(() => {
              if (loggedIn) {
                     return landingPage
              }
              else return r.account.login
       }, [landingPage, loggedIn])

       const failed = useMemo(() => {
              switch (portalAccountDomain) {
                     case PortalAccountDomain.TMCY:
                            return <IndividualTMCYFailed />
                     case PortalAccountDomain.TMJP:
                            return <IndividualTMJPFailed />
                     case PortalAccountDomain.AU:
                     case PortalAccountDomain.TMNZ:
                            return <IndividualAUFailed />
                     default: return <IndividualFailed />
              }
       }, [portalAccountDomain])

       const refer = useMemo(() => {
              switch (portalAccountDomain) {
                     case PortalAccountDomain.TMCY:
                            return <IndividualTMCYRefer />
                     case PortalAccountDomain.AU:
                     case PortalAccountDomain.TMNZ:
                            return <IndividualAURefer />
                     case PortalAccountDomain.UK:
                            return <IndividualUKRefer />
                     default: return <IndividualUKRefer />
              }
       }, [portalAccountDomain])

       return useMemo(() => {
              const to = (path: string) => {
                     return {
                            path,
                            key: path
                     }
              }
              return <Switch>
                     <Route exact {...to(r.register.ib)}
                            render={props => (<Layout stickWarning={true} ><Create {...props} /></Layout>)} />
                     <Route exact {...to(r.register.demo)}>
                            <Redirect to={r.account.createAccount.demo} />
                     </Route>
                     <Route exact path={[r.register.root, r.register.live]}>
                            <Redirect to={r.account.createAccount.live} />
                     </Route>
                     <Route exact {...to(r.welcome.demo)}>
                            <Redirect to={r.account.createAccount.demo} />
                     </Route>
                     <Route exact {...to(r.account.createAccount.demo)}
                            component={CreateDemo} />
                     <ApmRoute exact {...to(r.account.login)}
                            component={Login} />
                     <Route {...to(r.account.createAccount.live)}
                            render={props => (<Layout stickWarning={true} ><Create {...props} /></Layout>)} />
                     <Route {...to(r.account.corporate.corporateInformation)}
                            component={() => (<Layout><CorporateInfo /></Layout>)} />
                     <Route {...to(r.account.corporate.contacts)}
                            component={() => (<Layout><Contacts /></Layout>)} />
                     <Route {...to(r.account.corporate.address)}
                            component={() => (<Layout><CorporateAddress /></Layout>)} />
                     <Route {...to(r.account.corporate.placeOfBusiness)}
                            component={() => (<Layout><PlaceOfBuisiness /></Layout>)} />
                     <Route {...to(r.account.corporate.regulation)}
                            component={() => (<Layout><Regulation /></Layout>)} />
                     <Route {...to(r.account.corporate.representative)}
                            component={() => (<Layout><Representative /></Layout>)} />
                     <Route {...to(r.account.corporate.directors)}
                            component={() => (<Layout><Directors /></Layout>)} />
                     <Route {...to(r.account.corporate.shareholders)}
                            component={() => (<Layout><Shareholders /></Layout>)} />
                     <Route {...to(r.account.corporate.financialDetails)}
                            component={() => (<Layout><CorporateFinDetails /></Layout>)} />
                     <Route {...to(r.account.corporate.experience)}
                            render={props => (<Layout><Experience /></Layout>)} />
                     <Route exact {...to(r.account.corporate.appropriatenessTest)}
                            component={() => (<Layout><AppropriatenessTest /></Layout>)} />
                     <Route exact {...to(r.account.corporate.appropriatenessTestAdditional)}
                            component={() => (<Layout><IndividualAdditionalTestTMAU /></Layout>)} />
                     <Route {...to(r.account.corporate.forexConfirmation)}
                            component={() => (<Layout><ForexConfirmation /></Layout>)} />
                     <ApmRoute {...to(r.account.resetPasswordStep1)}
                            component={ViewResetPasswordStep1} />
                     <ApmRoute {...to(r.account.resetPasswordStep2)}
                            component={ViewResetPasswordStep2} />
                     <ApmRoute {...to(r.account.resetPasswordStep3)}
                            component={ViewResetPasswordStep3} />
                     <ApmRoute {...to(r.account.resetPasswordStep4)}
                            component={ViewResetPasswordStep4} />
                     {/* Application process */}
                     <Route {...to(r.account.personalInformation)}
                            render={_ => (<Layout><PersonalInformation /></Layout>)} />
                     <Route {...to(r.account.contactAndAddress)}
                            component={() => (<Layout><ContactAndAddress /></Layout>)} />
                     <Route {...to(r.account.phoneNumber)}
                            component={() => (<Layout><PhoneNumber /></Layout>)} />
                     <Route {...to(r.account.smsVerification)}
                            component={() => (<Layout><SmsVerification /></Layout>)} />
                     <ApmRoute {...to(r.account.check2fa)}
                            component={Check2FA} />
                     <ApmRoute {...to(r.account.validateEmail)}
                            component={Email} />,
                     <Route path={[r.account.selectPlatform, r.account.corporate.selectPlatform]}
                            key={r.account.selectPlatform}
                            component={() => (<Layout><SelectPlatform /></Layout>)} />
                     <Route {...to(r.account.financialDetails)}
                            render={props => (<Layout><FinancialDetails {...props} /></Layout>)} />
                     <Route {...to(r.account.financialDetailsSecondPage)}
                            render={props => (<Layout><FinancialDetailsSecondPage {...props} /></Layout>)} />
                     <Route {...to(r.account.clientJourney)}
                            component={() => (<Layout><ClientJourneySteps /></Layout>)} />
                     <Route {...to(r.account.accountType)}
                            render={props => (<Layout><AccountTypeSelectionJPN /></Layout>)} />
                     <Route {...to(r.account.experience)}
                            render={props => (<Layout><Experience /></Layout>)} />
                     <Route {...to(r.account.pammQuestionnaire)}
                            component={() => (<Layout><PAMMQuestionnaire /></Layout>)} />
                     <Route {...to(r.account.consent)}
                            component={() => (<Layout><Consent /></Layout>)} />
                     <Route exact {...to(r.account.appropriatenessTest)}
                            component={() => (<Layout><AppropriatenessTest /></Layout>)} />
                     <Route exact {...to(r.account.appropriatenessTestAdditional)}
                            component={() => (<Layout><IndividualAdditionalTestTMAU /></Layout>)} />
                     <Route path={[r.account.chessSponsorship,
                     r.account.corporate.chessSponsorship]}
                            key={r.account.chessSponsorship}
                            component={() => (<Layout><ChessSponsorship /></Layout>)} />
                     <Route {...to(r.account.usaCitizenship)}
                            render={props => (<Layout><UsaCitizenship {...props} /></Layout>)} />
                     <Route {...to(r.account.foreignExchangeBusiness)}
                            render={props => (<Layout><ForeignExchangeBusiness {...props} /></Layout>)} />
                     <Route {...to(r.account.pepLegalConsent)}
                            render={props => (<Layout><PepLegalConsent {...props} /></Layout>)} />
                     <Route {...to(r.account.forexConfirmation)}
                            component={() => (<Layout><ForexConfirmation /></Layout>)} />
                     <Route {...to(r.account.traderClassification)}
                            component={() => (<Layout><TraderClassification /></Layout>)} />
                     <Route {...to(r.account.individualFailed)}
                            component={() => (<Layout>{failed}</Layout>)} />
                     <Route {...to(r.account.individualRefer)}
                            component={() => (<Layout>{refer}</Layout>)} />
                     <Route {...to(r.account.verify.root)}
                            component={() => (<Layout><UploadDocs /></Layout>)} />
                     <Route {...to(r.account.deposit)}
                            render={props => <Deposit {...props} />} />,
                     <Route {...to(r.account.congrats)}
                            component={() => (<Layout><Congrats /></Layout>)} />
                     <Route {...to(r.account.simplified.accountCreated)}
                            component={() => (<Layout><AccountCreatedLevelOne /></Layout>)} />
                     <Route {...to(r.account.simplified.address)}
                            component={() => (<Layout><SimplifiedContactAndAddress /></Layout>)} />
                     <Route {...to(r.account.simplified.pendingApproval)}
                            component={() => (<Layout><SimplifiedAccountPendingApproval /></Layout>)} />
                     <Route {...to(r.account.ib.q1)}
                            component={() => (<Layout><IBQ1 /></Layout>)} />
                     <Route {...to(r.account.ib.q2)}
                            component={() => (<Layout><IBQ2 /></Layout>)} />
                     <Route {...to(r.account.ib.legal)}
                            component={() => (<Layout><IBLegal /></Layout>)} />
                     <Route {...to(r.account.ib.address)}
                            component={() => (<Layout><IBAddress /></Layout>)} />
                     <Route {...to(r.account.ib.accountCreated)}
                            component={() => (<Layout><IBAccountCreated /></Layout>)} />
                     <Route {...to(r.account.ib.pendingApproval)}
                            component={() => (<Layout><IBPendingApproval /></Layout>)} />
                     <Route path={[r.account.ib.verify.address, r.account.ib.verify.id]}
                            component={() => (<Layout><IBUploadDocs /></Layout>)} />
                     <Redirect to={redirectUrl()} />
              </Switch >
       }, [failed, redirectUrl, refer])

}

export default memo(Account)