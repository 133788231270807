import i18n from 'i18next'
import Backend from 'i18next-chained-backend'
import LocalStorageBackend from 'i18next-localstorage-backend'
import XHR from 'i18next-xhr-backend'
import { initReactI18next } from 'react-i18next'
import version from '../VERSION.json'

const build = `v${version.version}.${version.build}`
const backendOptions: Array<Object> = [{
    loadPath: '/locales/{{lng}}/{{ns}}.json',
    queryStringParams: { v: build }
}]

if (process.env.NODE_ENV === 'production')
    backendOptions.unshift({
        defaultVersion: build,
    })

const backend = {
    backends: process.env.NODE_ENV === 'production'
        ? [LocalStorageBackend, XHR] : [XHR],
    backendOptions
}

i18n
    .use(Backend)
    .use(initReactI18next)
    .init({
        backend,
        fallbackLng: 'en',
        fallbackNS: 'common',
        appendNamespaceToMissingKey: false,
        returnEmptyString: false,
        interpolation: {
            escapeValue: false, // not needed for react as it escapes by default
        },
        ns: ['account',
            'footer',
            'faq',
            'common',
            'translation',
            'notifications',
            'questions',
            'accounts',
            'processors',
            'banks',
            'banners',
            'partnerships',
            'trader_classification',
            'trading_central',
            'signal_centre',
            'demo_contest',
            'marketing',
            'platforms',
            'promotions',
            'ib',
            'confirmations']
    })

export default i18n