import { useTranslation } from 'react-i18next'

import { useDangerousHTML } from '../../utils/hooks'

const prefix = 'footer:individual.UAE'

export const IndividualUAE = () => {
    const { t } = useTranslation()
    const { p } = useDangerousHTML()

    return (
        <>
            {p(t(`${prefix}.riskWarningRetailTraders`))}
            {p(t(`${prefix}.riskWarningProfTraders`))}
            {p(t(`${prefix}.company`))}
            {p(t(`${prefix}.regulation`))}
            {p(t(`${prefix}.text`))}
        </>
    )
}
