import React, { memo, useEffect, useState, FunctionComponent, useCallback } from 'react'
import { StringFunc } from '../../../utils/types'
import InternalCheckbox, { CheckBoxAlign } from '../InternalCheckbox'
import Wrapper from '../ItemWrapper'

export type CheckboxProps = {
    name: string
    wrapperClassName?: string
    className?: string
    text: string | FunctionComponent | JSX.Element
    value?: boolean
    showError?: boolean
    disabled?: boolean
    checkBoxAlign?: CheckBoxAlign
    validation?: boolean | string
    validate?: boolean
    optional?: boolean
    validationError?: string | StringFunc
    onChange: (name: string, value: boolean) => void
    errorClassName?: string
}

const Checkbox = React.forwardRef<HTMLDivElement, CheckboxProps>(
    (props, ref) => {
        const [error, seterror] = useState<string>('')
        const { value, optional = true, checkBoxAlign = 'top' } = props

        const handleChange = () => {
            seterror('')
            props.onChange(props.name, !props.value)
        }

        const validationError = useCallback(() => {
            if (!optional && !value && props.validationError) {
                if (typeof props.validationError === 'function') return props.validationError()
                else return props.validationError
            }
            return ''
        }, [optional, props, value])

        useEffect(() => {
            if (!optional) {
                const result = validationError()
                if (props.validate)
                    seterror(result)
            }
            // eslint-disable-next-line        
        }, [props])

        const inputError = props.validation ? props.validation : error
        return (
            <Wrapper error={inputError}
                name={props.name}
                showError={props.showError}
                className={props.wrapperClassName}
                ref={ref}
                errorClassName={props.errorClassName}>
                <InternalCheckbox
                    value={props.value}
                    validation={inputError}
                    text={props.text}
                    checkBoxAlign={checkBoxAlign}
                    disabled={props.disabled}
                    onChange={handleChange} />
            </Wrapper>
        )
    })

export default memo(Checkbox)