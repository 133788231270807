import { FC, useCallback, useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'
import cn from 'classnames'
import { useHistory } from 'react-router'
import { useDispatch } from 'react-redux'

import Modal from '../Presentational/Modal'
import { useAppState } from '../../utils/hooks'
import { logout } from '../../redux/actions'
import Routes from '../../utils/routes'
import { setIsDemoExpired } from '../../redux/slices/app'

import styles from "./style.module.scss"

const ls = "dashboard.subscription.demoExpiration."

const DemoExpirationWarning: FC = () => {
    const [isOpened, setIsOpened] = useState<boolean>(false)
    const { t } = useTranslation()
    const dispatch = useDispatch()
    const history = useHistory()
    const { isDemoExpired } = useAppState()

    useEffect(() => {
        if (isDemoExpired) {
            setIsOpened(true)
        }
    }, [isDemoExpired])

    const handleLogoutClick = useCallback(() => {
        setIsOpened(false)
        dispatch(logout())
        history.push(Routes.account.login)
    }, [dispatch, history])

    const handleOpenLiveClick = useCallback(async () => {
        dispatch(setIsDemoExpired(false))
        setIsOpened(false)
        history.push(Routes.account.createAccount.live)
    }, [dispatch, history])

    return isOpened ? (
        <Modal
            isOpen={isOpened}
            shouldCloseOnEsc={false}
            shouldCloseOnOverlayClick={false}
            title={t(`${ls}modalTitle`)}
        >
            <div className={cn(styles.content)}>
                <div className={cn(styles.consent)} dangerouslySetInnerHTML={{ __html: t(`${ls}content`) }} />
                <div className={cn(styles.buttons)}>
                    <button
                        className={cn('link')}
                        onClick={handleLogoutClick}
                    >
                        {t(`${ls}logOut`)}
                    </button>
                    <button
                        className={cn('green')}
                        onClick={handleOpenLiveClick}
                    >
                        {t(`${ls}openLiveAccount`)}
                    </button>
                </div>
            </div>
        </Modal>
    ) : null
}

export default DemoExpirationWarning


