import cn from "classnames"

import LastLogin from '../../../Header/subHeaders/LastLogin'

import SideBarMenuItem from '../MenuItem'

import { MenuItem } from "../utils"
import styles from "./wtr-sidebar.module.scss"

export interface WtrSideBarProps<T> {
    languagePrefix: string
    items: MenuItem<T>[]
    className?: string
    handleItemClick: (item: MenuItem<T>) => void
}

const WtrSideBar = <T extends string>(props: WtrSideBarProps<T>) => {
    const { languagePrefix, handleItemClick, items, className } = props

    const handleMenuItemClick = (item: MenuItem<T>) => {
        handleItemClick(item)
    }

    return (
        <div className={styles.sidebar}>
            <div className={cn(styles.scroll, className)}>
                <div>
                    {items.map(menuItem => {
                        return (
                            <SideBarMenuItem
                                key={menuItem.name}
                                item={menuItem}
                                onMenuItemClick={() => handleMenuItemClick(menuItem)}
                                parent=""
                                languagePrefix={languagePrefix}
                            />
                        )
                    })}
                </div>
            </div>
            <LastLogin />
        </div>
    )
}

export default WtrSideBar
