import cn from "classnames"
import React, { PropsWithChildren, useLayoutEffect, useRef } from 'react'
import styles from './style.module.scss'


export type WrapperProps = {
    className?: string
    name: string
    tip?: React.ReactNode
    mainAreaClassName?: string
    captionClassName?: string
    errorClassName?: string
    showError?: boolean
    caption?: React.ReactNode
    error?: string | boolean | React.ReactElement
    insideCaption?: boolean
}

const Wrapper = React.forwardRef<HTMLDivElement, PropsWithChildren<WrapperProps>>(
    (props, ref) => {
        Wrapper.displayName = 'Wrapper'
        const mainRef = useRef<HTMLDivElement>(null)
        const captionRef = useRef<HTMLDivElement>(null)

        useLayoutEffect(() => {
            const shift = 20
            if (props.insideCaption && captionRef.current && mainRef.current) {
                if (captionRef.current.clientWidth > mainRef.current.clientWidth - shift)
                    captionRef.current.style.maxWidth = `${mainRef.current.clientWidth - shift}px`
            }
            // eslint-disable-next-line react-hooks/exhaustive-deps
        }, [])

        const { showError = true } = props

        const wrapperClasses = cn(
            styles.wrapper,
            props.className
        )

        const captionClasses = cn(
            styles.caption,
            props.captionClassName,
            { [styles.outside]: !props.insideCaption }
        )

        const mainAreaClasses = cn(
            styles.mainArea,
            props.mainAreaClassName
        )

        return (
            <div ref={ref} className={wrapperClasses} id={`${props.name}-wrapper`}>
                {props.caption && !props.insideCaption && <div className={captionClasses}>
                    {props.caption}
                </div>}
                <div ref={mainRef} className={mainAreaClasses}>
                    {props.caption && props.insideCaption && <span
                        ref={captionRef}
                        className={captionClasses}>
                        {props.caption}
                    </span>}
                    {props.children}
                </div>
                {props.tip && <div className={styles.tip}>{props.tip}</div>}
                {showError && <div className={cn('error-under-control', props.errorClassName)}>
                    <div className={styles.errorText}>
                        {props.error ? props.error : ' '}
                    </div>
                </div>}
            </div>
        )
    })

Wrapper.defaultProps = {
    insideCaption: true
}

export default Wrapper