import cn from 'classnames'
import React, { ChangeEvent, MouseEvent, useCallback, useEffect, useRef, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { shallowEqual, useDispatch, useSelector } from 'react-redux'
import { RouteComponentProps } from "react-router-dom"
import { appInit } from '../../../../config'
import { login } from "../../../../redux/actions"
import { appError, setApplicationToCreate, setAutoLogout } from "../../../../redux/slices/app"
import { Store } from "../../../../redux/store"
import qrCode from '../../../../resources/images/ThinkPortal_app.png'
import { NON_WTR_COUNTRIES } from '../../../../utils/constants/NON_WTR_COUNTRIES'
import { RequestState, __STORAGE } from "../../../../utils/enums"
import { useAppState, useDangerousHTML, useEmailValidation, useEmbeddedLink, useScreen } from '../../../../utils/hooks'
import appRoutes from '../../../../utils/routes'
import Utils from "../../../../utils/utils"
import Loader, { LoaderMode } from "../../../Loader"
import { useCaptcha } from '../../../Presentational/Captcha'
import Checkbox from '../../../Presentational/Checkbox'
import Input from '../../../Presentational/Input'
import { usePortalAppMobileButton } from '../../../Presentational/PortalApp'
import SVG from '../../../Presentational/SVG'
import BaseView from '../Base'
import Footer from './footer'
import styles from './login.module.scss'

type FormData = {
    email: string
    password: string
    keepSigned: boolean
}

type FormDataKeys = keyof FormData

type FormDataIndexed = FormData & {
    [key: string]: any
}

type Flags = { [K in keyof FormDataIndexed]?: any }

interface LoginProps extends RouteComponentProps {

}

const Login = ({ history }: LoginProps) => {
    const [values, setValues] = useState<FormDataIndexed>({
        email: '',
        password: '',
        keepSigned: Utils.getStorageBoolean('keepLogged')
    })
    const [validation, setValidation] = useState<Flags>({})
    const [inProgress, setInProgress] = useState(false)
    const { captcha, reset: resetCaptcha, executeCaptcha } = useCaptcha()
    const invalidCode = useRef(false)
    const willUnmount = useRef(false)
    const { userCountry, language } = useAppState()
    const { isMobile } = useScreen()
    const mobileAppButton = usePortalAppMobileButton()
    const noWTR = NON_WTR_COUNTRIES.includes(userCountry?.code3 || '')
    const loginRequest = useSelector(
        (state: Store) => state.Api.Login,
        shallowEqual
    )

    const dispatch = useDispatch()

    const { t } = useTranslation(['account', 'common', 'translation'])

    const state = useSelector(
        (state: Store) => state.App,
        shallowEqual
    )
    const { loggedIn, userEmailPreverified, autoLogout, error: requestError, redirectedFromWTR } = state

    const validateEmail = useEmailValidation(t)

    const validateField = useCallback(async (name: FormDataKeys, value: any) => {
        switch (name) {
            case 'email':
                return validateEmail(value)
            case 'password':
                return value ? '' : t('common:password.errors.notDefined')
            default:
                return ''
        }
    }, [t, validateEmail])

    const validate = useCallback(async () => {
        const email = await validateField('email', values.email)
        const password = await validateField('password', values.password)
        return email === '' && password === ''
    }, [validateField, values.email, values.password])

    const { d } = useDangerousHTML()

    useEffect(() => {
        if (requestError)
            setInProgress(false)
    }, [requestError])

    useEffect(() => {
        switch (loginRequest.state) {
            case RequestState.None:
            case RequestState.InProgress:
                break
            case RequestState.Success:
                // setInProgress(false)
                break
            case RequestState.Error:
                setInProgress(false)
                if (loginRequest.error === 'NOT_AUTHORIZED') {
                    let error
                    // const response: APIResponse<LoginResponse> = loginRequest.response
                    // const remainingAttempts = response.payload[0].result.remainingAttempts
                    // if (remainingAttempts === undefined) {
                    error = t('translation:NOT_AUTHORIZED_INLINE')
                    // }
                    // else {
                    //     if (remainingAttempts > 0)
                    //         error = d(`${t('translation:NOT_AUTHORIZED_INLINE')} <strong>${t('translation:RemainingAttempts', { count: remainingAttempts })}</strong>`)
                    //     else
                    //         error = d(`${t('translation:NOT_AUTHORIZED_INLINE')} <strong>${t('translation:accountLocked')}</strong>`)
                    // }
                    setValidation({
                        password: error,
                        email: '  ',
                    })
                } else {
                    dispatch(appError(Utils.statusError(loginRequest.error || '')))
                }
        }
    }, [d, dispatch, loginRequest, t])

    const clearValidation = (name: string) => {
        setValidation({ ...validation, [name]: '' })
        if (name === 'code') invalidCode.current = false
    }

    const onChangeValue = (e: ChangeEvent<HTMLInputElement>) => {
        const { name, value } = e.target
        setValues({ ...values, [name]: value })
        clearValidation(name)
    }

    const onBlur = async (name: string, value: any) => {
        if (!willUnmount.current) {
            const validationResult = await validateField(name as FormDataKeys, value)

            if (!willUnmount.current)
                setValidation({
                    ...validation,
                    [name]: validationResult
                })
        }
    }

    useEffect(() => {
        const checkWTR = async () => {
            await appInit
            if (redirectedFromWTR) {
                Utils.postMessageToWTR({ message: 'restart' })
            }
        }
        checkWTR()
    }, [redirectedFromWTR])

    const handleLogin = async (e: MouseEvent) => {
        e.preventDefault()
        dispatch(setAutoLogout(false))

        const valid = await validate()
        if (!values.email)
            setValidation({
                ...validation,
                email: t('common:email.errors.notDefined')
            })

        if (!values.password)
            setValidation({
                ...validation,
                password: t('common:password.errors.notDefined')
            })

        if (!values.email && !values.password)
            setValidation({
                email: t('common:email.errors.notDefined'),
                password: t('common:password.errors.notDefined')
            })

        if (!valid) return
        setInProgress(true)

        const token = await executeCaptcha()

        dispatch(login({
            email: values.email,
            password: values.password,
            keepLogged: values.keepSigned,
            recaptchaResponse: token,
        }))
        resetCaptcha()
    }

    const handleRedirectToDashboard = (e: MouseEvent) => {
        e.preventDefault()
        history.push(appRoutes.dashboard.accounts.root)
    }

    const createAccount = () => {
        // clear referrer value to indicate that flow is starting from the portal, not wtr
        sessionStorage.removeItem('wtrApp')
        dispatch(setApplicationToCreate(undefined))
        localStorage.removeItem(__STORAGE.creatingIBApplication)
        willUnmount.current = true
        history.push(`${appRoutes.account.createAccount.live}`)
    }

    const resetPassword = () => {
        willUnmount.current = true
        history.push(appRoutes.account.resetPasswordStep1)
    }

    /*     const error = useMemo(() => {
            return <div className={styles.maintenance}>{t('maintenance')}</div>
        }, [t])
     */

    const disabled = inProgress || loggedIn
    const linkStyle = cn(styles.small, { [styles.disabled]: disabled })

    const createLink = useEmbeddedLink({
        text: t('login.createLink'),
        onClick: createAccount,
        className: linkStyle
    })

    const chatLink = useEmbeddedLink({
        text: t('login.chat'),
        onClick: () => window.dispatchEvent(new Event("toggleLiveChat")),
        className: linkStyle
    })

    const ad = <div className={styles.ad}>
        <div className={styles.text}>
            <SVG name='portalApp' />
            {t('login.appPromotion.text')}
        </div>
        {isMobile
            ? mobileAppButton
            : <div className={styles.bigQR}>
                <img src={qrCode} alt="QR Code" />
            </div>}
    </div>


    const form = <>
        <form className={styles['login-form']} autoComplete="off">
            {inProgress && <Loader mode={LoaderMode.CoverParent} />}
            {userEmailPreverified && <div className={styles['email-confirmed']}>
                <SVG name='circleCheck' />
                {t('login.verified')}
            </div>
            }

            <h3>{t('login.captionv4')}</h3>

            {autoLogout && <div className={styles.autologout}>
                {t("autoLogoutMessage", { interval: state.inactivityTimeout })}
            </div>}

            {/* {initError && error} */}

            <Input
                name="email"
                type="email"
                // wrapperClassName={styles.wrapper}
                value={values.email}
                disabled={inProgress}
                placeholder={t('account:emailPlaceholder')}
                caption={t('common:email.caption')}
                validation={validation.email}
                isFloatedLabel={true}
                autoComplete="off"
                onChange={onChangeValue}
                onBlur={onBlur}
            />

            <div className={styles.forgottenPassword} onClick={resetPassword}>
                {t('login.forgottenPassword')}
            </div>

            <Input
                name="password"
                value={values.password}
                disabled={inProgress}
                // wrapperClassName={styles.wrapper}
                errorClassName={styles['pass-error']}
                placeholder={t('account:passwordPlaceholder')}
                caption={t('common:password.caption')}
                validation={validation.password}
                type='password'
                autoComplete="off"
                validateSecure={false}
                onChange={onChangeValue}
                onBlur={onBlur}
                showPasswordEye
            />

            <Checkbox
                name="keepSigned"
                text={t('login.keepSignedIn')}
                wrapperClassName={styles.wrapper}
                value={values.keepSigned}
                disabled={inProgress}
                optional
                onChange={(name, value) => {
                    setValues({ ...values, [name]: value })
                    clearValidation(name)
                }}
            />
            {!noWTR && chatLink}
            {captcha}

            <div className={cn(styles['bottom-controls'], styles['margin-top'])}>
                <button
                    className={cn("green", styles.loginButton)}
                    type="submit"
                    disabled={disabled}
                    onClick={loggedIn ? handleRedirectToDashboard : handleLogin}
                >
                    {loggedIn ? t('login.alreadyLogged') : t('common:login')}
                </button>
                {createLink}
            </div>
        </form>
    </>

    return (
        <BaseView customFooter={<Footer />}
            secondCard={language === 'ja' ? undefined : ad}>
            {form}
        </BaseView>
    )
}

export default Login
