import { ApplicationStatusResponse, ApplicationStatus } from "../../utils/types"
import Utils from "../../utils/utils"
import { AppState } from "../appState"
import { __STORAGE } from './../../utils/enums'
import * as ApiSlices from "./api"

type OnAction = null | ((state: AppState, action: any) => void)

const getResult = (action: any) => action.payload.result.payload[0].result

const createReducers = ({ slice, start, success, error }:
    { slice: any, start: OnAction, success: OnAction, error: OnAction }) => {
    return {
        [slice.actions.start.type](state: AppState, action: any) {
            if (start) start(state, action)
        },
        [slice.actions.success.type](state: AppState, action: any) {
            if (success) success(state, action)
        },
        [slice.actions.error.type](state: AppState, action: any) {
            if (error) error(state, action)
        }
    }
}

export const loginReducers = createReducers({
    slice: ApiSlices.login,
    start: null,
    success: (state: AppState, action: any) => {
        state.loggedIn = true
        state.loggedOut = false
        if (action.payload.payload) {
            state.userLoginResult = action.payload.payload[0].status
            state.userLoginInfo = action.payload.payload[0].result
            Utils.saveEncryptedObject(__STORAGE.userLoginInfo, action.payload.payload[0].result)
        }
    },
    error: (state: AppState, action: any) => state.loggedIn = false
})
export const logoutReducers = createReducers({
    slice: ApiSlices.logout,
    start: null,
    success: (state: AppState, action: any) => {
        state.loggedIn = false
        state.loggedOut = true
    },
    error: (state: AppState, action: any) => {
        state.loggedIn = false
        state.loggedOut = true
    }
})

export const appStatusReducers = createReducers({
    slice: ApiSlices.getAppStatus,
    start: null,
    success: (state: AppState, action: any) => {
        const statusResponse = getResult(action) as ApplicationStatusResponse
        state.appStatus = statusResponse.application_status as ApplicationStatus
        state.appropriatenessLevel = statusResponse.appropriateness_level
        state.applicationApproval = statusResponse.application_approval
    },
    error: null
})

export const getBankAccounts = createReducers({
    slice: ApiSlices.getBankAccounts,
    start: null,
    success: (state: AppState, action: any) => {
        state.bankAccounts = getResult(action)
    },
    error: null
})


