import React, { FC } from "react"
import cn from 'classnames'
import { useSelector } from "react-redux"
import { useTranslation } from "react-i18next"

import { Store } from "../../../redux/store"
import { CONTACT_PHONES } from "../../../utils/constants/links"
import { PortalAccountDomain } from "../../../utils/enums"

import styles from './style.module.scss'

const languageString = 'common:contacts'

interface ContactUsInfoProps {
    className?: string
    buttonClassName?: string
    showFullVersion?: boolean
}

const ContactUsInfo: FC<ContactUsInfoProps> = ({ className, buttonClassName, showFullVersion }) => {
    const { t } = useTranslation()
    const portalAccountDomain = useSelector((state: Store) => state.App.portalAccountDomain)

    const phonesMap = {
        [PortalAccountDomain.AU]: [t(`${languageString}.AU`), CONTACT_PHONES.AU],
        [PortalAccountDomain.UK]: [t(`${languageString}.UK`), CONTACT_PHONES.UK],
        //hidden according to https://jira.tfxcorp.com/browse/TGD-1239
        // [PortalAccountDomain.TMCY]: [t(`${languageString}.TMCY`), CONTACT_PHONES.TMCY],
        [PortalAccountDomain.TMEU]: ['', ''],
        [PortalAccountDomain.TMJP]: ['', ''],
        [PortalAccountDomain.TFSA]: [t(`${languageString}.TFSA`), CONTACT_PHONES.TFSA],
        [PortalAccountDomain.TMBM]: ['', ''],
        [PortalAccountDomain.TMLC]: ['', ''],
        [PortalAccountDomain.TMSY]: ['', ''],
        // [PortalAccountDomain.TMBM]: [t(`${languageString}.TMBM`), INTERNATIONAL_CONTACT_PHONE],
    }
    const selected = portalAccountDomain === PortalAccountDomain.Unknown ? undefined : (phonesMap as any)[portalAccountDomain]

    return (
        <div className={cn(styles['contact-us-info'], className)}>
            <div className={styles.title}>{t('common:ContactUs')}</div>
            <div className={styles.info}>
                {showFullVersion || !selected ? (
                    Object.values(phonesMap).map((x, index) => (
                        <div key={index}>{`${x[0]} ${x[1]}`}</div>
                    ))
                ) : (
                    <div>{`${selected[0]} ${selected[1]}`}</div>
                )}
                <div className={styles.email}>
                    {t(`${languageString}.emailCaption`)}
                    &nbsp;
                    <a href={`mailto:${t(`${languageString}.email`)}`}>
                        {t(`${languageString}.email`)}
                    </a>
                </div>
            </div>
        </div>
    )
}

export default ContactUsInfo

