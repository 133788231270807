import React from 'react'

const scrollRef = React.createRef<HTMLDivElement>()

const setScrollTop = (scrollTop: number) => {
    if (scrollRef.current) {
        scrollRef.current.scrollTop = scrollTop - 50
    }
}

export { scrollRef, setScrollTop }