export const NON_REFER_FRIEND_COUNTRIES: string[] = [
    // "AL", // Albania
    // "AO", // Angola
    // "AR", // Argentina
    // "BD", // Bangladesh
    //"KH", // Cambodia
    // "CO", // Colombia
    // "DO", // Dominican Republic
    // "EC", // Ecuador
    // "GH", // Ghana
    // "HN", // Honduras
    // "IN", // India
    // "IQ", // Iraq
    // "KZ", // Kazakhstan
    // "XK", // Kosovo
    // "PK", // Pakistan
    // "PY", // Paraguay
    // "PE", // Peru
    // "UY", // Uruguay
    // "UZ", // Uzbekistan
    // "VE", // Venezuela
    "VN", // Vietnam
    // "BO", // Bolivia
    // "GT", // Guatemala
    // "NG", // Nigeria
    // "SR", // Suriname
    // "CL", // Chile
    // "PA", // Panama
    // "SV", // El Salvador
    // "AE",  // United Arab Emirates
    // "MX" //Mexico
]

/* THPRT-5490 Refer-a-friend - Country exclusion adjustment
Cambodia, Bangladesh, Uzbekistan, Kazakhstan, Albania, Kosovo, Iraq, Angola, Ghana, Peru, Venezuela, Argentina,
Dominican Republic, Ecuador, Paraguay, Uruguay, Honduras, Colombia, India, Pakistan, Panama, Bolivia, Guatemala,
Suriname, Nigeria, Chile, El Salvador, UAE, Mexico, and Indonesia.
*/