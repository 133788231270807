import { useMemo } from "react"
import { Language } from "../../../utils/enums";
import { useAppState } from "../../../utils/hooks";

export const useSocialLinks = () => {
    const { language } = useAppState()

    const links = useMemo(() => {
        switch (language) {
            case 'zh-Hans':
            case 'zh-Hant':
            case 'id':
                return {
                    line: 'https://page.line.me/?accountId=thinkmarkets',
                    facebook: 'https://m.me/ThinkMarketsCN',
                    telegram: 'http://t.me/ThinkMarkets_SEA_bot',
                    twitter: 'https://twitter.com/messages/compose?recipient_id=1602552057738989568'
                }
            case 'ja':
                return {
                    line: 'https://page.line.me/?accountId=039wycms',
                    facebook: 'https://m.me/ThinkMarkets',
                    telegram: 'http://t.me/ThinkMarkets_Japan_bot',
                    twitter: 'https://twitter.com/messages/compose?recipient_id=1544602193185058816'
                }
            default:
                return {
                    line: 'https://page.line.me/?accountId=786azhee',
                    facebook: 'https://m.me/ThinkMarkets',
                    telegram: 'http://t.me/ThinkMarkets_Global_bot',
                    twitter: 'https://twitter.com/messages/compose?recipient_id=136399657'
                }
        }
    }, [language])

    return links
}

export const getZendeskKey = (language: Language) => {
    const defaultKey = "f0585f41-3237-4e8e-aaed-96c64eee884c"
    const japanKey = "45bab9a6-ee0c-4db3-85bb-0e71fc8d4024"
    const chineeseKey = "dbdb970c-5b15-4c23-97f1-abf5bb0f197e"

    switch (language) {
        case 'zh-Hans':
            return {
                value: 'zh-cn',
                key: chineeseKey
            }
        case 'zh-Hant':
            return {
                value: 'zh-tw',
                key: chineeseKey
            }
        case 'ja':
            return {
                value: 'ja',
                key: japanKey
            }
        case 'id':
            return {
                value: 'id',
                key: chineeseKey
            }
        default:
            return {
                value: language,
                key: defaultKey
            }
    }
}