import { createSlice, PayloadAction } from '@reduxjs/toolkit'

export type LoaderState = {
    showProgress: boolean
    progressSteps: number
    progressStep: number
}

const initialState: LoaderState = {
    showProgress: false,
    progressSteps: 4,
    progressStep: 1
}

const slice = createSlice({
    name: 'loader',
    initialState: initialState,
    reducers: {
        setShowProgress: (state: LoaderState, action: PayloadAction<boolean>) => {
            state.showProgress = action.payload
        },
        setProgressStep: (state: LoaderState, action: PayloadAction<number>) => {
            state.progressStep = action.payload
        },
        setProgressSteps: (state: LoaderState, action: PayloadAction<number>) => {
            state.progressSteps = action.payload
        },
    }
})

const { actions, reducer } = slice

export const { setShowProgress, setProgressStep, setProgressSteps } = actions
export default reducer