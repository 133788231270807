import { useCallback } from 'react'
import { useDispatch } from 'react-redux'
import { useHistory, useLocation } from 'react-router-dom'
import { setApplicationToCreate, setUserInsidePortal } from '../../redux/slices/app'
import api from '../../utils/api'
import Routes, { dashboardPath, profilePath } from '../../utils/routes'
import { useIBState } from './hooks'
import { setApplication } from '../redux/state'

export const useRedirect = () => {
    const history = useHistory()
    const { application } = useIBState()
    const location = useLocation()
    const dispatch = useDispatch()

    const enterPortal = useCallback(() => {
        const isOutside = location.pathname.indexOf(dashboardPath) === -1 &&
            location.pathname.indexOf(profilePath) === -1

        if (isOutside) {
            history.push(Routes.ib.dashboard)
            dispatch(setUserInsidePortal(true))
        }
    }, [location.pathname, history, dispatch])

    const redirectByStatus = useCallback(() => {
        if (!application) {
            enterPortal()
            return
        }
        dispatch(setApplicationToCreate('partner'))
        switch (application.status.toUpperCase()) {
            case 'INCOMPLETE':
                switch (application.formStep) {
                    case "questionnaire":
                        history.push(Routes.account.ib.legal)
                        break
                    default:
                        history.push(Routes.account.ib.q1)
                }
                break
            default:
                enterPortal()
                break
        }
    }, [application, dispatch, enterPortal, history])

    const continueApplication = useCallback(() => {
        if (!application) return
        dispatch(setApplicationToCreate('partner'))

        switch (application.status.toUpperCase()) {
            case 'LEVEL1_APPROVED':
            case 'PENDING_ID':
            case 'PENDING_ID_ADDRESS':
                api.updateIBApplicationStatus('pendingKYC', application?.applicationId || 0)
                dispatch(setApplication({ ...application, status: 'PENDING_KYC' }))
                history.push(Routes.account.ib.address)
                break
            case 'PENDING_KYC':
                switch (application.formStep) {
                    case "address data":
                        history.push(Routes.account.ib.verify.id)
                        break
                    case "id kyc":
                        history.push(Routes.account.ib.verify.address)
                        break
                    case "address kyc":
                        history.push(Routes.account.ib.pendingApproval)
                        break
                    default:
                        history.push(Routes.account.ib.address)
                }
                break
            default: redirectByStatus() //INCOMPLETE
        }
    }, [application, dispatch, history, redirectByStatus])

    return {
        redirectByStatus,
        continueApplication
    }
}
