import { createSlice, PayloadAction } from "@reduxjs/toolkit"
import { ScreenSize } from '../../utils/enums'
import { IBApplication, Question } from '../../utils/types'
import { AccountTradingType, IBCountry, IBTradingPlatform, InvoiceFile, ManagerContact, NCIItem, PartnerInfo, PaymentMethod, PaymentMethodDetails } from '../utils/types'
import { getIBScreenType } from '../utils/utils'

type Requests = {
    paymentMethod: boolean
    documents: boolean
    recentInvoices: boolean
    nci: boolean
}

type Confirmations = {
    paymentMethod: boolean
}

const requests: Requests = {
    paymentMethod: false,
    documents: false,
    recentInvoices: false,
    nci: false
}

export type IBState = {
    inProgress: boolean
    showNavigation: boolean
    prefferedPaymentMethod: PaymentMethod | null
    paymentMethodWithStatusList: PaymentMethodDetails[] | null
    recentInvoices: InvoiceFile[]
    nciItems: NCIItem[]
    requests: Requests
    tokenUpdateError: boolean
    confirmations: Confirmations
    managerContact: ManagerContact | null
    partnerInfos: PartnerInfo[]
    selectedPartner: PartnerInfo | null
    countries: IBCountry[]
    tradingPlatforms: IBTradingPlatform[]
    accountTradingTypes: AccountTradingType[]
    screen: ScreenSize
    application: IBApplication | null
    questionnaire: Question[]
    answers: Question[]
}

const initialState: IBState = {
    inProgress: false,
    showNavigation: false,
    prefferedPaymentMethod: null,
    paymentMethodWithStatusList: null,
    recentInvoices: [],
    nciItems: [],
    tokenUpdateError: false,
    requests: requests,
    confirmations: {
        paymentMethod: false
    },
    managerContact: null,
    partnerInfos: [],
    selectedPartner: null,
    countries: [],
    tradingPlatforms: [],
    accountTradingTypes: [],
    screen: getIBScreenType(window.innerWidth),
    application: null,
    questionnaire: [],
    answers: [],
}

const slice = createSlice({
    name: "ib",
    initialState: initialState,
    reducers: {
        setInProgress: (state: IBState, action: PayloadAction<boolean>) => {
            state.inProgress = action.payload
        },
        toggleNavigation: (state: IBState) => {
            state.showNavigation = !state.showNavigation
        },
        setPrefferedPaymentMethod: (state: IBState, action: PayloadAction<Partial<PaymentMethod> | null>) => {
            state.prefferedPaymentMethod = action.payload
        },
        setPaymentMethods: (state: IBState, action: PayloadAction<PaymentMethodDetails[] | null>) => {
            state.paymentMethodWithStatusList = action.payload
        },
        setRecentInvoices: (state: IBState, action: PayloadAction<InvoiceFile[]>) => {
            state.recentInvoices = action.payload
        },
        setApplication: (state: IBState, action: PayloadAction<IBApplication | null>) => {
            state.application = action.payload
        },
        setManagerContact: (state: IBState, action: PayloadAction<ManagerContact>) => {
            state.managerContact = action.payload
        },
        setNCIItems: (state: IBState, action: PayloadAction<NCIItem[]>) => {
            state.nciItems = action.payload
        },
        setRequestCompleted: (state: IBState, action: PayloadAction<{ name: keyof Requests, value: boolean }>) => {
            state.requests = { ...state.requests, [action.payload.name]: action.payload.value }
        },
        setConfirmation: (state: IBState, action: PayloadAction<{ name: keyof Confirmations, value: boolean }>) => {
            state.confirmations = { ...state.confirmations, [action.payload.name]: action.payload.value }
        },
        setPartnerInfos: (state: IBState, action: PayloadAction<PartnerInfo[]>) => {
            state.partnerInfos = action.payload
        },
        setSelectedPartner: (state: IBState, action: PayloadAction<PartnerInfo | null>) => {
            state.selectedPartner = action.payload
        },
        setCountries: (state: IBState, action: PayloadAction<IBCountry[]>) => {
            state.countries = action.payload
        },
        setTradingPlatforms: (state: IBState, action: PayloadAction<IBTradingPlatform[]>) => {
            state.tradingPlatforms = action.payload
        },
        setAccountTradingTypes: (state: IBState, action: PayloadAction<AccountTradingType[]>) => {
            state.accountTradingTypes = action.payload
        },
        setQuestionnaire: (state: IBState, action: PayloadAction<Question[]>) => {
            state.questionnaire = action.payload
        },
        setAnswers: (state: IBState, action: PayloadAction<Question[]>) => {
            state.answers = action.payload
        },
        changeIBScreen: (state: IBState, action: PayloadAction<ScreenSize>) => {
            state.screen = action.payload
        },
        cleanupIBState: (state: IBState) => {
            return initialState
        }
    }
})

const { actions, reducer } = slice

export const {
    toggleNavigation,
    setInProgress,
    setPrefferedPaymentMethod,
    setPaymentMethods,
    setRecentInvoices,
    setNCIItems,
    setRequestCompleted,
    setConfirmation,
    setManagerContact,
    setPartnerInfos,
    setSelectedPartner,
    setCountries,
    setTradingPlatforms,
    setAccountTradingTypes,
    changeIBScreen,
    setApplication,
    setAnswers,
    setQuestionnaire,
    cleanupIBState
} = actions
export default reducer
