import { ScreenSize } from "../../utils/enums";
import { IB_SCREEN_BREAKPOINTS } from "./constants";
import { CashbackType } from "./types";

export const formatCashback = (value?: string | number, type?: CashbackType) => {
    switch (type) {
        case 'VALUE':
            return `${value || ' - '} USD`
        case 'PERCENTAGE':
            return `${value || ' - '}%`
        default:
            return ' - '
    }
}

export const getCashbackSign = (type?: CashbackType) => {
    switch (type) {
        case 'VALUE':
            return 'USD'
        case 'PERCENTAGE':
            return '%'
        default:
            return ''
    }
}

export const getIBScreenType = (width: number): ScreenSize => {
    switch (true) {
        case width < IB_SCREEN_BREAKPOINTS.MOBILE:
            return ScreenSize.Mobile
        case width > IB_SCREEN_BREAKPOINTS.DESKTOP:
            return ScreenSize.Desktop
        default:
            return ScreenSize.Tablet
    }
}