import cn from 'classnames'
import React, { useMemo, VFC } from 'react'
import { useTranslation } from 'react-i18next'
import { PortalAccountDomain } from '../../utils/enums'
import { useAppState } from '../../utils/hooks'
import Utils from '../../utils/utils'
import version from '../../VERSION.json'
import { Individual } from './Individual'
import { IndividualAU } from './IndividualAU'
import { IndividualNZ } from './IndividualNZ'
import { IndividualTFSA } from './IndividualTFSA'
import { IndividualTMBM } from './IndividualTMBM'
import { IndividualTMCY } from './IndividualTMCY'
import { IndividualTMEU } from './IndividualTMEU'
import { IndividualTMJP } from './IndividualTMJP'
import { IndividualTMSY } from './IndividualTMSY'
import { IndividualUAE } from './IndividualUAE'
import { IndividualUK } from './IndividualUK'
import styles from './style.module.scss'

const Copyright = () => {
    const { t } = useTranslation()
    return <div className={styles.copyright}>
        <p> &copy;{` ${new Date().getFullYear()} `}{t(`footer:copyright`)}</p>
    </div>

}

const Version = () => {
    return <><div className={styles.version}>
        <p>v {version.version}.{version.build}</p>
    </div>
        {version.comment && <div className={styles.comment}>
            <p>{version.comment}</p>
        </div>}
    </>
}
interface FooterProps {
    isExtended?: boolean
    customFooter?: JSX.Element
    stickWarning?: boolean
}

const Footer: VFC<FooterProps> = ({ isExtended, customFooter, stickWarning = false }) => {

    const { portalAccountDomain, userCountry, onboardingDomain } = useAppState()
    const domain = onboardingDomain || portalAccountDomain
    const text = useMemo(() => {
        switch (domain) {
            case PortalAccountDomain.AU:
                return <IndividualAU userCountry={userCountry} />
            case PortalAccountDomain.TMNZ:
                return <IndividualNZ />
            case PortalAccountDomain.TFSA:
                return <IndividualTFSA userCountry={userCountry} />
            case PortalAccountDomain.TMBM:
            case PortalAccountDomain.TMLC:
                return <IndividualTMBM />
            case PortalAccountDomain.TMSY:
                return <IndividualTMSY />
            case PortalAccountDomain.UK:
                return Utils.checkIfCountryIsUAE(userCountry) ? <IndividualUAE /> : <IndividualUK />
            case PortalAccountDomain.TMCY:
                return <IndividualTMCY stickWarning={stickWarning} />
            case PortalAccountDomain.TMEU:
                return <IndividualTMEU stickWarning={stickWarning} />
            case PortalAccountDomain.TMJP:
                return <IndividualTMJP />
            default: return <Individual />
        }
    }, [domain, stickWarning, userCountry])

    return <div className={cn(styles.footer)}>
        <div className={cn(styles.inner, { [styles.extended]: isExtended })}>
            {customFooter ? customFooter : (
                <>
                    {text}
                    <Copyright />
                    <Version />
                </>
            )}
        </div>
    </div>
}

export default Footer
export { Copyright, Version }

