import { datadogRum } from '@datadog/browser-rum'
import versionFile from './VERSION.json'
import { appConfig } from './config'

export const initializeDataDogRum = () => {
    const datadogEnv = appConfig.ENV === 'ld' || appConfig.ENV === 'hk' ? 'production' : appConfig.ENV
    if (datadogEnv === 'dev') return
    datadogRum.init({
        applicationId: "16b1c76f-73d4-4108-b879-ee7180cd36b2",
        clientToken: "pub0347c77cff29ed67a712ab92667253b8",
        site: "datadoghq.com",
        service: `portal-${datadogEnv}`,
        env: datadogEnv,
        version: versionFile.version,
        sampleRate: 100,
        trackInteractions: true,
        trackFrustrations: true,
        defaultPrivacyLevel: 'mask-user-input',
        silentMultipleInit: true,
        beforeSend: (event) => {
            if (event.type === 'error') {
                if (event.error.source === 'report') return false
                if (event.error.source.indexOf('zalo') !== -1) return false
            }
        },
    })

    datadogRum.addRumGlobalContext('viewPort', {
        width: Math.max(document.documentElement.clientWidth || 0, window.innerWidth || 0),
        height: Math.max(document.documentElement.clientHeight || 0, window.innerHeight || 0)
    })

    datadogRum.startSessionReplayRecording()
}

export const setUserForDatadog = (email: string) => {
    if (appConfig.ENV === 'dev') return
    datadogRum.addRumGlobalContext('email', email)
    datadogRum.setRumGlobalContext({ email: email })
    const context = datadogRum.getRumGlobalContext()
    datadogRum.setUser({
        email: context.email?.toString()
    })
}
