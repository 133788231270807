import { useCallback } from 'react'
import { useHistory } from 'react-router-dom'

import appRoutes from "../utils/routes"
import { useAppState } from './hooks'

export const usePhoneRedirect = () => {
    const history = useHistory()
    const { userProfile, appStatus } = useAppState()

    const redirect = useCallback(() => {
        if (appStatus !== 'INCOMPLETE') return true

        if (userProfile?.country?.isSimplifyOnboarding) {
            if (
                userProfile?.country?.forcePhoneNumberValidate
                && userProfile.phone?.mobile && userProfile.phone?.mobileCountryCode
                && !userProfile.phoneVerified
            ) {
                history.push(appRoutes.account.smsVerification)
                return false
            }
        } else {
            if (userProfile?.country?.forcePhoneNumberValidate) {
                if (!userProfile.phone?.mobile || !userProfile.phone?.mobileCountryCode) {
                    history.push(appRoutes.account.phoneNumber)
                    return false
                }

                if (!userProfile.phoneVerified) {
                    history.push(appRoutes.account.smsVerification)
                    return false
                }
            }
        }

        return true
    }, [history, userProfile, appStatus])

    return redirect
}