import React from 'react'
import { useTranslation } from 'react-i18next'
import { useDangerousHTML } from '../../utils/hooks'
export const Individual = () => {
    const { t } = useTranslation()
    const { p } = useDangerousHTML()

    const prefix = 'footer:individual.TMSY'
    return <>
        <p>
            <strong>{t(`${prefix}.warning.header`)} </strong>
            {t(`${prefix}.warning.text`)}
        </p>
        <p>
            <strong>{t(`${prefix}.0.header`)} </strong>
            {t(`${prefix}.0.text1`)}
        </p>
        <p>{t(`${prefix}.0.text2`)}</p>
        <p>{t(`${prefix}.0.text3`)}</p>
        <p>{t(`${prefix}.0.text4`)}</p>
        <p>
            <strong>{t(`${prefix}.1.header`)}&nbsp;</strong>
            {t(`${prefix}.1.text`)}
        </p>
        {p(t(`footer:individual.TMEU.cyprus`))}
        <p>
            <strong>{t(`${prefix}.2.header`)}&nbsp;</strong>
            {t(`${prefix}.2.text`)}
        </p>
        <p>
            <strong>{t(`${prefix}.3.header`)}&nbsp;</strong>
            {t(`${prefix}.3.text`)}
        </p>
        {/* <p>
            <strong>{t(`${prefix}.4.header`)}&nbsp;</strong>
            {t(`${prefix}.4.text`)}
        </p> */}
        <p>
            <strong>{t(`${prefix}.5.header`)}&nbsp;</strong>
            {t(`${prefix}.5.text`)}
        </p>
    </>
}
