import cn from 'classnames'
import { FC, useEffect, useRef, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { useDispatch } from 'react-redux'
import { getAppStatus, getUserApps } from '../../../../../redux/actions'
import api from '../../../../../utils/api'
import { TrustDockResponse } from '../../../../../utils/types'
import Utils from '../../../../../utils/utils'
import Loader from '../../../../Loader'
import SVG from '../../../../Presentational/SVG'
import styles from './style.module.scss'



const openerClassName = 'opener'
const ls = 'account:upload'

interface TrustDockProps {
    applicationID: number
}

const TrustDock: FC<TrustDockProps> = ({ applicationID }) => {
    const dispatch = useDispatch()
    const { t } = useTranslation()
    const [initializeInfo, setInitializeInfo] = useState<TrustDockResponse>()
    const [scriptLoaded, setScriptLoaded] = useState<boolean>(false)
    const [isInitialized, setIsInitialized] = useState<boolean>(false)
    const trustDockInstance = useRef<any>(null)

    useEffect(() => {
        const getData = async (applicationID: number) => {
            const response = await api.getTrustDockVerificationID(applicationID)
            const trustDockResponse = response.payload[0].result
            setInitializeInfo(trustDockResponse)
        }
        if (applicationID) {
            console.log('>> applicationID', applicationID)
            getData(applicationID)
        }
    }, [applicationID])

    useEffect(() => {
        const trustDockLink = Utils.isTestEnv()
            ? 'https://helper.test.trustdock.io/v2/verification_helper.js'
            : 'https://helper.trustdock.io/v2/verification_helper.js'
        Utils.loadScript(trustDockLink, () => {
            setScriptLoaded(true)
        })
    }, [])

    useEffect(() => {
        if (initializeInfo && scriptLoaded && applicationID) {
            trustDockInstance.current = new window.Trustdock({
                publicId: initializeInfo.publicId,
                plans: initializeInfo.planIds.map((x) => ({ id: x })),
                openerSelector: `.${openerClassName}`
            })

            trustDockInstance.current.on('documentsubmitted', (e: any) => {
                console.log('>>> documentsubmitted', e)
            })
            trustDockInstance.current.on('completed', (e: any) => {
                console.log('>>> completed', e)
                dispatch(getAppStatus(applicationID))
                dispatch(getUserApps())
            })
            trustDockInstance.current.on('canceled', (e: any) => {
                console.log('>>> canceled', e)
            })

            setTimeout(() => {
                setIsInitialized(true)
            }, 1000)
        }
    }, [initializeInfo, scriptLoaded, dispatch, applicationID])

    return (
        <div className={styles.trustdock}>
            {!isInitialized && <Loader />}
            <button autoFocus className={cn(styles.upload, openerClassName)}>
                <div className={styles.icon} >
                    <SVG name='fileFolder' />
                </div>
                <div>{t(`${ls}.trustdock.buttonLabel`)}</div>
            </button>
        </div>
    )
}


export default TrustDock