import { useCallback, useMemo } from "react"
import { useTranslation } from "react-i18next"
import { useLocation } from "react-router"

export type MenuItem<T = string> = {
  name: T
  customCaption?: string | JSX.Element
  icon?: {
    active: string
    inactive: string
    disabled: string
  }
  children?: MenuItem<T>[]
  link?: string
  matchLinks?: string[]
  disabled?: boolean
  keepActiveAppearance?: boolean
  shouldDisplay?: () => boolean
}

export const useRetrieveIcon = <T extends string>(item: MenuItem<T>) => {
  const retrieveIcon = useCallback((active?: boolean, disabled?: boolean) => {
    if (!item.icon) return null

    if (active) return item.icon.active
    else if (disabled) return item.icon.disabled
    else return item.icon.inactive
  }, [item.icon])

  return retrieveIcon
}

export const useMenuEntryCaption = <T extends string>(
  languagePrefix: string,
  item: MenuItem<T>,
  hasChildren?: boolean,
  parent?: string
) => {
  const { t } = useTranslation()

  const caption = useMemo(() => {
    if (item.customCaption) return item.customCaption

    if (hasChildren) return t(`${languagePrefix}.${item.name}.caption`)

    const parentPrefix = parent ? `${parent}.` : ''
    return t(`${languagePrefix}.${parentPrefix}${item.name}`)
  }, [item, languagePrefix, t, hasChildren, parent])

  return caption
}

export const useMatchUrlUtils = <T extends string>() => {
  const location = useLocation()

  const checkForAnyChildMatchUrl = useCallback((children: MenuItem<T>[]): boolean => {
    const child = children.find(item => {
      if (!item.link && !item.matchLinks) return false
      else {
        let found = false
        if (item.link)
          found = location.pathname.includes(item.link)
        if (!found && item.matchLinks)
          for (let i = 0; i < item.matchLinks.length; i++) {
            if (found) break
            found = location.pathname.includes(item.matchLinks[i])
          }
        return found
      }
    })
    return !!child
  }, [location.pathname])

  const checkForMatchUrl = useCallback((item: MenuItem<T>) => {
    const matchUrl = (item.link && location.pathname.includes(item.link))
      || (item.matchLinks && item.matchLinks.find(i => location.pathname.includes(i)))
    return !!matchUrl
  }, [location.pathname])

  return { checkForAnyChildMatchUrl, checkForMatchUrl }
}
