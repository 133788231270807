import cn from 'classnames'
import React, { FC, useMemo } from 'react'
import { useTranslation } from 'react-i18next'
import { useSelector } from 'react-redux'
import { Link } from 'react-router-dom'
import { Store } from '../../redux/store'
import mt4 from '../../resources/images/mt4.png'
import ttLogoImg from '../../resources/images/tt-logo.svg'
import { usePageTitle } from '../../utils/hooks'
import routes from '../../utils/routes'
import { Notification } from '../../utils/types'
import { useRedirect } from '../../utils/useRedirect'
import alert2 from './images/application-alert_2.png'
import alert4 from './images/application-alert_4.png'
import appSuccess from './images/application-success.png'
import fundsSuccess from './images/funds-success.png'
import info from './images/info-alert.png'
import fund from './images/money-bill-alt.png'
import userSuccess from './images/user-success.png'
import styles from './style.module.scss'


type NotificationsItem = {
    icon?: any
    link?: string
}

const Mapping: { [key: string]: NotificationsItem } = {
    //Your ThinkPortal account was updated
    "1": {
        icon: userSuccess
    },
    //Your Profile information has been updated
    "2": {
        icon: userSuccess
    },
    //Your withdrawal transaction has been processed
    "3": {
        icon: fundsSuccess
    },
    //Your Live account application is incomplete
    "4": {
        link: 'fake',
        icon: alert2
    },
    //Your live account Application has been successfully submitted
    "5": {
        icon: appSuccess
    },
    //Your new MT4 account has been created
    "6": {
        icon: mt4
    },
    //Your new cTrader account has been created
    "7": {

    },
    //Your new ThinkTrader account has been created
    "8": {
        icon: ttLogoImg
    },
    //User account not funded
    "9": {
        link: routes.dashboard.funds.deposit,
        icon: fund
    },
    //Transfer to UK allowed
    "10": {
        icon: fundsSuccess
    },
    //Transfer to UK disallowed: disabled account with money
    "11": {
        icon: alert2
    },
    //Upload your missing ID document
    "12": {
        link: routes.account.verify.id,
        icon: alert4
    },
    //Upload your missing Address document
    "13": {
        link: routes.account.verify.address,
        icon: alert4
    },
    //Upload your missing verification documents
    "14": {
        link: routes.account.verify.root,
        icon: alert4
    },
    //You are now ready to fund your account
    "15": {
        link: routes.dashboard.funds.deposit,
        icon: fund
    },
    //Free* VPS hosting now available
    "16": {
        link: routes.dashboard.tools.vps,
        icon: info
    },
    //Your deposit is applied to your trading account
    "17": {
        icon: fundsSuccess
    }
}

type ItemProps = {
    item: Notification
    index: number
    onAction?: () => void
}

const Item: FC<ItemProps> = ({ item, onAction, index }) => {
    const { notificationId } = item
    const { t } = useTranslation('notifications')

    const actions = useMemo(() => {
        return t('actions', { returnObjects: true })
    }, [t])

    const messages = useMemo(() => {
        return t('messages', { returnObjects: true })
    }, [t])

    const mapping = useMemo(() => Mapping[notificationId]
        , [notificationId])

    const icon = useMemo(() => {
        if (!mapping) return <div />
        return mapping.icon
            // eslint-disable-next-line jsx-a11y/alt-text
            ? <img className={styles.icon} src={mapping.icon} />
            : <div></div>
    }, [mapping])

    const { redirectToRegisterFormStep } = useRedirect()

    const link = useMemo(() => {
        if (!mapping) return <div />
        if (mapping.link) {
            const text = actions[notificationId]
            if (notificationId === 4)
                return <button className={cn(styles.button, "green")}
                    onClick={() => redirectToRegisterFormStep()}>
                    {text}
                </button>

            return text
                ? (<Link className={styles.link} to={mapping.link} onClick={onAction}>
                    <button className={cn(styles.button, "green")}>{text}</button>
                </Link>)
                : null
        }
        return null
    }, [mapping, actions, notificationId, onAction, redirectToRegisterFormStep])

    const message = useMemo(() => {
        const text = messages[notificationId] ? messages[notificationId] : item.message
        return text
    }, [item.message, messages, notificationId])

    return (
        <div className={styles.item}>
            <div className={styles.content}>
                {icon}
                <div className={styles.text}>{message}</div>
            </div>
            {link}
        </div>
    )
}

const Notifications: FC = () => {
    const store = useSelector((state: Store) => state.App)
    usePageTitle('notifications:notificationsTitle')
    const { notifications } = store

    return <div className={"page-card"}>
        {notifications.map((n, i) =>
            <Item key={i} item={n} index={i} />
        )}
    </div>
}


export default Notifications
export { Item }

