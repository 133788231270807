import React, { FC } from 'react'
import { useTranslation } from 'react-i18next'
import { usePageTitle } from '../../../../../utils/hooks'
import styles from './style.module.scss'

const Card: FC<
    {
        title?: string
    }
> = ({ title, children }) => {
    const { t } = useTranslation()
    usePageTitle(title ? t(title) : null)
    return <div className={styles.card}        >
        {children}
    </div>
}

export default Card