import { FC, useCallback, useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'
import cn from 'classnames'
import { useDispatch } from 'react-redux'

import Modal from '../Presentational/Modal'
import { useAppState } from '../../utils/hooks'
import { setIsSubscriptionTerminated } from '../../redux/slices/app'

import styles from "./style.module.scss"

const ls = "dashboard.subscription.terminatedErrorModal."

const SubscriptionTerminatedError: FC = () => {
    const [isOpened, setIsOpened] = useState<boolean>(false)
    const { t } = useTranslation()
    const dispatch = useDispatch()
    const { isSubscriptionTerminated } = useAppState()

    useEffect(() => {
        if (isSubscriptionTerminated) {
            setIsOpened(true)
        }
    }, [isSubscriptionTerminated])

    const handleOkClick = useCallback(async () => {
        dispatch(setIsSubscriptionTerminated(false))
        setIsOpened(false)
    }, [dispatch])

    return isOpened ? (
        <Modal
            isOpen={isOpened}
            shouldCloseOnEsc={false}
            shouldCloseOnOverlayClick={false}
            title={t(`${ls}modalTitle`)}
        >
            <div className={cn(styles.content)}>
                <div className={cn(styles.consent)} dangerouslySetInnerHTML={{ __html: t(`${ls}content`) }} />
                <button
                    className={cn('green', styles.button)}
                    onClick={handleOkClick}
                >
                    {t(`${ls}ok`)}
                </button>
            </div>
        </Modal>
    ) : null
}

export default SubscriptionTerminatedError
