import cn from 'classnames'
import { FC } from 'react'
import Image from '../Image'
import styles from './style.module.scss'

interface AppLogoProps {
  className?: string
  condensed?: boolean
}

const AppLogo: FC<AppLogoProps> = ({ className, condensed }) => {
  return (
    <Image
      name={condensed ? 'tmCondensedLogo' : 'tmLogo'}
      className={cn(styles['app-logo'], { [styles.condensed]: condensed }, className)}
    />
  )
}


export default AppLogo
