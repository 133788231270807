import { FC, useCallback, useEffect, useMemo, useState } from 'react'
import SumsubWebSdk from '@sumsub/websdk-react'
import { useDispatch } from 'react-redux';
import { datadogRum } from '@datadog/browser-rum'

import api from '../../../../../utils/api'
import Loader, { LoaderMode } from '../../../../Loader'
import { getUserApps, getAppStatus } from '../../../../../redux/actions';
import Utils from '../../../../../utils/utils';
import { UserProfile } from '../../../../../utils/types';
import { useAppState } from '../../../../../utils/hooks';

import styles from './style.module.scss'

interface SumSubProps {
    applicationID: number
    userProfile: UserProfile
    isMobile?: boolean
}

const SumSub: FC<SumSubProps> = ({ applicationID, userProfile, isMobile, children }) => {
    const dispatch = useDispatch()
    const [accessToken, setAccessToken] = useState<string | null>(null)
    const [isInitialized, setIsInitialized] = useState<boolean>(false)
    const { redirectedFromWTR } = useAppState()

    const getAccessToken = useCallback(async () => {
        try {
            const response = await api.getSumSubAccessToken(applicationID)
            if (Utils.validResponse(response)) {
                const token: string = response.payload[0].result
                return token
            } else {
                const err = new Error('Response from getSumSubAccessToken is not OK')
                datadogRum.addError(err, {
                    place: 'sumsub getSumSubAccessToken not OK handler',
                    response: response,
                    applicationID: applicationID,
                    isMobile: isMobile,
                })
                return response.payload[0].result.message || ''
            }
        } catch (error) {
            return 'Unhandled error'
        }
    }, [applicationID, isMobile])

    useEffect(() => {
        const getData = async () => {
            const token = await getAccessToken()
            setIsInitialized(true)
            setAccessToken(token)
        }

        if (applicationID) {
            getData()
        }
    }, [applicationID, getAccessToken])

    const langCode = useMemo(() => {
        switch (userProfile.preferredLanguage.language_code) {
            case 'zh-Hans':
                return 'zh'
            case 'zh-Hant':
                return 'zh-tw'
            default:
                return userProfile.preferredLanguage.language_code
        }
    }, [userProfile])

    const mobileStyles = {
        height: `${window.innerHeight}px`,
        overflow: 'scroll',
    }

    return (
        <div
            className={styles.sumsub}
            style={isMobile ? mobileStyles : undefined}
        >
            {!isInitialized && <Loader mode={LoaderMode.StandAlone} />}
            {accessToken && (
                <>
                    <SumsubWebSdk
                        accessToken={accessToken}
                        expirationHandler={getAccessToken}
                        config={{
                            lang: langCode,
                            country: userProfile?.country.code3,
                            uiConf: {
                                customCss: `${window.origin}/sumsub.css`,
                                scrollIntoView: redirectedFromWTR ? false : true,
                            }
                        }}
                        options={{ addViewportTag: false, adaptIframeHeight: true }}
                        onMessage={(type, payload: any) => {
                            console.log('onMessage', type, payload)
                            if (type === 'idCheck.applicantStatus' || type === 'idCheck.onApplicantSubmitted') {
                                setTimeout(() => {
                                    if (userProfile?.country.isSimplifyOnboarding) {
                                        window.dataLayer?.push({
                                            'event': 'custom_event',
                                            'event_name': 'simplified_level3_verify_identity',
                                        })
                                    }
                                    dispatch(getAppStatus(applicationID))
                                    dispatch(getUserApps())
                                    if (redirectedFromWTR) {
                                        Utils.postMessageToWTR({ message: 'tradePage' })
                                    }
                                }, 500)
                            }
                        }}
                        onError={(error) => {
                            console.log('sumsub onError', error)
                            datadogRum.addError(error, {
                                place: 'sumsub onError callback',
                                applicationID: applicationID,
                                isMobile: isMobile,
                                user: userProfile?.email,
                                country: userProfile?.country.code3,
                            })
                        }}
                    />
                    {children}
                </>
            )}
        </div>
    )
}

export default SumSub
