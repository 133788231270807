import { PropsWithChildren } from 'react'
import ReactModal from 'react-modal'
import cn from 'classnames'
import styles from './style.module.scss'
import SVG from '../SVG'

interface ModalProps {
    onModalClose?(): void
    afterModalOpen?(): void
    isOpen: boolean
    showCross?: boolean
    shouldCloseOnEsc?: boolean
    shouldCloseOnOverlayClick?: boolean
    className?: string
    cardClassName?: string
    headerClassName?: string
    title?: string
}

const Modal = (props: PropsWithChildren<ModalProps>) => {
    const { onModalClose, afterModalOpen, children, isOpen, title, showCross, className, headerClassName, cardClassName, shouldCloseOnEsc = true, shouldCloseOnOverlayClick = true } = props
    return (
        <div className='modal-background' onClick={onModalClose}>
            <ReactModal
                isOpen={isOpen}
                onRequestClose={onModalClose}
                onAfterOpen={afterModalOpen}
                className={styles.modal}
                overlayClassName={styles.overlay}
                shouldCloseOnEsc={shouldCloseOnEsc}
                shouldCloseOnOverlayClick={shouldCloseOnOverlayClick}
            >
                <div className={cn(styles.card, cardClassName)} onClick={(e: any) => e.stopPropagation()}>
                    {(showCross || title) && <div className={cn(styles.header, headerClassName)}>
                        <h4 className={styles.title}>{title ? title : ''}</h4>
                        {showCross && <SVG name='cross' onClick={onModalClose} className={styles.cross} />}
                    </div>}
                    <div className={cn(styles.content, className)}>
                        {children}
                    </div>
                </div>
            </ReactModal>
        </div>
    )
}

export default Modal