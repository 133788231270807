import cn from 'classnames'
import React, { FunctionComponent, memo } from "react"
import SVG from '../SVG'
import styles from "./style.module.scss"


export type CheckBoxAlign = 'top' | 'center'

type InternalCheckboxProps = {
    className?: string
    text: string | FunctionComponent | JSX.Element
    value?: boolean
    checkBoxAlign?: CheckBoxAlign
    disabled?: boolean
    validation?: boolean | string
    onChange: () => void
}

const InternalCheckbox = memo((props: InternalCheckboxProps) => {
    const handleClick = () => {
        if (props.onChange && !props.disabled)
            props.onChange()
    }

    const className = cn(
        styles.checkbox,
        props.className,
        { [styles.success]: props.value && (props.validation === true || props.validation === '') },
        { [styles.error]: (props.validation === false || props.validation !== '') },
        { [styles.disabled]: props.disabled },
    )

    const checkBoxClasses = cn(
        styles.rectangle,
        { [styles.top]: props.checkBoxAlign && props.checkBoxAlign === 'top' }
    )

    return <div className={className} onClick={handleClick}>
        <div className={checkBoxClasses}>
            {props.value && <div className={styles.checkmark}><SVG name='checkmark' /></div>}
        </div>
        <div className={styles.text}>{props.text}</div>
    </div>
})

export default InternalCheckbox