import { apm } from '@elastic/apm-rum'
import moment from 'moment'
import { Mutex } from 'async-mutex'
import { appConfig } from '../config'
import * as Enums from "./enums"
import * as Types from "./types"
import * as IBTypes from "../ib/utils/types"
import Utils from "./utils"


export enum Authorize { Yes, No };
type AuthResponse = Types.AuthResponse & Types.ErrorAuthResponse
type RequestParams = {
    payload: {
        module: string
        action: string
    }[]
    session_id?: string
    token?: string
    challengeResponse?: string
    accessToken?: string
    refreshToken?: string
}
class Api {
    private tfboToken: string
    private tfboSession: string
    private accessToken: string
    private refreshToken: string
    private refreshTokenValidUntil: string
    private mutex = new Mutex();

    constructor() {
        this.tfboSession = localStorage[Enums.__STORAGE.session]
        this.tfboToken = localStorage[Enums.__STORAGE.token]
        this.accessToken = localStorage[Enums.__STORAGE.accessToken]
        this.refreshToken = localStorage[Enums.__STORAGE.refreshToken]
        this.refreshTokenValidUntil = localStorage[Enums.__STORAGE.validUntil]

    }
    _request<T>(url: string,
        method: string,
        auth: Authorize,
        throwStatusError: boolean,
        data?: RequestParams | Object,
        contentType: string = 'application/json') {
        const headers: HeadersInit = { 'Content-Type': contentType }

        if (auth === Authorize.Yes && this.accessToken) {
            headers.authorization = `Bearer ${this.accessToken}`
        }

        const config: RequestInit = {
            method,
            headers,
            credentials: 'include',
        }


        // logging into elastic apm
        let transaction: any = undefined
        let processSpan: any = undefined
        let fetchSpan: any = undefined
        if (data) {
            config.body = JSON.stringify(data)
            const body: RequestParams = data as RequestParams
            if (body.payload) {
                const name = body.payload.length > 1
                    ? 'Multiple payloads'
                    : `${body.payload[0].module} -> ${body.payload[0].action}`
                transaction = apm.startTransaction(name, 'custom.request')
                const labelValue = body.payload.length > 1
                    ? body.payload.map((x: any) => `${x.module} -> ${x.action}`).join(', ')
                    : `${body.payload[0].module} -> ${body.payload[0].action}`
                transaction && transaction.addLabels({ payload: labelValue })
                fetchSpan = transaction ? transaction.startSpan(name, 'external.http') : undefined
            }
        }

        const response = fetch(url, config)

        return response
            .then(response => {
                fetchSpan && fetchSpan.end()
                processSpan = transaction
                    ? transaction.startSpan('Process response', 'external.process')
                    : undefined
                if (throwStatusError && response.status !== 200)
                    throw Error('Server is unavailable. Please try later.')
                return response
            })
            .then(response => {
                const contentType = response.headers.get("content-type")

                if (contentType) {
                    if (contentType.startsWith('application/json'))
                        return response.json()
                    else if (contentType.startsWith('application/octet-stream'))
                        return response.blob()
                    else if (contentType.startsWith('image/png'))
                        return response.blob()
                }

                return response
            })
            .then(response => {
                processSpan && processSpan.end()
                transaction && transaction.end()
                return response as T
            })
    }

    _tfbo<T>(data: Partial<RequestParams> = {}, auth: Authorize = Authorize.Yes) {
        if (auth === Authorize.Yes) {
            data.session_id = this.tfboSession
            data.token = this.tfboToken
        }
        const request = () => {
            return this._request<Types.APIResponse<T>>(appConfig.API_URL, 'post', auth, true, data)
        }

        if (auth === Authorize.Yes) {

            return this.mutex.runExclusive(async () => {
                const response = await request()
                if (response.payload[0].status === 'NOT_AUTHORIZED') {
                    if (await this.UpdateTokens())
                        return request()
                    else {
                        window.dispatchEvent(new Event("TokenExpired"))
                        return Promise.resolve(response)
                    }
                }
                else return Promise.resolve(response)
            })
        }
        else return request()
    }

    _auth<T>(url: string, method: string, data?: Object, auth: Authorize = Authorize.Yes) {
        const request = () => {
            return this._request<AuthResponse & T>(
                `${appConfig.AUTH_URL}/${url}`,
                method,
                auth,
                false,
                data)
        }

        if (auth === Authorize.Yes) {
            return this.mutex.runExclusive(async () => {
                const response = await request()
                if (response.status >= 400) {
                    if (await this.UpdateTokens())
                        return request()
                    else {
                        window.dispatchEvent(new Event("TokenExpired"))
                        return Promise.resolve(response)
                    }
                }
                else {
                    return Promise.resolve(response)
                }
            })
        }
        else {
            return request()
        }
    }

    downloadClientStatement<T>(urlParams: string) {
        const request = () => {
            return this._request<Response & T>(
                `${appConfig.CLIENT_STATEMENT_URL}/download?${urlParams}`,
                'GET',
                Authorize.Yes,
                false
            )
        }

        return this.mutex.runExclusive(async () => {
            const response = await request()
            if (response.status === 400) {
                if (await this.UpdateTokens())
                    return request()
                else {
                    window.dispatchEvent(new Event("TokenExpired"))
                    return Promise.resolve(response)
                }
            }
            else return Promise.resolve(response)
        })
    }

    downloadTradeStatement<T>(urlParams: string) {
        const request = () => {
            return this._request<Response & T>(
                `${appConfig.TRADE_STATEMENT_CSV_URL}/download?${urlParams}`,
                'GET',
                Authorize.Yes,
                false
            )
        }

        return this.mutex.runExclusive(async () => {
            const response = await request()
            if (response.status === 400) {
                if (await this.UpdateTokens())
                    return request()
                else {
                    window.dispatchEvent(new Event("TokenExpired"))
                    return Promise.resolve(response)
                }
            }
            else return Promise.resolve(response)
        })
    }

    downloadWithdrawalReceipt<T>(urlParams: string) {
        const request = () => {
            return this._request<Response & T>(
                `${appConfig.WITHDRAWAL_RECEIPT_URL}?${urlParams}`,
                'GET',
                Authorize.Yes,
                false
            )
        }

        return this.mutex.runExclusive(async () => {
            const response = await request()
            if (response.status === 400) {
                if (await this.UpdateTokens())
                    return request()
                else {
                    window.dispatchEvent(new Event("TokenExpired"))
                    return Promise.resolve(response)
                }
            }
            else return Promise.resolve(response)
        })
    }

    _createTFBOParams(params: Types.RequestBody, challenge?: string): RequestParams {
        let result: RequestParams = { payload: [params] }
        if (challenge)
            result = { ...result, challengeResponse: challenge }
        return result
    }

    /// Authentication ///

    registerUser = (data: Types.RegisterUserParams) => {
        const params = this._createTFBOParams(
            {
                module: 'authentication',
                action: 'registerUser',
                parameters: data
            })
        return this._tfbo(params, Authorize.No)
    };

    Login = (data: Types.EmailAndPassword) => {
        const params = {
            email: data.email,
            password: data.password,
            recaptchaResponse: data.recaptchaResponse,
        }
        return this._auth<Types.ErrorAuthResponse>
            ('auth/login', 'post', params, Authorize.No)
    };

    // set auth info
    setTFBOAuth = (session: string, token: string) => {
        this.tfboSession = session
        this.tfboToken = token
        localStorage[Enums.__STORAGE.session] = session
        localStorage[Enums.__STORAGE.token] = token
    };

    setAuthTokens = (tokens?: Types.AuthTokens) => {
        if (tokens) {
            this.accessToken = tokens.accessToken
            this.refreshToken = tokens.refreshToken
            this.refreshTokenValidUntil = tokens.refreshTokenValidUntil
            localStorage[Enums.__STORAGE.accessToken] = tokens.accessToken
            localStorage[Enums.__STORAGE.refreshToken] = tokens.refreshToken
            localStorage[Enums.__STORAGE.validUntil] = tokens.refreshTokenValidUntil
        }
        else {
            this.accessToken = ''
            this.refreshToken = ''
            this.refreshTokenValidUntil = ''
            localStorage.removeItem(Enums.__STORAGE.accessToken)
            localStorage.removeItem(Enums.__STORAGE.refreshToken)
            localStorage.removeItem(Enums.__STORAGE.validUntil)
        }
    };

    // logout
    Logout = () => {
        const params = this._createTFBOParams(
            {
                module: 'authentication',
                action: 'logout'
            })

        return this._tfbo<Types.APIResponse<any>>(params)
            .then((response) => {
                this.setTFBOAuth('', '')
                this.setAuthTokens(undefined)
                return response
            })
    };

    VerifyTokens = () => {
        if (this.accessToken && this.refreshToken) {
            const diff = moment().diff(moment(this.refreshTokenValidUntil), 'minutes')
            if (diff < -1) return true
            else return false
        }
        return false
    };

    UpdateTokens = async (): Promise<boolean> => {
        if (this.accessToken && this.refreshToken) {
            const params = {
                accessToken: this.accessToken,
                refreshToken: this.refreshToken,
            }
            try {
                const tokens = await this._auth<Types.AuthTokens>(
                    'auth/refresh',
                    'post',
                    params,
                    Authorize.No
                )
                if (!tokens.code)
                    this.setAuthTokens(tokens)
                return tokens.code ? false : true
            } catch (e) {
                return false
            }
        }
        else return false
    }

    // verify_password
    verifyPortalPassword = (data: Types.VerifyPasswordParams) => {
        const params = this._createTFBOParams(
            {
                module: "authentication",
                action: "verify_password",
                parameters: data
            })

        return this._tfbo(params)
    }

    // change_password
    changePortalPassword = (data: Types.ChangePasswordParams) => {
        const params = this._createTFBOParams(
            {
                module: "authentication",
                action: "change_password",
                parameters: data
            })

        return this._tfbo(params)
    }
    // forgot_password
    resetPasswordStepOne = (data: Pick<Types.ResetPassword, 'email_id' | 'response'>) => {
        const params = this._createTFBOParams(
            {
                module: "authentication",
                action: "forgot_password_web",
                parameters: data
            })

        return this._tfbo<Types.ReCaptchaResponse>(params, Authorize.No)
    }

    resetPasswordStepTwo = (data: Pick<Types.ResetPassword, 'password' | 'password_reset_token' | 'response'>) => {
        const params = this._createTFBOParams(
            {
                module: "authentication",
                action: "forgot_password_web",
                parameters: data
            })

        return this._tfbo<Types.ReCaptchaResponse>(params, Authorize.No)
    }

    //register

    /// Accounts ///
    // getUserAccounts
    getUserAccounts = (data: Types.getUserAccountsRequestParams) => {
        const params = this._createTFBOParams(
            {
                module: "account",
                action: "getUserAccounts",
                parameters: data
            }
        )

        return this._tfbo(params)
    }

    // canCreateAdditionalTradingAccount
    canCreateAdditionalTradingAccount = () => {
        const params = this._createTFBOParams(
            {
                module: "account",
                action: "canCreateAdditionalTradingAccount"
            }
        )

        return this._tfbo<boolean>(params)
    }
    // transfer
    transferMoneyStepOne = (data: Types.TransferMoneyStepOne) => {
        const params = this._createTFBOParams(
            {
                module: "account",
                action: "transfer",
                parameters: data
            }
        )

        return this._tfbo<Types.TransferMoneyResponse>(params)
    }

    transferMoneyStepTwo = (data: Types.TransferMoneyStepTwo) => {
        const params = this._createTFBOParams(
            {
                module: "account",
                action: "transfer",
                parameters: data
            }
        )

        return this._tfbo<Types.TransferMoneyResponse>(params)
    }
    availableInterAccountTransfer = () => {
        const params = this._createTFBOParams(
            {
                module: "account",
                action: "availableInterAccountTransfer",
                parameters: {}
            }
        )

        return this._tfbo<number>(params)
    }

    // getTransferHistory
    getTransferHistory = () => {
        const params = this._createTFBOParams(
            {
                module: "account",
                action: "getTransferHistory",
            }
        )

        return this._tfbo<Types.TransefrsHistoryResponse>(params)
    }

    // verify
    verifyAccountPassword = (data: Types.VerifyAccountPasswordParams) => {
        const params = this._createTFBOParams(
            {
                module: "account",
                action: "verify",
                parameters: data
            }
        )

        return this._tfbo(params)
    }

    verifyTradingAccount = (data: Required<Pick<Types.UpdateTradingAccountParams, 'account' | 'password'>>) => {
        const params = this._createTFBOParams(
            {
                module: "account",
                action: "verify",
                parameters: data
            }
        )

        return this._tfbo(params)
    }

    // update
    updateTradingAccount = (data: Types.UpdateTradingAccountParams) => {
        const params = this._createTFBOParams(
            {
                module: "account",
                action: "update",
                parameters: data
            }
        )

        return this._tfbo(params)
    }

    getTradingAccountTempPassword = (accountNumber: number) => {
        const params = this._createTFBOParams(
            {
                module: "account",
                action: "getAccountTempInfo",
                parameters: { accountNumber }
            }
        )

        return this._tfbo<Types.TradingAccountTempPasswordResponse>(params)
    }

    // stat
    getAccountStatPayload = (type?: Enums.TradeAccountType): Types.RequestBody => {
        return {
            module: "account",
            action: "stats",
            parameters: type
                ? {
                    accountType: type === Enums.TradeAccountType.Live ? "LIVE" : "DEMO"
                }
                : {}
        }
    }
    getAccountStat = (type?: Enums.TradeAccountType) => {
        const params = this._createTFBOParams(this.getAccountStatPayload(type))
        return this._tfbo<Types.AccountStatResponse>(params)
    }

    //add
    createTradingAccount = (data: Partial<Types.CreateTradingAccountParams>, challenge?: string) => {
        const params = this._createTFBOParams(
            {
                module: "account",
                action: "add",
                parameters: {
                    live: data.type === Enums.TradeAccountType.Live,
                    currency: data.currency,
                    leverage: data.leverage,
                    balance: data.balance,
                    platformCode: data.platform,
                    platformAccountType: data.platformAccountType,
                    source: data.source,
                    accPassword: data.accPassword ? data.accPassword : undefined,
                    //demo contest
                    fromContest: data.fromContest,
                    username: data.username
                }
            }, challenge
        )

        return this._tfbo<Types.CreateTradeAccountResponse>(params)
    }

    createDemoPortalAccount = (data: Partial<Types.CreateDemoPortalAccountParams>) => {
        const params = this._createTFBOParams(
            {
                module: "account",
                action: "adddemo",
                parameters: data
            })

        return this._tfbo<Types.CreateTradeAccountResponse>(params, Authorize.No)
    }

    // profile
    accountProfile = (accountId: number) => {
        const params = this._createTFBOParams(
            {
                module: "account",
                action: "profile",
                parameters: {
                    accountId
                }
            }
        )

        return this._tfbo(params)
    }

    /// Profile ///
    // get_popups
    getPopups = () => {
        const params = this._createTFBOParams(
            {
                module: "profile",
                action: "get_popups",
            }
        )
        return this._tfbo(params)
    }
    // get_user
    getUserPayload = (): Types.RequestBody => {
        return {
            module: "profile",
            action: "get_user"
        }
    }

    getUser = () => {
        const params = this._createTFBOParams(this.getUserPayload())
        return this._tfbo<Types.UserProfile>(params)
    }

    addAttributeToUserProfile = (data: Partial<Types.UserProfile['additionalAttributes']>) => {
        const params = this._createTFBOParams(
            {
                module: "profile",
                action: "addAdditionalAttributeToUser",
                parameters: { additionalAttributes: data }
            }
        )
        return this._tfbo(params)
    }

    // update
    updateUserProfile = (data: Types.UserProfile & { isADemoAccount?: boolean }) => {
        const params = this._createTFBOParams(
            {
                module: "profile",
                action: "update",
                parameters: data
            }
        )

        return this._tfbo(params)
    }

    // notifications
    getUserNotifications = (data?: Types.GetNotificationsParams) => {
        const params = this._createTFBOParams(
            {
                module: "profile",
                action: "notifications",
                parameters: data
            }
        )

        return this._tfbo<Types.Notification[]>(params)
    }
    getNotificationsMap = () => {
        const params = this._createTFBOParams(this.getNotificationsMapPayload())
        return this._tfbo<Types.Notification[]>(params)
    }
    getNotificationsMapPayload = (): Types.RequestBody => {
        return {
            module: "profile",
            action: "getnotification",
        }
    }
    clearNotifications = () => {
        const params = this._createTFBOParams(
            {
                module: "profile",
                action: "markReadNotification",
            }
        )

        return this._tfbo(params)
    }
    // files
    getUserFiles = () => {
        const params = this._createTFBOParams(
            {
                module: "profile",
                action: "files"
            }
        )

        return this._tfbo<Types.DocumentFile[]>(params)
    }
    // claim
    claim = (data: Types.IndividualClaimParams) => {
        const params = this._createTFBOParams(
            {
                "module": "profile",
                "action": "claim",
                parameters: data
            }
        )

        return this._tfbo<Types.IndexedObject | undefined>(params)
    }

    // claim documents. Difference from claim: doesnt check application, doesn't send emails
    claimDocuments = (data: Types.IndividualClaimParams) => {
        const params = this._createTFBOParams(
            {
                "module": "profile",
                "action": "claim_documents",
                parameters: data
            }
        )

        return this._tfbo<void>(params)
    }

    // deleteCreditCard
    deleteCreditCard = (data: Types.DeleteCreditCardParams) => {
        const params = this._createTFBOParams(
            {
                module: "account",
                action: "deleteCreditCard",
                parameters: data
            }
        )

        return this._tfbo(params)
    }

    /// payment ///
    // transactions
    getTransactionsPayload = (data?: Types.GetTransactionsParams): Types.RequestBody => {
        return {
            module: "payment",
            action: "transactions",
            parameters: data
        }
    }
    getTransactions = (data?: Types.GetTransactionsParams) => {
        const params = this._createTFBOParams(this.getTransactionsPayload(data))
        return this._tfbo<Types.Transaction[]>(params)
    }
    // getProcessorsByAccount
    getProcessorsByAccount = (accountId: number) => {
        const params = this._createTFBOParams(
            {
                module: "payment",
                action: "getProcessorsByAccount",
                parameters: {
                    accountId: accountId,
                    // source: "ThinkPortal",
                    AllowCrypto: true,
                }
            }
        )
        return this._tfbo<Types.AccountProcessor[]>(params)
    }

    // getProcessorsByCurrentUser
    getProcessorsByCurrentUser = () => {
        const params = this._createTFBOParams(
            {
                module: "payment",
                action: "processors_by_current_user",
            }
        )
        return this._tfbo<Types.AccountProcessor[]>(params)
    }
    // getBitGoAddress
    getBitGoAddress = (data: Types.GetBitGoAddressParams) => {
        const params = this._createTFBOParams(
            {
                module: "payment",
                action: "getBitGoAddress",
                parameters: data
            },
        )
        return this._tfbo<Types.GetBitGoAddressResponse>(params)
    }
    // "parameters":{"userId":10089191,"cryptoCurrency":{"id": 181,"code": "BTC","name":"Bitcoin"}}}]}
    // initiatePayment
    initiatePayment = (data: Types.InitPaymentParams, challenge?: string) => {
        const params = this._createTFBOParams(
            {
                module: "payment",
                action: "initiatePayment",
                parameters: data
            }, challenge
        )
        return this._tfbo<Types.InitPaymentResponse>(params)
    }

    // initiate
    initiate = (data: Types.InitiateParams, challenge?: string) => {
        const params = this._createTFBOParams(
            {
                module: "payment",
                action: "initiate",
                parameters: data
            }, challenge
        )
        return this._tfbo<Types.InitiateResponse>(params)
    }

    appleWebPayVerification = (appleUrl: string) => {
        const params = this._createTFBOParams(
            {
                module: "payment",
                action: "appleWebPayVerification",
                parameters: { appleUrl }
            },
        )
        return this._tfbo<any>(params)
    }

    // cardpayLATAMPaymentMethods
    cardpayLATAMPaymentMethods = () => {
        const params = this._createTFBOParams(
            {
                module: "payment",
                action: "cardpayLATAMPaymentMethods",
                parameters: {}
            },
        )
        return this._tfbo<{ [key: string]: string }>(params)
    }

    // generatePayPalToken
    generatePayPalToken = () => {
        const params = this._createTFBOParams(
            {
                module: "payment",
                action: "generatePayPalToken",
                parameters: {}
            },
        )
        return this._tfbo<string>(params)
    }

    // isPayPalSupported
    isPayPalSupported = (countryCode: string) => {
        const params = this._createTFBOParams(
            {
                module: "payment",
                action: "isPayPalSupported",
                parameters: {
                    countryCode,
                }
            },
        )
        return this._tfbo<boolean>(params)
    }

    // getusercreditcards
    getusercreditcards = (parameters: Types.GetUserCreditCardsParams) => {
        const params = this._createTFBOParams(
            {
                module: "payment",
                action: "getusercreditcards",
                parameters,
            },
        )
        return this._tfbo<Types.CreditCard[]>(params)
    }

    // getThunderXPayBanks
    getThunderXPayBanks = () => {
        const params = this._createTFBOParams(
            {
                module: "payment",
                action: "getThunderXPayBanks",
                parameters: {},
            },
        )
        return this._tfbo<Types.XPayQRBank[]>(params)
    }

    // getThunderXPayBanks
    fetchBankCodes = (isWithdrawal: boolean = false) => {
        const params = this._createTFBOParams(
            {
                module: "payment",
                action: "fetchBankCodes",
                parameters: {
                    isWithdrawal,
                },
            },
        )
        return this._tfbo<Types.BJPBank[]>(params)
    }

    subscribe = (parameters: Types.SubscriptionSubscribeParams) => {
        const params = this._createTFBOParams(
            {
                module: "subscription",
                action: "subscribe",
                parameters: {
                    ...parameters,
                },
            },
        )
        return this._tfbo<Types.SubscriptionSubscribeResponse>(params)
    }

    editBankInfo = (parameters: Types.EditBankInfoParams) => {
        const params = this._createTFBOParams(
            {
                module: "subscription",
                action: "editBankInfo",
                parameters: {
                    ...parameters,
                },
            },
        )
        return this._tfbo<Types.SubscriptionSubscribeResponse>(params)
    }

    getSubscriptionStatus = (parameters: Types.GetSubscriptionStatusParams) => {
        const params = this._createTFBOParams(
            {
                module: "subscription",
                action: "getSubscriptionStatus",
                parameters: {
                    ...parameters,
                },
            },
        )
        return this._tfbo<Types.GetSubscriptionStatusResponse>(params)
    }

    changeTier = (parameters: Types.ChangeTierParams) => {
        const params = this._createTFBOParams(
            {
                module: "subscription",
                action: "changeTier",
                parameters: {
                    ...parameters,
                },
            },
        )
        return this._tfbo<Types.ChangeTierResponse>(params)
    }

    stopSubscription = (parameters: Types.StopSubscriptionParams) => {
        const params = this._createTFBOParams(
            {
                module: "subscription",
                action: "stopSubscription",
                parameters: {
                    ...parameters,
                },
            },
        )
        return this._tfbo<Types.StopSubscriptionResponse>(params)
    }

    getPaymentDetails = (parameters: Types.GetPaymentDetailsParams) => {
        const params = this._createTFBOParams(
            {
                module: "subscription",
                action: "getPaymentDetails",
                parameters: {
                    ...parameters,
                },
            },
        )
        return this._tfbo<Types.GetPaymentDetailsResponse>(params)
    }

    getBillingHistory = (parameters: Types.GetBillingHistoryParams) => {
        const params = this._createTFBOParams(
            {
                module: "subscription",
                action: "getBillingHistory",
                parameters: {
                    ...parameters,
                },
            },
        )
        return this._tfbo<Types.SubscriptionBillingHistoryItem[]>(params)
    }

    checkOpenPositionsTT = (parameters: Types.CheckOpenPositionsTTParams) => {
        const params = this._createTFBOParams(
            {
                module: "subscription",
                action: "checkOpenPositionsTT",
                parameters: {
                    ...parameters,
                },
            },
        )
        return this._tfbo<Types.CheckOpenPositionsTTResponse>(params)
    }

    restartSubscription = (parameters: Types.RestartSubscriptionParams) => {
        const params = this._createTFBOParams(
            {
                module: "subscription",
                action: "restartSubscription",
                parameters: {
                    ...parameters,
                },
            },
        )
        return this._tfbo<Types.RestartSubscriptionResponse>(params)
    }

    cancelSubscription = (parameters: Types.CancelSubscriptionParams) => {
        const params = this._createTFBOParams(
            {
                module: "subscription",
                action: "cancelSubscription",
                parameters: {
                    ...parameters,
                },
            },
        )
        return this._tfbo<Types.CancelSubscriptionResponse>(params)
    }

    // getwithdrawaloptions
    getWithdrawalOptionsPayload = (accountId: number): Types.RequestBody => {
        return {
            module: "payment",
            action: "getwithdrawaloptions",
            parameters: {
                accountId,
            }
        }
    }
    getWithdrawalOptions = (accountId: number) => {
        const params = this._createTFBOParams(this.getWithdrawalOptionsPayload(accountId))
        return this._tfbo<Types.WithdrawalOptions>(params)
    }

    // getpendingwithdrawalsum
    getPendingWithdrawalSumPayload = (accountId: number): Types.RequestBody => {
        return {
            module: "account",
            action: "getpendingwithdrawalsum",
            parameters: {
                accountId,
            }
        }
    }

    getPendingWithdrawalSum = (accountId: number) => {
        const params = this._createTFBOParams(this.getPendingWithdrawalSumPayload(accountId))
        return this._tfbo<number>(params)
    }

    // getFreezeMoney
    getFreezeMoneyPayload = (accountId: number): Types.RequestBody => {
        return {
            module: "payment",
            action: "getFreezeMoney",
            parameters: {
                accountId,
            }
        }
    }

    getFreezeMoney = (accountId: number) => {
        const params = this._createTFBOParams(this.getFreezeMoneyPayload(accountId))
        return this._tfbo<number>(params)
    }

    // add_bank_account
    addBankAccount = (data: Types.AddBankAccountParams) => {
        const params = this._createTFBOParams(
            {
                module: "payment",
                action: "add_bank_account",
                parameters: data
            }
        )
        return this._tfbo<{}>(params)
    }
    // delete_bank_account
    deleteBankAccount = (data: Types.BankAccount) => {
        const params = this._createTFBOParams(
            {
                module: "payment",
                action: "delete_bank_account",
                parameters: {
                    accountNumber: data.accountNumber
                }
            }
        )
        return this._tfbo<{}>(params)
    }
    // get_bank_accounts
    getBankAccounts = () => {
        const params = this._createTFBOParams(
            {
                module: "payment",
                action: "get_bank_accounts"
            }
        )
        return this._tfbo<Types.BankAccount[]>(params)
    }
    // get_payment_info
    // getUserCreditCards
    getPaymentInfo = (data: Types.getPaymentInfoParams) => {
        const params = this._createTFBOParams(
            {
                module: "payment",
                action: "get_payment_info",
                parameters: data
            }
        )
        return this._tfbo<Types.Transaction>(params)
    }

    getUserCreditCards = (data: Types.GetUserCreditCardsRequestParams) => {
        const params = this._createTFBOParams(
            {
                module: "payment",
                action: "getUserCreditCards",
                parameters: data
            }
        )
        return this._tfbo(params)
    }

    submitWithdrawal = (data: Types.SubmitWithdrawalParams) => {
        const params = this._createTFBOParams(
            {
                module: "payment",
                action: "submitWithdrawal",
                parameters: data
            }
        )
        return this._tfbo<Types.Transaction[]>(params)
    }

    cancelwithdraw = (transactionID: number) => {
        const params = this._createTFBOParams(
            {
                module: "payment",
                action: "cancelwithdraw",
                parameters: {
                    id: transactionID,
                }
            }
        )
        return this._tfbo(params)
    }

    sendPhoneVerificationCode = () => {
        const params = this._createTFBOParams(
            {
                module: 'phoneVerification',
                action: 'sendVerificationCode',
                parameters: {}
            }
        )

        return this._tfbo<Types.TwilioSmsReponse>(params)
    }

    checkPhoneVerificationCode = (code: number, sid: string) => {
        const params = this._createTFBOParams(
            {
                module: 'phoneVerification',
                action: 'checkVerificationCode',
                parameters: {
                    code: code,
                    sid: sid,
                }
            }
        )

        return this._tfbo<Types.CheckVerificationCodeResponse>(params)
    }

    sendTwilioSMS = (data: Types.SendTwilioSmsParams) => {
        const params = this._createTFBOParams(
            {
                module: 'payment',
                action: 'sendVerificationCode',
                parameters: {
                    userId: data.userId,
                    channel: "sms",
                    to: data.to
                }
            }
        )

        return this._tfbo<Types.TwilioSmsReponse>(params)
    }

    checkTwilioSMS = (data: Types.CheckTwilioSmsParams) => {
        const params = this._createTFBOParams(
            {
                module: 'payment',
                action: 'checkVerificationCode',
                parameters: {
                    userId: data.userId,
                    to: data.to,
                    code: data.code,
                    sid: data.sid
                }
            }
        )

        return this._tfbo<Types.TwilioSmsReponse>(params)
    }

    // getAstroPayDirectBanks
    // getPaymentsCount

    /// utility ///
    // getOrganizationSettings
    getOrganizationSettingsPayload = (country_id?: number): Types.RequestBody => {
        const parameters: Types.IndexedObject = {
            type: "LIVE"
        }
        if (country_id) parameters.country_id = country_id
        return {
            module: "utility",
            action: "getorganizationsettings",
            parameters
        }
    }
    getOrganizationSettings = (country_id?: number) => {
        const auth = country_id ? Authorize.No : Authorize.Yes
        const params = this._createTFBOParams(this.getOrganizationSettingsPayload(country_id))
        return this._tfbo<Types.OrganizationSettings>(params, auth)
    }
    getQuestions = (orgId: number) => {
        const params = this._createTFBOParams(
            {
                module: "application",
                action: "getQuestions",
                parameters: {
                    orgId
                }
            })

        return this._tfbo<Types.Question[]>(params, Authorize.No)
    }
    getTrustDockVerificationID = (applicationID: number) => {
        const params = this._createTFBOParams(
            {
                module: "application",
                action: "get_trust_dock_verification_id",
                parameters: {
                    applicationId: applicationID,
                }
            })

        return this._tfbo<Types.TrustDockResponse>(params)
    }

    getSumSubAccessToken = (applicationID: number) => {
        const params = this._createTFBOParams(
            {
                module: "application",
                action: "get_sum_sub_app_token",
                parameters: {
                    applicationId: applicationID,
                }
            })

        return this._tfbo<string>(params)
    }

    getLastApplicationsInfo = () => {
        const params = this._createTFBOParams(
            {
                module: "application",
                action: "getLastApplicationsInfo",
                parameters: {
                }
            })

        return this._tfbo<Types.AppInfo[]>(params)
    }

    getDocumentTypesPayload = (): Types.RequestBody => {
        return {
            module: "utility",
            action: "getdocumenttypes"
        }
    }
    getDocumentTypes = () => {
        const params = this._createTFBOParams(this.getDocumentTypesPayload())
        return this._tfbo<Types.DocType[]>(params, Authorize.No)
    }

    getTMCYOrgOverridesPayload = (): Types.RequestBody => {
        return {
            module: "utility",
            action: "getTMCYOrgOverrides"
        }
    }
    getTMCYOrgOverrides = () => {
        const params = this._createTFBOParams(this.getTMCYOrgOverridesPayload())
        return this._tfbo<string[]>(params, Authorize.No)
    }



    // getCountries
    getCountriesPayload = (showUnused: boolean): Types.RequestBody => {
        return {
            module: "utility",
            action: "getCountries",
            parameters: {
                showUnused
            }
        }
    }

    getCountries = (showUnused: boolean = false) => {
        const params = this._createTFBOParams(this.getCountriesPayload(showUnused))
        return this._tfbo<Types.Country[]>(params, Authorize.No)
    }

    getcurrenciesPayload = (processorId?: number): Types.RequestBody => {
        return {
            module: "utility",
            action: "getcurrencies",
            parameters: {
                processorId,
            }
        }
    }

    getcurrencies = (processorId?: number) => {
        const params = this._createTFBOParams(this.getcurrenciesPayload(processorId))
        return this._tfbo<Types.Currency[]>(params, Authorize.No)
    }

    getKYCProvider = (countryId: number) => {
        const params = this._createTFBOParams(
            {
                module: "utility",
                action: "getkycprovider",
                parameters: {
                    countryId
                }
            })

        return this._tfbo<Types.KYCProvider>(params, Authorize.No)
    }

    checkUserTermsAndConditionsPayload = (): Types.RequestBody => {
        return {
            module: "profile",
            action: "checkUserTermsAndConditions"
        }
    }

    checkUserTermsAndConditions = () => {
        const params = this._createTFBOParams(this.checkUserTermsAndConditionsPayload())
        return this._tfbo<Types.UserTermsAndConditions>(params)
    }

    getLatestTermsAndConditionsPayload = (): Types.RequestBody => {
        return {
            module: "profile",
            action: "getLatestTermsAndConditions"
        }
    }

    getLatestTermsAndConditions = () => {
        const params = this._createTFBOParams(this.getLatestTermsAndConditionsPayload())
        return this._tfbo<Types.LatestTermsAndConditions>(params)
    }

    agreeToLatestTermsAndConditions = (versionNumber: string) => {
        const params = this._createTFBOParams(
            {
                module: "profile",
                action: "agreeToLatestTermsAndConditions",
                parameters: {
                    versionNumber: versionNumber
                }
            }
        )

        return this._tfbo<void>(params)
    }

    // getAvatars
    getAvatars = () => {
        const params = this._createTFBOParams(
            {
                module: "profile",
                action: "getAvatars",
                parameters: {},
            }
        )

        return this._tfbo<Types.IAvatarImage[]>(params)
    }

    // changeAvatar
    changeAvatar = (attachmentId: number) => {
        const params = this._createTFBOParams(
            {
                module: "profile",
                action: "changeAvatar",
                parameters: {
                    attachmentId,
                },
            }
        )

        return this._tfbo<{}>(params)
    }

    // isAutoWithdrawFormEnabled
    // ip
    getIPPayload = (): Types.RequestBody => {
        return {
            module: "utility",
            action: "ip",
        }
    }

    getIP = () => {
        const params = this._createTFBOParams(this.getIPPayload())
        return this._tfbo<Types.GetIPResponse>(params, Authorize.No)
    }
    // getPreferredLanguages
    getPreferredLanguagesPayload = (): Types.RequestBody => {
        return {
            module: "utility",
            action: "getpreferredlanguages",
        }
    }

    getPreferredLanguages = () => {
        const params = this._createTFBOParams(this.getPreferredLanguagesPayload())
        return this._tfbo<Types.PreferredLanguage>(params, Authorize.No)
    }

    getTradingCentralLink = (data: Types.GetTradingCentralLinkParams) => {
        const params = this._createTFBOParams(
            {
                module: "utility",
                action: "createHandshakeURL",
                parameters: data
            }
        )

        return this._tfbo<string>(params)
    }

    getResearchPlatformLink = (data: Types.GetTradingCentralLoginLinkParams) => {
        const params = this._createTFBOParams(
            {
                module: "utility",
                action: "createTCLoginURL",
                parameters: data
            }
        )

        return this._tfbo<string>(params)
    }

    /// application ///
    // apps
    getUserAppsPayload = (): Types.RequestBody => {
        return {
            module: "application",
            action: "apps",
        }
    }

    getUserApps = () => {
        const params = this._createTFBOParams(this.getUserAppsPayload())
        return this._tfbo<Types.UserApplication>(params)
    }

    checkApplicationStatusesPayload = (): Types.RequestBody => {
        return {
            module: "application",
            action: "check_application_statuses",
        }
    }

    checkApplicationStatuses = () => {
        const params = this._createTFBOParams(this.checkApplicationStatusesPayload())
        return this._tfbo<Types.ApplicationStatusResponse[]>(params)
    }

    checkApplicationStatusByIDPayload = (applicationId: number): Types.RequestBody => {
        return {
            module: "application",
            action: "check_application_status_by_id",
            parameters: {
                applicationId
            }
        }
    }

    checkApplicationStatusByID = (applicationId: number) => {
        const params = this._createTFBOParams(this.checkApplicationStatusByIDPayload(applicationId))
        return this._tfbo<Types.ApplicationStatusResponse>(params)
    }

    // appExists = (accountHolderEmail: string) => {
    //     const params = this._createParams(
    //         {
    //             module: "application",
    //             action: "check_applicant_existance",
    //             parameters: { accountHolderEmail }
    //         }
    //     )

    //     return this._post<Types.AppExistsResponse>(Authorize.Yes,params)
    // }

    validateEmail = (data: Types.ValidateEmailParams) => {
        const params = this._createTFBOParams(
            {
                module: "application",
                action: "validateemail",
                parameters: data
            }
        )

        return this._tfbo(params, Authorize.No)
    }

    incrementalSubmit = (data: Types.AppInfo) => {
        const authorized = this.tfboSession || this.accessToken
        const params = this._createTFBOParams(
            {
                module: "application",
                action: authorized
                    ? "application_submit"
                    : "incremental_submit",
                parameters: data
            }
        )
        return this._tfbo<Types.IncrementalSubmitResponse>(params, authorized ? Authorize.Yes : Authorize.No)
    }

    simplified_submit_level_one = (data: Types.AppInfo) => {
        const params = this._createTFBOParams(
            {
                module: "application",
                action: "simplified_submit_level_one",
                parameters: data
            }
        )
        return this._tfbo<Types.SimplifiedSubmitLevelOneResponse>(params)
    }

    simplified_submit_level_two = (data: Types.AppInfo) => {
        const params = this._createTFBOParams(
            {
                module: "application",
                action: "simplified_submit_level_two",
                parameters: data
            }
        )
        return this._tfbo<Types.SimplifiedSubmitLevelOneResponse>(params)
    }

    getAccountTradingTypes = () => {
        const params = this._createTFBOParams(
            {
                module: "utility",
                action: "getAccountTradingTypes",
                parameters: {}
            }
        )

        return this._tfbo<Types.AccountTradingTypeModel[]>(params, Authorize.No)
    }

    getJumioUrl = (type: Types.JumioAcceptedDocument, locale: string) => {
        const params = this._createTFBOParams(
            {
                module: "application",
                action: "jumio_iframe_redirect_url",
                parameters: { type, locale }
            }
        )

        return this._tfbo<Types.JumioUrlResponse>(params)
    }

    getJumioDocumentTypes = () => {
        const params = this._createTFBOParams(
            {
                module: "application",
                action: "getJumioDocumentTypes"
            }
        )

        return this._tfbo<Types.JumioDocumentTypesResponse>(params)
    }

    sendOtpCode = (data: Types.VerifyEmailParams) => {
        const params = this._createTFBOParams(
            {
                module: "emailvalidation",
                action: "send_verification_code",
                parameters: data
            }
        )

        return this._tfbo<boolean>(params, Authorize.No)
    }

    verifyOtpCode = (otpCode: string, email: string) => {
        const params = this._createTFBOParams(
            {
                module: "emailvalidation",
                action: "verify_otp_code",
                parameters: {
                    otpValue: otpCode,
                    accountHolderEmail: email
                }
            }
        )

        return this._tfbo<boolean>(params, Authorize.No)
    }

    isValidOtpCode = (otpCode: string, recaptchaResponse: string) => {
        const params = this._createTFBOParams(
            {
                module: "utility",
                action: "isValidOtpCode",
                parameters: {
                    otpCode: otpCode,
                    recaptchaResponse: recaptchaResponse,
                }
            }
        )

        return this._tfbo<Types.IsValidOtpCodeResponse>(params)
    }

    isUserVerifiedPayload = (email: string): Types.RequestBody => {
        return {
            module: "emailvalidation",
            action: "isuserverified",
            parameters: {
                userEmail: email
            }
        }
    }



    isUserVerified = (email: string) => {
        const params = this._createTFBOParams(this.isUserVerifiedPayload(email))
        return this._tfbo<boolean>(params, Authorize.No)
    }


    isEmailVerificationRequired = (countryId: number) => {
        const params = this._createTFBOParams(
            {
                module: "emailvalidation",
                action: "isemail_verification_required",
                parameters: { originCountry: countryId }
            }
        )

        return this._tfbo<boolean>(params, Authorize.No)
    }

    //returns AddressLookupResponse
    addressLookup = (data: Types.AddressLookupParams) => {
        const params = this._createTFBOParams(
            {
                module: "application",
                action: "addressLookup",
                parameters: data
            }
        )

        return this._tfbo<Types.AddressLookupRecord[]>(params, Authorize.No)
    }

    getDocumentsJPN = () => {
        const params = this._createTFBOParams(
            {
                module: "application",
                action: "get_documents",
                parameters: {}
            }
        )

        return this._tfbo<Types.DocsJPNResponse>(params)
    }

    getMigrationStatusPayload = (): Types.RequestBody => {
        return {
            module: "migration",
            action: "getMigrationStatus",
            parameters: {}
        }
    }

    getMigrationStatus = () => {
        const params = this._createTFBOParams(this.getMigrationStatusPayload())
        return this._tfbo<Types.MigrationInfo>(params)
    }

    setMigrationResponse = (response: Types.UserMigrationResponse) => {
        const params = this._createTFBOParams({
            module: "migration",
            action: "setMigrationResponse",
            parameters: {
                response: response,
            }
        })
        return this._tfbo(params)
    }

    appInfoPayload = (): Types.RequestBody => {
        return {
            module: "application",
            action: "get_application_info",
            parameters: {
                email: Utils.loadEncryptedString(Enums.__STORAGE.user),
            }
        }
    }

    appInfo = () => {
        const params = this._createTFBOParams(this.appInfoPayload())
        return this._tfbo<Types.AppInfo>(params, Authorize.No)
    }

    sendLastField = (field: string) => {
        const params = this._createTFBOParams(
            {
                module: "application",
                action: "setFormLastField",
                parameters: {
                    accountHolderEmail: Utils.loadEncryptedString(Enums.__STORAGE.user),
                    formLastField: field
                }
            }
        )

        return this._tfbo(params, Authorize.No)
    }

    getAppRules = (country_id: number, accountType?: Types.ApplicationType) => {
        const params = this._createTFBOParams(
            {
                module: "application",
                action: "get_validation_rules",
                parameters: { country_id, accountType }
            }
        )

        return this._tfbo<Types.AccountValidationRules>(params, Authorize.No)
    }

    //utility
    multilpeTFBO = (data: Types.RequestBody[], auth: Authorize = Authorize.Yes) => {
        const request = { payload: data }
        return this._tfbo<any>(request, auth)
    }

    checkTFBOResponse = (response: Types.APIResponse<any>,
        alsoValid?: Types.ResponseStatus[]): Types.ResponseStatus => {
        const { status } = response.payload[0]
        const valid = alsoValid ? ['OK', ...alsoValid] : ['OK']
        if (!valid.includes(status.toUpperCase()))
            throw Utils.apiError(response)
        return status
    }

    //2fa

    FA2Options = (): RequestInit => {
        return {
            // mode: 'no-cors',
            headers: {
                'Content-Type': 'application/json',
                'session': `Bearer ${this.accessToken}`,
                'token': `Bearer ${this.accessToken}`
            }
        }
    }

    isSignalCenterEnabled = (email?: string) => {
        const notAllowed = {
            allowed: false
        }
        if (!email) return Promise.resolve(notAllowed)
        return fetch(`${appConfig.SIGNALS_URL}/check-access/${encodeURI(email)}`, {
            method: "GET", ...this.FA2Options()
        })
            .then(response => {
                if (response.status === 200)
                    return response.json()
                throw new Error(`Failed to check Signal Center status. Status code ${response.status}`)
            })
            .then(response => response as Types.SignalCenterEnabledResponse)
            .catch(() => {
                return notAllowed
            })
    }


    get2FaStatus = () => {
        return this._auth<Types.FA2Status>(
            `user/tfa/status`,
            'get')
    }

    set2FaStatus = (enable: boolean, code: string) => {
        const params = {
            code: code
        }
        return this._auth<Types.ErrorAuthResponse>(
            enable
                ? `user/tfa/enable`
                : `user/tfa/disable`,
            'post', params)
    }

    verify2FaCode = (data: Types.FA2VerifyRequest) => {
        const payload: Types.FA2VerifyRequest = {
            email: data.email,
            code: data.code
        }
        if (data.recaptchaResponse)
            payload.recaptchaResponse = data.recaptchaResponse
        return this._auth<Types.ErrorAuthResponse>
            ('auth/tfa', 'post', payload)
    }

    generate2FaCode = async () => {
        return this._auth<Types.FA2QRCode>(
            `user/tfa/generate`, 'post')
    }

    //address lookup

    AddressLookupOptions = (): RequestInit => {
        return this.FA2Options()
    }

    getCurrencyPairRate = (params: Types.CurrencyPairRateParams) => {
        return fetch(`${appConfig.EXCHANGE_RATES_URL}/currency-pair?amount=${params.amount}&from=${params.from}&to=${params.to}&direction=${params.direction}`, {
            method: "GET",
        })
            .then(response => {
                if (response.status === 200) {
                    return response.json()
                } else {
                    throw new Error(response.statusText)
                }
            })
            .then(response => response as Types.CurrencyPairRateResponse)
    }

    addressLookupAutocomplete = (params: Types.AddressLookupAutocompleteParams) => {
        return fetch(`${appConfig.ADDRESS_LOOKUP_URL}/lookup`, {
            ...this.AddressLookupOptions(),
            method: "POST",
            body: JSON.stringify(params),
        })
            .then(response => {
                if (response.status === 200) {
                    return response.json()
                } else {
                    throw new Error(response.statusText)
                }
            })
            .then(response => response as Types.AddressLookupAutocompleteResponse)
    }

    addressLookupComponents = (params: Types.AddressLookupComponentsParams) => {
        return fetch(`${appConfig.ADDRESS_LOOKUP_URL}/components`, {
            ...this.AddressLookupOptions(),
            method: "POST",
            body: JSON.stringify(params),
        })
            .then(response => {
                if (response.status === 200) {
                    return response.json()
                } else {
                    throw new Error(response.statusText)
                }
            })
            .then(response => response as Types.AddressLookupComponentsResponse)
    }

    bjpPaymentFinishCallback = (transaction_id: string, status: string) => {
        return fetch(`${appConfig.PAYMENT_URL}/finish_callback/Bjp?transaction_id=${transaction_id}&status=${status}`, {
            ...this.AddressLookupOptions(),
            method: "POST",
        })
            .then(response => {
                if (response.status === 200) {
                    return response.json()
                } else {
                    throw new Error(response.statusText)
                }
            })
            .then(response => response)
    }

    generateSsoToken = () => {
        return this._auth<{
            ssoCode: string
        } & Types.ErrorAuthResponse>('internal/sso', 'post')
    }

    validateSsoToken = async (parameters: Types.ValidateSsoTokenParams): Promise<boolean> => {
        try {
            const response = await this._auth<Types.AuthTokens & Types.ErrorAuthResponse>
                ('internal/sso/exchange', 'post', parameters, Authorize.No)

            if (!response.code)
                this.setAuthTokens(response)
            return response.code ? false : true
        } catch (e) {
            return false
        }
    }

    getalltrades = (parameters: Types.GetAllAccountTradesParams) => {
        const params = this._createTFBOParams(
            {
                module: "account",
                action: "getalltrades",
                parameters: parameters
            }
        )

        return this._tfbo<Types.AccountTrade[]>(params)
    }

    sendTradeStatementsEmail = (parameters: Types.GetAllAccountTradesParams) => {
        const params = this._createTFBOParams(
            {
                module: "account",
                action: "sendTradeStatementsEmail",
                parameters: parameters
            }
        )

        return this._tfbo<void>(params)
    }

    getAccountDetails = (tradingId: number) => {
        const params = this._createTFBOParams(
            {
                module: "account",
                action: "getAccountDetails",
                parameters: {
                    "tradingId": tradingId
                }
            }
        )

        return this._tfbo<Types.AccountDetails>(params)
    }

    getGreenIDUIShow = (applicationID: number) => {
        const params = this._createTFBOParams(
            {
                module: "application",
                action: "greenIDUIShow",
                parameters: {
                    applicationId: applicationID,
                }
            })

        return this._tfbo<Types.GreenID>(params)
    }


    getGreenIDUISubmitCallBack = (applicationID: number) => {
        const params = this._createTFBOParams(
            {
                module: "application",
                action: "greenIDUISubmitCallback",
                parameters: {
                    applicationId: applicationID,
                }
            })

        return this._tfbo(params)
    }

    isThinkInvestOptionDisabled = (affiliateId: string, userId: number) => {
        const params = this._createTFBOParams(
            {
                module: "account",
                action: "isThinkInvestOptionDisabled",
                parameters: {
                    affiliateId, userId
                }
            }
        )

        return this._tfbo<boolean>(params)
    }
    getGreenIDUIAbortCallback = (applicationID: number) => {
        const params = this._createTFBOParams(
            {
                module: "application",
                action: "greenIDUIAbortCallback",
                parameters: {
                    applicationId: applicationID,
                }
            })

        return this._tfbo<any>(params)
    }

    refreshTTDemoBalance = (accountId: number, amount: number) => {
        const params = this._createTFBOParams(
            {
                module: "account",
                action: "refreshTTDemoBalance",
                parameters: {
                    accountId: accountId,
                    amount: amount,
                }
            }
        )

        return this._tfbo<{}>(params)
    }

    addTrustedDevice = () => {
        const params = this._createTFBOParams(
            {
                module: "authentication",
                action: "addTrustedDevice",
                parameters: {}
            }
        )

        return this._tfbo<{}>(params)
    }

    getTrustedDeviceStatus = () => {
        const params = this._createTFBOParams(
            {
                module: "authentication",
                action: "gettrusteddevicestatus",
                parameters: {}
            }
        )

        return this._tfbo<boolean>(params)
    }

    listTrustedDevice = () => {
        const params = this._createTFBOParams(
            {
                module: "authentication",
                action: "listTrustedDevices",
                parameters: {}
            }
        )

        return this._tfbo<Types.TrustedDeviceListResponse>(params)
    }

    removeTrustedDevice = (deviceId?: number) => {
        const params = this._createTFBOParams(
            {
                module: "authentication",
                action: "removeTrustedDevice",
                parameters: {
                    deviceId
                }
            }
        )

        return this._tfbo<{}>(params)
    }
    sendPartnershipQuestionnaire = (details: {
        user: number
        partnershipType: string// 'IB' | 'MM'
        answers: Array<{
            question: string
            answer?: string
        }>
    }) => {
        const params = this._createTFBOParams(
            {
                module: "partnership",
                action: "sendPartnershipQuestionnaire",
                parameters: {
                    userId: details.user,
                    partnershipType: details.partnershipType,
                    filledQuestionnaire: details.answers

                }
            }
        )

        return this._tfbo<{}>(params)
    }

    validateIB = (id: number) => {
        const params = this._createTFBOParams(
            {
                module: "application",
                action: "does_affiliate_exist_by_aid",
                parameters: {
                    aid: id
                }
            }
        )
        return this._tfbo<{}>(params, Authorize.No)
    }

    findAllAvailableRequestTypes = () => {
        const params = this._createTFBOParams(
            {
                module: "supportrequest",
                action: "findAllAvailableRequestTypes",
                parameters: {}
            }
        )
        return this._tfbo<Types.SupportRequestType[]>(params)
    }

    findAllRequestTypes = () => {
        const params = this._createTFBOParams(
            {
                module: "supportrequest",
                action: "findAllRequestTypes",
                parameters: {}
            }
        )
        return this._tfbo<Types.SupportRequestType[]>(params)
    }

    saveSupportRequest = (parameters: Types.SaveSupportRequest) => {
        const params = this._createTFBOParams(
            {
                module: "supportrequest",
                action: "savesupportrequest",
                parameters: parameters
            }
        )
        return this._tfbo<{}>(params)
    }

    getAllSupportRequests = (parameters: Types.GetAllSupportRequests) => {
        const params = this._createTFBOParams(
            {
                module: "supportrequest",
                action: "searchByFilter",
                parameters: parameters
            }
        )
        return this._tfbo<Types.GetAllSupportRequestsResponse>(params)
    }

    rateSupportRequest = (parameters: Types.RateSupportRequestParameters) => {
        const params = this._createTFBOParams(
            {
                module: "supportrequest",
                action: "rateSupportRequest",
                parameters: parameters
            }
        )
        return this._tfbo<{}>(params)
    }

    getAccountsByRequestType = (requestTypeId: number) => {
        const params = this._createTFBOParams(
            {
                module: "supportrequest",
                action: "getAccountsByRequestType",
                parameters: {
                    requestTypeId: requestTypeId,
                }
            }
        )
        return this._tfbo<Types.AccountStatResponse>(params)
    }

    getLastDemoContestDate = () => {
        const params = this._createTFBOParams(
            {
                module: "account",
                action: "getLastDemoContestDate",
                parameters: {}
            }
        )
        return this._tfbo<string>(params)
    }

    getUserTradingVolumePayload = (currency?: string): Types.RequestBody => {
        return {
            module: "payment",
            action: "getUserTradingVolume",
            parameters: {
                currency: currency,
            }
        }
    }

    getUserTradingVolume = (currency?: string) => {
        const params = this._createTFBOParams(this.getUserTradingVolumePayload(currency))
        return this._tfbo<Types.GetUserTradingVolumeResponse>(params)
    }

    checkPercentageAndRatioConfigurationPayload = (subProcessorId: number): Types.RequestBody => {
        return {
            module: "payment",
            action: "checkPercentageAndRatioConfiguration",
            parameters: {
                subProcessorId: subProcessorId,
            }
        }
    }

    checkPercentageAndRatioConfiguration = (subProcessorId: number) => {
        const params = this._createTFBOParams(this.checkPercentageAndRatioConfigurationPayload(subProcessorId))
        return this._tfbo<boolean>(params)
    }

    checkUserReferrerStatus = (userId: number) => {
        const params = this._createTFBOParams(
            {
                module: "referafriend",
                action: "checkUserReferrerStatus",
                parameters: { userId: userId }
            }
        )
        return this._tfbo<Types.UserReferrerStatus>(params)
    }

    checkQualificationCriteriaForTheReferrer = () => {
        const params = this._createTFBOParams(
            {
                module: "referafriend",
                action: "checkQualificationCriteriaForTheReferrer",
                parameters: {}
            }
        )
        return this._tfbo<Types.QualificationForTheReferrer>(params)
    }

    addNewReferrer = (userId: number) => {
        const params = this._createTFBOParams(
            {
                module: "referafriend",
                action: "addNewReferrer",
                parameters: { userId: userId }
            }
        )
        return this._tfbo<Types.AddNewReferrerResponse>(params)
    }

    generateRefLinkPayload = (): Types.RequestBody => {
        return {
            module: "referafriend",
            action: "generateRefLink",
            parameters: {}
        }
    }

    generateRefLink = () => {
        const params = this._createTFBOParams(this.generateRefLinkPayload())
        return this._tfbo<Types.GenerateRefLinkResponse>(params)
    }

    getReferralActivitiesPayload = (userId: number): Types.RequestBody => {
        return {
            module: "referafriend",
            action: "getReferralActivities",
            parameters: { userId: userId }
        }
    }

    getReferralActivities = (userId: number) => {
        const params = this._createTFBOParams(this.getReferralActivitiesPayload(userId))
        return this._tfbo<Types.ReferralActivity[]>(params)
    }

    getRemainigDeposit = (accountId: number) => {
        const params = this._createTFBOParams(
            {
                module: "account",
                action: "getRemainingDepositAmount",
                parameters: {
                    accountId
                }
            }
        )

        return this._tfbo<Types.RemainingDeposit>(params)
    }

    // Partner Portal

    getpartnerinfoPayload = (parameters: Types.GetWithUserIDParams): Types.RequestBody => {
        return {
            module: "ibcommissioncportalmanagement",
            action: "getpartnerinfo",
            parameters: parameters
        }
    }

    searchPartnerClientListPayload = (parameters: Types.SearchPartnerClientListParams): Types.RequestBody => {
        return {
            module: "ibcommissioncportalmanagement",
            action: "searchPartnerClientList",
            parameters: parameters
        }
    }

    searchpartnerusersummaryPayload = (parameters: Types.SearchPartnerUserSummaryParams): Types.RequestBody => {
        return {
            module: "ibcommissioncportalmanagement",
            action: "searchpartnerusersummary",
            parameters: parameters
        }
    }

    getIBCountriesPayload = (): Types.RequestBody => {
        return {
            module: "ibcommissioncportalmanagement",
            action: "getCountries",
            parameters: {}
        }
    }

    getIBTradingPlatformsPayload = (): Types.RequestBody => {
        return {
            module: "ibcommissioncportalmanagement",
            action: "getTradingPlatforms",
            parameters: {}
        }
    }

    getIBAccountTradingTypesPayload = (): Types.RequestBody => {
        return {
            module: "ibcommissioncportalmanagement",
            action: "getAccountTradingTypes",
            parameters: {}
        }
    }

    searchClientAllAccountsPayload = (parameters: Types.SearchClientAllAccountsParams): Types.RequestBody => {
        return {
            module: "ibcommissioncportalmanagement",
            action: "searchClientAllAccounts",
            parameters: parameters
        }
    }

    searchClientAllAccounts = (parameters: Types.SearchClientAllAccountsParams) => {
        const params = this._createTFBOParams(this.searchClientAllAccountsPayload(parameters))

        return this._tfbo<Types.SearchClientAllAccountsResponse>(params)
    }

    searchClientSummaryPayload = (parameters: Types.SearchClientSummaryParams): Types.RequestBody => {
        return {
            module: "ibcommissioncportalmanagement",
            action: "searchClientSummary",
            parameters: parameters
        }
    }

    searchClientSummary = (parameters: Types.SearchClientSummaryParams) => {
        const params = this._createTFBOParams(this.searchClientSummaryPayload(parameters))

        return this._tfbo<Types.ClientSummaryResponse>(params)
    }

    searchClientTransactionSummaryPayload = (parameters: Types.SearchClientTransactionsParams): Types.RequestBody => {
        return {
            module: "ibcommissioncportalmanagement",
            action: "searchClientTransactionSummary",
            parameters: parameters
        }
    }

    searchClientTransactionSummary = (parameters: Types.SearchClientTransactionsParams) => {
        const params = this._createTFBOParams(this.searchClientTransactionSummaryPayload(parameters))

        return this._tfbo<Types.SearchClientTransactionsResponse>(params)
    }

    getCashbackType = () => {
        const params = this._createTFBOParams(
            {
                module: "ibcommissioncportalmanagement",
                action: "getcashbacktype",
                parameters: {}
            }
        )

        return this._tfbo<Types.CashBackTypeObject>(params)
    }

    updateCashback = (parameters: Types.UpdateCashbackParams) => {
        const params = this._createTFBOParams(
            {
                module: "ibcommissioncportalmanagement",
                action: "updateCashback",
                parameters: parameters
            }
        )

        return this._tfbo<{}>(params)
    }

    getPartnerCountrySettings = () => {
        const params = this._createTFBOParams(
            {
                module: "ibcommissioncportalmanagement",
                action: "getPartnerCountrySettings",
                parameters: {}
            }
        )

        return this._tfbo<IBTypes.PartnerCountrySettings[]>(params, Authorize.No)
    }

    isVietnamCalculationModel = (partnerId: number) => {
        const params = this._createTFBOParams(
            {
                module: "ibcommissioncportalmanagement",
                action: "isVietnamCalculationModel",
                parameters: { partnerId }
            }
        )

        return this._tfbo<boolean>(params)
    }


    getsublevelibs = (parameters: Types.GetSubLevelIbsParams) => {
        const params = this._createTFBOParams(this.getsublevelibsPayload(parameters))

        return this._tfbo<Types.SubLevelIB[]>(params)
    }

    getsublevelibsPayload = (parameters: Types.GetSubLevelIbsParams): Types.RequestBody => {
        return {
            module: "ibcommissioncportalmanagement",
            action: "getsublevelibs",
            parameters: parameters
        }
    }

    createIBApplication = (data: Partial<IBTypes.IBApplicationInit>) => {
        const params = this._createTFBOParams(
            {
                module: "UserPartnerApplicationController",
                action: "submitUserPartnerApplication",
                parameters: data
            }
        )
        return this._tfbo(params)
    }

    setIBApplicationStep = (applicationId: number, step: IBTypes.IBApplicationStep) => {
        const params = this._createTFBOParams(
            {
                module: "UserPartnerApplicationController",
                action: "setApplicationFormStep",
                parameters: {
                    applicationId,
                    formStep: step
                }
            }
        )
        return this._tfbo(params)
    }

    completeIBApplication = (userId: number) => {
        const params = this._createTFBOParams(
            {
                module: "UserPartnerApplicationController",
                action: "processApplicationAndCreateAccount",
                parameters: {
                    userId,
                    termsAndConditionConsent: "accepted"
                }
            }
        )
        return this._tfbo<IBTypes.IBAccountCreatedResponse>(params)
    }

    createDefaultPartner = (accountNumber: number, applicationId: number) => {
        const params = this._createTFBOParams(
            {
                module: "PartnerController",
                action: "createDefaultPartner",
                parameters: { accountNumber, applicationId }
            }
        )
        return this._tfbo<IBTypes.DefaultPartnerResponse>(params)
    }

    createPartnerLink = (data: {
        campId: number
        partnerId: number
        redirecturl: string
    }) => {
        const params = this._createTFBOParams(
            {
                module: "PartnerTrackingLinkController",
                action: "generateTrackingLink",
                parameters: data
            }
        )
        return this._tfbo<string>(params)
    }
    createRetailPartnerLink = (data: {
        cid: number
        pid: number
    }) => {
        const params = this._createTFBOParams(
            {
                module: "PartnerTrackingLinkController",
                action: "generateRetailTrackingLink",
                parameters: data
            }
        )
        return this._tfbo<string>(params)
    }

    getIBApplicationPayload = (): Types.RequestBody => {
        return {
            module: "UserPartnerApplicationController",
            action: "checkForApplication",
            parameters: {}
        }
    }

    getIBApplication = () => {
        const params = this._createTFBOParams(this.getIBApplicationPayload())
        return this._tfbo<IBTypes.IBApplication>(params)


    }
    updateIBApplicationStatus = (status: 'pendingKYC' | 'pendingReviw', applicationId: number) => {
        const params = this._createTFBOParams(
            {
                module: "UserPartnerApplicationController",
                action: status === 'pendingKYC' ? "updateStatusPendingKYC" : "updateStatusPendingReview",
                parameters: { applicationId }
            }
        )
        return this._tfbo(params)
    }

    getAllIBQuestions = () => {
        const params = this._createTFBOParams(
            {
                module: "PartnerApplicationQuestionnaireController",
                action: "getAllPartnerQuestions",
                parameters: {}
            }
        )
        return this._tfbo(params)
    }
    getIBQuestions = (orgId?: number) => {
        const params = this._createTFBOParams(
            {
                module: "PartnerApplicationQuestionnaireController",
                action: "getPartnerQuestions",
                parameters: { orgId }
            }
        )
        return this._tfbo<Types.Question[]>(params)
    }

    postIBAnswers = ({ organizationId, applicationId, answers }
        : {
            organizationId: number
            applicationId: number
            answers: Types.Question[]
        }) => {
        const params = this._createTFBOParams(
            {
                module: "PartnerApplicationQuestionnaireController",
                action: "savePartnerQuestionAnswersDetails",
                parameters: {
                    organizationId,
                    applicationId,
                    result: answers
                }
            }
        )
        return this._tfbo<Types.Question[]>(params)
    }

    postIBAddress = (data: IBTypes.IBAddress) => {
        const params = this._createTFBOParams(
            {
                module: "userpartneraddresscontroller",
                action: "registeruseraddress",
                parameters: data
            }
        )
        return this._tfbo(params)
    }

    postIBPhone = (data: IBTypes.IBContactPhone) => {
        const params = this._createTFBOParams(
            {
                module: "UserPartnerAddressController",
                action: "registerOrUpdateUserPhone",
                parameters: data
            }
        )
        return this._tfbo(params)
    }

    claimIBDocument = (data: Types.IBClaimParams) => {
        const params = this._createTFBOParams(
            {
                module: "PartnerApplicationDocumentController",
                action: "submitPartnerDocuments",
                parameters: data
            }
        )
        return this._tfbo(params)
    }

    getIBFiles = (data: Partial<{
        userId: number
        applicationId: number
    }>) => {
        const params = this._createTFBOParams(
            {
                module: "PartnerApplicationDocumentController",
                action: "findUserDocuments",
                parameters: data
            }
        )
        return this._tfbo<any>(params)
    }

    getIBAnswers = (applicationId?: number) => {
        const params = this._createTFBOParams(
            {
                module: "PartnerApplicationQuestionnaireController",
                action: "getpartnerquestionanswerdetails",
                parameters: {
                    applicationId
                }
            }
        )
        return this._tfbo<Types.Question[]>(params)
    }
    getAvailableInvoicesPayload = (parameters: Types.GetWithUserIDParams): Types.RequestBody => {
        return {
            module: "ibcommissioncportalmanagement",
            action: "getAvailableInvoices",
            parameters: parameters
        }
    }

    getInvoicePayoutHistoryPayload = (parameters: Types.GetWithUserIDParams): Types.RequestBody => {
        return {
            module: "ibcommissioncportalmanagement",
            action: "getInvoicePayoutHistory",
            parameters: parameters
        }
    }

    getAvailableForWithdrawalPayload = (parameters: Types.GetWithUserIDParams): Types.RequestBody => {
        return {
            module: "ibcommissioncportalmanagement",
            action: "getAvailableForWithdrawal",
            parameters: parameters
        }
    }

    getAwaitingPaymentPayload = (parameters: Types.GetWithUserIDParams): Types.RequestBody => {
        return {
            module: "ibcommissioncportalmanagement",
            action: "getAwaitingPayment",
            parameters: parameters
        }
    }

    getTotalCommissionsPayload = (parameters: Types.GetWithUserIDParams): Types.RequestBody => {
        return {
            module: "ibcommissioncportalmanagement",
            action: "getTotalCommissions",
            parameters: parameters
        }
    }

    requestInvoicesPayment = (parameters: Types.RequestInvoicesPaymentParams) => {
        const params = this._createTFBOParams(
            {
                module: "ibcommissioncportalmanagement",
                action: "requestInvoicesPayment",
                parameters: parameters
            }
        )

        return this._tfbo<{}>(params)
    }

    getUserPartnerApplication = (parameters: Types.GetWithUserIDParams) => {
        const params = this._createTFBOParams(
            {
                module: "ibcommissioncportalmanagement",
                action: "getUserPartnerApplication",
                parameters: parameters
            }
        )

        return this._tfbo<IBTypes.UserPartnerApplication>(params)
    }

    getPerformanceSummary = (parameters: Types.GetPerformanceSummaryParams) => {
        const params = this._createTFBOParams(
            {
                module: "ibcommissioncportalmanagement",
                action: "getPerformanceSummary",
                parameters: parameters
            }
        )

        return this._tfbo<IBTypes.PerformanceReport>(params)
    }

    searchPartnerAllTradingAccounts = (parameters: Types.SearchPartnerAllTradingAccountsParams): Types.RequestBody => {
        return {
            module: "ibcommissioncportalmanagement",
            action: "searchPartnerAllTradingAccounts",
            parameters: parameters
        }
    }

    getTraderTransactionReport = (parameters: IBTypes.GetTraderTransactionReportParams): Types.RequestBody => {
        return {
            module: "ibcommissioncportalmanagement",
            action: "getTraderTransactionReport",
            parameters: parameters
        }
    }

    searchPartnerTradingAccountsSummary = (parameters: Types.SearchPartnerTradingAccountsSummaryParams): Types.RequestBody => {
        return {
            module: "ibcommissioncportalmanagement",
            action: "searchPartnerTradingAccountsSummary",
            parameters: parameters
        }
    }

    downloadIBInvoice<T>(invoiceID: string) {
        const request = () => {
            return this._request<Response & T>(
                `${appConfig.IB_INVOICE_URL}?sid=${this.accessToken}&id=${invoiceID}`,
                'GET',
                Authorize.Yes,
                false
            )
        }

        return this.mutex.runExclusive(async () => {
            const response = await request()
            if (response.status === 400) {
                if (await this.UpdateTokens())
                    return request()
                else {
                    window.dispatchEvent(new Event("TokenExpired"))
                    return Promise.resolve(response)
                }
            }
            else return Promise.resolve(response)
        })
    }

}


let api = new Api()

export default api