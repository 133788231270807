import cn from 'classnames'
import React, { FC, useEffect, useState } from 'react'
import { useHistory } from 'react-router-dom'
import { AppState } from '../../redux/appState'
import { useShortTranslation } from '../../utils/hooks'
import appRoutes from "../../utils/routes"
import Modal from '../Presentational/Modal/index'
import image from './image.png'
import styles from './style.module.scss'


interface VPSModalProps {
}
const VPSModal: FC<VPSModalProps> = (props) => {
    const [show, setshow] = useState(false)
    const history = useHistory()
    const tt = useShortTranslation('dashboard.vps.modal.')
    const close = () => {
        setshow(false)
    }

    useEffect(() => {
        const timer = setTimeout(() => setshow(true), 1000)
        return () => {
            clearTimeout(timer)
        }
    }, [])

    return <Modal
        isOpen={show}
        onModalClose={close}
    >
        <div className={cn("page-content", styles.container)}>
            <div className="column">
                <div className="title">
                    {tt('title')}
                </div>
                <img src={image} alt="" />
                <div className="bottom">
                    <button className={"green"}
                        onClick={() => {
                            setshow(false)
                            history.push(appRoutes.dashboard.tools.vps)
                        }}>
                        {tt('button')}
                    </button>
                </div>
            </div>
        </div>
    </Modal>

}

export const needShowVPSModal = (store: AppState) => {
    //TO DO
    return false
}



export default VPSModal