import creditCard from './credit-debit-card.png'
import xpayqr from './xpay-qr.png'
import latam from './latam.png'
import ozow from './ozow.png'
import bankWire from './bankWire.svg'
import neteller from './neteller.svg'
import unionPay from './china-unionpay.svg'
import skrill from './skrill.svg'
import internationalBankWire from './ibt.svg'
import localBankTransfer from './lbt.svg'
import webmoney from './webmoney.svg'
import paymentasia from './paymentasia.svg'
import paypal from './paypal.svg'
import bitpay from './bitpay.svg'
import bpay from './bpay.png'
import cardpay from '../basic-credit-card.svg'
import poli from './poli.svg'
import fasapay from './fasapay.svg'
import astropay from './astropay.svg'
import sofort from './sofort.svg'
import praxis from './praxis-logo.svg'
import crypto from './crypto.svg'
import googlePay from './google-pay.svg'
import applePay from './apple-pay-logo.webp'

// dakr icons
import bitpayDark from './bitpay-dark.svg'
import skrillDark from './skrill-dark.svg'
import creditCardDark from './credit-debit-card-dark.svg'
import unionPayDark from './china-unionpay-dark.svg'
import poliDark from './poli-dark.svg'
import paypalDark from './paypal-dark.jpeg'
import localBankTransferDark from './lbt-dark.svg'
import internationalBankWireDark from './ibt-dark.svg'

import { useThemes } from '../../../utils/themes'

const useProcessorImages = () => {
    const { theme } = useThemes()
    const baseIcons = {
        creditCard,
        xpayqr,
        latam,
        ozow,
        bankWire,
        neteller,
        unionPay,
        skrill,
        internationalBankWire,
        localBankTransfer,
        webmoney,
        paymentasia,
        paypal,
        bitpay,
        bpay,
        cardpay,
        poli,
        fasapay,
        astropay,
        sofort,
        praxis,
        crypto,
        googlePay,
        applePay,
    }

    if (theme === 'dark') {
        return {
            ...baseIcons,
            skrill: skrillDark,
            bitpay: bitpayDark,
            creditCard: creditCardDark,
            unionPay: unionPayDark,
            poli: poliDark,
            paypal: paypalDark,
            internationalBankWire: internationalBankWireDark,
            localBankTransfer: localBankTransferDark,
        }
    }

    return baseIcons
}

export { useProcessorImages }