import React, { memo, useMemo } from 'react'
import { useTranslation } from 'react-i18next'
import { useAccounts, useAppState, useDangerousHTML, useUserApplications } from '../../utils/hooks'
import { Country } from '../../utils/types'
import Utils from '../../utils/utils'
import HttpLink from '../HttpLink'


interface IndividualAUProps {
    userCountry?: Country
}

const IndividualAU = memo(({ userCountry }: IndividualAUProps) => {
    const { t } = useTranslation()
    const prefix = 'footer:individual.AU'
    const isAustraliaCountry = Utils.checkIfCountryIsAustralia(userCountry)
    const { tradingAccountToCreate: accountToCreate } = useAppState()
    const { isDemo } = useAccounts()
    const { hasCFDApps } = useUserApplications()
    const { p } = useDangerousHTML()

    const showTargetMarketLink = useMemo(() =>
        isDemo || hasCFDApps || (accountToCreate === 'CFD')
        , [accountToCreate, hasCFDApps, isDemo])

    return <>
        {p(t(`${prefix}.advice`))}
        <p>
            <strong>{t(`${prefix}.warning.header`)} </strong>
            {t(`${prefix}.warning.text1`)}&nbsp;
            <HttpLink url={t(`${prefix}.warning.links.productDisclosure.link`)}>
                {t(`${prefix}.warning.links.productDisclosure.text`)}
            </HttpLink>
            {showTargetMarketLink === true && <>,&nbsp;
            <HttpLink url={t(`${prefix}.warning.links.targetMarketDetermination.link`)}>
                    {t(`${prefix}.warning.links.targetMarketDetermination.text`)}
                </HttpLink>
            </>}
            &nbsp;{t(`${prefix}.warning.text2`)}&nbsp;
            <HttpLink url={t(`${prefix}.warning.links.financialServicesGuide.link`)}>
                {t(`${prefix}.warning.links.financialServicesGuide.text`)}
            </HttpLink>
            &nbsp;{t(`${prefix}.warning.text3`)}
        </p>
        {isAustraliaCountry && <p>{t(`${prefix}.warning.text4`)}</p>}
        <p>
            <strong>{t(`${prefix}.company.header`)} </strong>
            {t(`${prefix}.company.text1`)}
        </p>
        {p(t(`${prefix}.company.text3`))}
        <p>
            {t(`${prefix}.company.text2`)}
        </p>
        <p> {t(`${prefix}.text`)}</p>
    </>
})

export { IndividualAU }