import { FC, useMemo } from 'react'
import { useSelector } from 'react-redux'
import { useHistory } from "react-router-dom"
import cn from 'classnames'

import { Store } from '../../redux/store'
import { useScreen } from '../../utils/hooks'

import styles from './style.module.scss'

export type NavigationLink = {
    active: boolean
    name: string
    link: string
}

export type NavigationButton = {
    caption: string | JSX.Element
    active: boolean
    value: any
    onClick: (value: any) => void
}

const TopNavigation: FC = () => {
    const history = useHistory()
    const { links, buttons } = useSelector((state: Store) => state.UI.Dashboard)
    const { isMobile } = useScreen()

    const linkItems = useMemo(() => {
        return links
            ? links.map((l, i) => (
                <div key={i} className={cn({
                    [styles.active]: l.active,
                    [styles.mobile]: isMobile
                })}

                    onClick={() => history.push(l.link)}>
                    {l.name}
                </div>
            ))
            : null
    }, [history, isMobile, links])

    const buttonItems = useMemo(() => {
        return buttons
            ? buttons.map((l, i) => (
                <div key={i} className={cn({
                    [styles.active]: l.active,
                    [styles.mobile]: isMobile
                })}
                    onClick={e => l.onClick(l.value)}>
                    {l.caption}
                </div>
            )) : null
    }, [buttons, isMobile])

    return <div className={styles.navigation}>
        <div className={styles.inner}>
            {linkItems}
            {buttonItems}
        </div>
    </div>
}


export default TopNavigation