import { UAParser } from 'ua-parser-js'

const browser = new UAParser().getBrowser()
const ie11 = browser.name === "IE" && browser.version?.startsWith("11")
const isAndroid = /android/i.test(navigator.userAgent)
const isIOS = [
    'iPad Simulator',
    'iPhone Simulator',
    'iPod Simulator',
    'iPad',
    'iPhone',
    'iPod'
].includes(navigator.platform)
    // iPad on iOS 13 detection
    || (navigator.userAgent.includes("Mac") && "ontouchend" in document)
const mobileDevice = isAndroid || isIOS

export { browser, ie11, mobileDevice, isAndroid, isIOS }