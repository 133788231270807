import cn from 'classnames'
import React, { useMemo } from 'react'
import { useTranslation } from 'react-i18next'
import { Link, useHistory } from "react-router-dom"
import { useShouldShowIBportal } from '../../ib/utils/hooks'
import { useAccounts, useAppState, useScreen } from '../../utils/hooks'
import { default as appRoutes, default as routes } from '../../utils/routes'
import SVG from '../Presentational/SVG'
import styles from './header.module.scss'
import Notifications from './subHeaders/Notifications'



const Header = () => {
    const { hasCompletedApp, appStatus, pageTitle, redirectedFromWTR } = useAppState()
    const { isDemo, availabeForDeposit } = useAccounts()
    const { t } = useTranslation()
    const history = useHistory()
    const { isDesktop } = useScreen()
    const shouldShowIBportal = useShouldShowIBportal()

    const shouldAllowProfile = isDemo ||
        hasCompletedApp ||
        appStatus !== 'INCOMPLETE'

    const showDepositButton = useMemo(() => {
        return isDesktop && availabeForDeposit.length > 0
    }, [availabeForDeposit.length, isDesktop])

    const showIBButton = useMemo(() => {
        return isDesktop && shouldShowIBportal
    }, [isDesktop, shouldShowIBportal])

    const userMenu = useMemo(() =>
        <div className={cn(styles['user-menu'], { [styles.disabled]: !shouldAllowProfile })}>
            <SVG name='user' />
        </div>
        , [shouldAllowProfile])

    return (
        <div className={styles.header}>

            <div className={styles.inner}>
                <div className={styles.content}>
                    <div className={styles.left}>
                        {pageTitle && <div className={styles['page-title']}>
                            {t(pageTitle)}
                        </div>}
                    </div>
                    <div className={cn(styles.right, { [styles.wtr]: redirectedFromWTR })}>
                        {showIBButton && <button
                            className={cn("gray-outline", styles.ibButton)}
                            onClick={() => history.push(routes.ib.dashboard)}
                        >
                            <SVG name="ib" />
                            {t('common:ibPortal')}
                        </button>}
                        {showDepositButton && <button
                            className='green'
                            onClick={() => history.push(routes.dashboard.funds.deposit)}
                        >
                            {t('account:accountCreated.deposit.caption')}
                        </button>}
                        {hasCompletedApp && <Notifications />}
                        {shouldAllowProfile
                            ? (
                                <Link className={styles.link} to={appRoutes.profile.root}>
                                    {userMenu}
                                </Link>
                            ) : userMenu
                        }
                    </div>
                </div>
            </div>
        </div>
    )
}

export default Header