export const showGlobalLoader = () => {
    const loader = document.getElementById("loader")
    loader!.style.visibility = 'visible'
}

export const hideGlobalLoader = () => {
    const loader = document.getElementById("loader")
    loader!.style.visibility = 'hidden'
}

