import cn from 'classnames'
import React, { FC } from "react"
import styles from "./style.module.scss"

type CollapseProps = {
    open?: boolean | null
    children: any
}

const Collapse: FC<CollapseProps> = ({ open = false, children }) => {
    return (
        <div className={cn(styles.collapse, { [styles.open]: open })}>
            {children}
        </div>
    )
}


export default Collapse
