import cn from 'classnames'
import React, { FC, useCallback, useEffect, useState } from 'react'
import { useDispatch } from 'react-redux'
import { useHistory } from 'react-router'
import { useScrollToTop, useAppState, } from '../../../../utils/hooks'
import appRoutes from '../../../../utils/routes'
import Utils from '../../../../utils/utils'
import Loader from '../../../Loader'
import { startPullAppStatus } from '../../../../redux/actions'
import { GreenID } from '../../../../utils/types'
import api from '../../../../utils/api'
import ErrorModal from "../../../Presentational/ErrorModal"
import { useTranslation } from 'react-i18next'
import './style.scss'
interface GreenIdProps {
    applicationID: number
    isMobile?: boolean
}

const GreenId: FC<GreenIdProps> = ({ applicationID, isMobile }) => {
    const dispatch = useDispatch()
    const history = useHistory()
    const [loading, setLoading] = useState(true)
    const [scriptLoaded, setScriptLoaded] = useState<boolean>(false)
    const [configLoaded, setConfigLoaded] = useState<boolean>(false)
    const [error, setError] = useState(false)
    const [initializeInfo, setInitializeInfo] = useState<GreenID>()
    const isTestEnv = Utils.isTestEnv();
    const { redirectedFromWTR } = useAppState()
    const { t } = useTranslation()
    useScrollToTop()

    const sessionUploadLater = useCallback(async () => {
        await api.getGreenIDUIAbortCallback(applicationID)
        return history.push(appRoutes.account.verify.root)
    }, [applicationID, history])

    const sessionComplete = useCallback(async () => {
        setLoading(true)
        if (applicationID) {
            api.getGreenIDUISubmitCallBack(applicationID).then(
                async () => {
                    const response = await api.checkApplicationStatuses()
                    if (response.payload[0].status === 'OK') {
                        const { application_status, client_boarded_green_id, green_id_status } = response.payload[0].result[0]
                        if (green_id_status === "LOCKED_OUT" || (application_status === "PENDING_ID_ADDRESS" && client_boarded_green_id))
                            return history.push(appRoutes.account.verify.root)
                    }
                    if (redirectedFromWTR) {
                        Utils.postMessageToWTR({ message: 'tradePage' })
                    }
                    dispatch(startPullAppStatus())
                    return isMobile && history.push(appRoutes.dashboard.accounts.root)
                }
            )
        }
    }, [applicationID, dispatch, history, isMobile, redirectedFromWTR])

    useEffect(() => {
        const getData = async (applicationID: number) => {
            const response = await api.getGreenIDUIShow(applicationID)
            const greenIdResponse = response.payload[0].result
            if (greenIdResponse.message) {
                setError(true)
            } else {
                setInitializeInfo(greenIdResponse)
            }
        }
        if (applicationID) {
            getData(applicationID)
        }
    }, [applicationID])

    useEffect(() => {
        const greenIdConfigLink = isTestEnv
            ? "https://simpleui-test-au.vixverify.com/df/javascripts/greenidConfig.js"
            : "https://simpleui-au.vixverify.com/df/javascripts/greenidConfig.js"
        const greeIdLink = isTestEnv
            ? "https://simpleui-test-au.vixverify.com/df/javascripts/greenidui.min.js"
            : "https://simpleui-au.vixverify.com/df/javascripts/greenidui.min.js"
        Utils.loadScript(greenIdConfigLink, () => {
            setConfigLoaded(true)
        })
        Utils.loadScript(greeIdLink, () => {
            setScriptLoaded(true)
        })
    }, [isTestEnv])

    useEffect(() => {
        if (scriptLoaded && configLoaded && initializeInfo) {
            window.greenidConfig.setOverrides({ "enable_save_and_complete_later": false });
            window.greenidUI?.setup({
                enableCancelButton: true,
                environment: isTestEnv ? "test" : "prod",
                frameId: "greenid-div",
                errorCallback: () => { },
                sessionCompleteCallback: sessionComplete,
                sourceAttemptCallback: () => { },
                sessionCancelledCallback: sessionUploadLater,
                preSubmitValidationCallback: () => { },
            });
            window.greenidUI?.show(initializeInfo.accountID, initializeInfo.apiCode, initializeInfo.token);
            setLoading(false)
        }
    }, [dispatch, history, initializeInfo, isTestEnv, scriptLoaded, configLoaded, sessionComplete, sessionUploadLater])

    const onError = useCallback(() => {
        setError(false)
    }, [])

    const mobileStyle = {
        height: `${window.innerHeight}px`,
        overflow: 'scroll',
        paddingRight: '10px'
    }

    return <div className={cn('page-card')}>
        {loading && <Loader />}
        <link rel="stylesheet" type="text/css" href={isTestEnv ? "https://simpleui-test-au.vixverify.com/df/assets/stylesheets/greenid.css" : "https://simpleui-au.vixverify.com/df/assets/stylesheets/greenid.css"} />
        <div id='greenid-div' style={isMobile ? mobileStyle : undefined}></div>
        {error && <ErrorModal
            onClose={onError}
            text={t("account:greenID.errorMessage")}
        >
            <div
                dangerouslySetInnerHTML={{ __html: t("account:greenID.text") }} />
            <div >
                <button
                    className="green"
                    onClick={onError}
                >
                    {t('common:ok')}
                </button>
            </div>
        </ErrorModal>}
    </div>
}


export default GreenId