import React, { FocusEvent, memo, useEffect, useMemo, useState } from 'react'
import { useSelector } from 'react-redux'
import cn from 'classnames'
import ReactSelect, { components, Props, StylesConfig, ValueContainerProps } from 'react-select'
import AsyncSelect from 'react-select/async'
import { SelectComponents } from 'react-select/src/components'
import { OptionProps } from "react-select/src/components/Option"
import { ActionMeta, MenuPlacement, OptionsType, OptionTypeBase } from 'react-select/src/types'

import { Store } from '../../../redux/store'
import { getSelectStyles } from '../../../utils/getSelectStyles'
import Wrapper from '../ItemWrapper'
import { Menu, MenuList } from '../SelectMenuList'
import SVG from '../SVG'

import styles from './style.module.scss'

export type SelectProps<OptionType extends OptionTypeBase = { label: string; value: string }> = {
    className?: string
    wrapperClassName?: string
    mainAreaClassName?: string
    height?: number
    name: string
    value?: any
    options: any[]
    components?: Partial<SelectComponents<any>>
    nameField?: string
    valueField?: string
    placeholder?: string
    caption?: React.ReactNode
    disabled?: boolean
    isSearchable?: boolean
    menuIsOpen?: boolean
    openMenuOnFocus?: boolean
    openMenuOnClick?: boolean
    showError?: boolean
    validation?: boolean | string
    validate?: boolean
    optional?: boolean
    readOnly?: boolean
    menuSeparator?: boolean
    isMulti?: boolean
    blurInputOnSelect?: boolean
    validationError?: string
    menuPlacement?: MenuPlacement
    getOptionValue?(option: any): string
    getOptionLabel?(option: any): string
    onSelect: (name: string, value: any) => void
    onBlur?: (name: string, value: any, e: FocusEvent<HTMLElement>) => void
    upgradeSelectStyles?: (baseStyles: StylesConfig, rtl: boolean) => StylesConfig
    // Async Select options
    useAsyncSelect?: boolean
    loadOptions?: (inputValue: string, callback: ((options: OptionsType<OptionType>) => void)) => Promise<any> | void
}

const Option = (props: OptionProps<any>) => {
    const { label, selectProps: { inputValue, isSearchable = true } } = props
    let highlighted: string
    try {
        highlighted = inputValue && isSearchable ? label.replace(
            new RegExp(inputValue, 'gi'),
            highlighted => `<span class=${styles.highlight}>${highlighted}</span>`
        ) : label
    }
    catch {
        highlighted = label
    }
    return (
        <components.Option {...props}>
            <span dangerouslySetInnerHTML={{ __html: highlighted }} />
        </components.Option>
    )
}

const ValueContainer = (props: ValueContainerProps<any>) => {
    
    return (
        <components.ValueContainer {...props}>
            {(props.selectProps.isSearchable && !props.hasValue) && (
                <div className={styles["search-icon"]}>
                    <SVG name='search' />
                </div>
            )}
            <div
                data-di-mask
                data-dd-privacy="mask"
                className={cn(styles.value, props.isMulti ? styles.multiValue : undefined)}
            >
                {props.children}
            </div>
        </components.ValueContainer>
    )
}

const Select = React.forwardRef<HTMLDivElement, SelectProps>(
    (props, ref) => {
        const [error, seterror] = useState(false)
        const {
            showError = true,
            upgradeSelectStyles, height, value, validation, menuIsOpen, loadOptions,
            openMenuOnClick = true, openMenuOnFocus = true, useAsyncSelect = false, isMulti = false,
            getOptionLabel, menuPlacement = 'auto', readOnly, options, menuSeparator = true
        } = props

        const store = useSelector((state: Store) => state.App)

        const singleOption = useMemo(() => {
            if (!value) return false
            return options.length === 1 && value.value === options[0].value
        }, [options, value])

        const selectStyles = useMemo(() => {
            const styles = getSelectStyles({
                height: height,
                error: error,
                validation: validation,
                value: value,
                rtl: store.rtl,
                readOnly: readOnly,
            })
            return upgradeSelectStyles ? upgradeSelectStyles(styles, store.rtl) : styles
        }, [height, error, validation, value, store.rtl, readOnly, upgradeSelectStyles])

        const handleChange = (newValue: any, _: ActionMeta) => {
            seterror(false)
            if (props.onSelect)
                props.onSelect(props.name, newValue)
        }

        const handleBlur = (e: FocusEvent<HTMLElement>) => {
            const { value, onBlur, name } = props
            if (onBlur) onBlur(name, value, e)
        }

        useEffect(() => {
            //clear incorrect value
            if (value && !options.find(o => o.value === value.value))
                if (props.onSelect) {
                    props.onSelect(props.name, null)
                }
            // eslint-disable-next-line react-hooks/exhaustive-deps
        }, [])

        useEffect(() => {
            if (!props.optional) {
                const result = !!props.value
                if (props.validate)
                    seterror(!result)
            }
        }, [props])

        const { nameField = 'name', valueField = 'value', getOptionValue } = props
        const wrapperError = props.validation ? props.validation :
            error ? props.validationError : false

        let components: Partial<SelectComponents<any>> = {
            Option: Option,
            MenuList: MenuList,
            ValueContainer: ValueContainer,
            Menu: Menu
        }
        if (props.components)
            components = { ...components, ...props.components }

        const baseSelectProps: Props = {
            styles: selectStyles,
            value: props.value,
            inputId: props.name,
            components: components,
            getOptionLabel: getOptionLabel ? getOptionLabel : (option: any) => option[nameField],
            getOptionValue: getOptionValue ? getOptionValue : (option: any) => option[valueField],
            onChange: handleChange,
            onBlur: handleBlur,
            isSearchable: props.isSearchable,
            isMulti: isMulti,
            menuIsOpen: menuIsOpen,
            placeholder: props.placeholder,
            isDisabled: props.disabled || readOnly || singleOption,
            openMenuOnFocus: openMenuOnFocus,
            openMenuOnClick: openMenuOnClick,
            blurInputOnSelect: props.blurInputOnSelect,
            menuPlacement: menuPlacement,
            escapeClearsValue: true,
            menuSeparator: menuSeparator
        }


        return (
            <Wrapper
                name={props.name}
                error={wrapperError}
                ref={ref}
                showError={showError}
                className={props.wrapperClassName}
                mainAreaClassName={props.mainAreaClassName}
                captionClassName={styles.caption}
                insideCaption={false}
                caption={props.caption}
            >
                <div className={styles.field}>
                    {useAsyncSelect && loadOptions ? (
                        <AsyncSelect
                            {...baseSelectProps}
                            loadOptions={loadOptions}
                            loadingMessage={() => null}
                            noOptionsMessage={() => null}
                        />
                    ) : (
                        <ReactSelect
                            {...baseSelectProps}
                            options={props.options}
                        />
                    )}

                </div>
            </Wrapper>
        )
    })

export default memo(Select)