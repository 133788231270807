import { useCallback, useMemo, useState } from "react"
import { useSelector } from "react-redux"
import { useHistory, useLocation } from "react-router-dom"
import { Store } from "../../redux/store"
import { mobileDevice } from "../../utils/constants/browser"
import { ScreenSize } from "../../utils/enums"
import { useAppState } from "../../utils/hooks"
import r from "../../utils/routes"

export const useIBState = () => {
    return useSelector((state: Store) => state.IB)
}

export function usePagination<T>(data: T[], pageSize: number, total?: number) {
    const [currentPage, setCurrentPage] = useState(1)
    const [itemsPerPage, setItemsPerPage] = useState<number>(pageSize)
    const lenght = total ? total : data.length
    const maxPage = Math.ceil(lenght / itemsPerPage)

    const currentData = useMemo(() => {
        const begin = (currentPage - 1) * itemsPerPage
        const end = begin + itemsPerPage
        return data.slice(begin, end)
    }, [currentPage, data, itemsPerPage])

    const next = useCallback(() => {
        setCurrentPage((currentPage) => Math.min(currentPage + 1, maxPage))
    }, [maxPage])

    const prev = useCallback(() => {
        setCurrentPage((currentPage) => Math.max(currentPage - 1, 1))
    }, [])

    const jump = useCallback((page: number) => {
        const pageNumber = Math.max(1, page)
        const newPage = Math.min(pageNumber, maxPage)
        setCurrentPage(() => Math.max(1, newPage))
    }, [maxPage])

    const setPerPageNumber = useCallback((amount: number | null) => {
        const value = amount === null ? lenght : amount
        setItemsPerPage(value)
        setCurrentPage(1)
    }, [lenght])

    return { next, prev, jump, setPerPageNumber, setCurrentPage, currentPage, maxPage, itemsPerPage, data, currentData }
}

export const useIBScreen = () => {
    const { screen } = useIBState()
    const notDesktop = useMemo(() => mobileDevice || screen !== ScreenSize.Desktop, [screen])
    const isDesktop = useMemo(() => !mobileDevice && screen === ScreenSize.Desktop, [screen])
    const isMobile = useMemo(() => mobileDevice || screen === ScreenSize.Mobile, [screen])
    const isTablet = useMemo(() => !mobileDevice && screen === ScreenSize.Tablet, [screen])

    return {
        notDesktop,
        isDesktop,
        isMobile,
        isTablet
    }
}

export const useShouldShowIBportal = () => {
    const { application: ibApplication } = useIBState()
    const { liveAccounts } = useAppState()
    const hasComissionAcc = liveAccounts.some(x => x.platformAccountType === 'Commission')
    return (hasComissionAcc || !!ibApplication)
}

export const useBackButton = () => {
    const { userInsidePortal } = useAppState()
    const history = useHistory()
    const location = useLocation()

    const goBack = useCallback(() => {
        switch (location.pathname) {
            case r.account.ib.q2:
                history.push(r.account.ib.q1)
                break
            case r.account.ib.legal:
                history.push(r.account.ib.q2)
                break
            case r.account.ib.verify.address:
                history.push(r.account.ib.verify.id)
                break
            case r.account.ib.verify.id:
                history.push(r.account.ib.address)
                break
            case r.account.ib.address:
                if (userInsidePortal) history.push(r.ib.dashboard)
                break
            default:
        }
    }, [history, location, userInsidePortal])

    return goBack
}
