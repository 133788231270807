import { useCallback } from 'react'
import { useTranslation } from 'react-i18next'
import Utils from './utils'

export const useCurrencyName = (delimiter: string = ' | ') => {
    const { t, i18n } = useTranslation()

    const getCurrencyDescription = useCallback((currency: string) => {
        return i18n.exists(`common:currencies.${currency}`)
            ? `${currency}${delimiter}` + t(`common:currencies.${currency}`)
            : (Utils.getCurrencySign(currency) || currency)
    }, [i18n, t, delimiter])

    return getCurrencyDescription
}