import cn from "classnames"
import { Link } from 'react-router-dom'

import { MenuItem, useMatchUrlUtils, useMenuEntryCaption, useRetrieveIcon } from "../utils"

import styles from "./menu-item.module.scss"

export interface MenuItemProps<T> {
    languagePrefix: string
    item: MenuItem<T>
    parent: string
    onMenuItemClick: () => void
}

const SideBarMenuItem = <T extends string>(props: MenuItemProps<T>) => {
    const { languagePrefix, onMenuItemClick, item, parent } = props
    const { checkForMatchUrl } = useMatchUrlUtils()
    const retrieveIcon = useRetrieveIcon(item)
    const caption = useMenuEntryCaption(languagePrefix, item, false, parent)
    const isActive = checkForMatchUrl(item)
    const icon = retrieveIcon(isActive, item.disabled)

    const classes = cn(
        parent ? styles.nestedLink : styles.mainLink,
        styles.link,
        {
            [styles.active]: isActive,
            [styles.disabled]: item.disabled,
            [styles.keepAppearance]: item.keepActiveAppearance,
            [styles.withIcon]: !!icon,
        })

    const renderLinkContent = () => {
        return (
            <div className={classes} onClick={onMenuItemClick}>
                <div className={styles.caption}>
                    {icon && (
                        <div className={styles.icon}>
                            <img src={icon} alt={`icon for ${item.name}`} />
                        </div>
                    )}
                    <span>{caption}</span>
                </div>
                <div></div>
            </div>
        )
    }

    if (item.disabled || !item.link) return renderLinkContent()

    return (
        <Link to={item.link}>
            {renderLinkContent()}
        </Link>
    )
}

export default SideBarMenuItem
