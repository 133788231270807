import { FC, useEffect, useState } from 'react'
import queryString from "query-string"
import { useDispatch } from 'react-redux'
import { useLocation } from 'react-router'

import api from '../../../../../utils/api'
import { hideGlobalLoader } from '../../../../GlobalLoader'
import { getUser } from '../../../../../redux/actions'
import { useAppState } from '../../../../../utils/hooks'
import { setTwoFa } from '../../../../../redux/slices/app'

import SumSub from '.'

const SumSubPage: FC = () => {
    const location = useLocation()
    const [applicationID, setApplicationID] = useState<number | null>(null)
    const query = queryString.parse(location.search)
    const dispatch = useDispatch()
    const { userProfile } = useAppState()

    useEffect(() => {
        dispatch(setTwoFa({ checked: true, enabled: false, valid: true }))
        hideGlobalLoader()
    }, [dispatch])

    useEffect(() => {
        const session = typeof query.session === 'string' ? query.session : null
        const token = typeof query.token === 'string' ? query.token : null
        const applicationID = typeof query.applicationID === 'string' ? +query.applicationID : null

        if (session && token && applicationID) {
            api.setTFBOAuth(session, token)
            setApplicationID(applicationID)
            setTimeout(() => {
                dispatch(getUser())
            }, 1000)
        }
    }, [query.session, query.token, query.applicationID, dispatch])

    return applicationID && userProfile ? (
        <SumSub applicationID={applicationID} userProfile={userProfile} isMobile />
    ) : null
}


export default SumSubPage