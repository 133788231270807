import cn from 'classnames'
import React, { ChangeEvent, FocusEvent, useCallback, useMemo, useRef, useState } from 'react'
import { useSelector } from 'react-redux'
import ReactTooltip from 'react-tooltip'
import { Store } from "../../../redux/store"
import { ie11 } from '../../../utils/constants/browser'
import SVG from '../SVG'
import styles from './style.module.scss'

export type InputType = 'password' | 'email' | 'integer' | 'float' | 'text' | 'id'

export type InternalInputProps = React.InputHTMLAttributes<HTMLInputElement> & {
    value: string
    label?: string
    disabled?: boolean
    optional?: boolean
    showPasswordEye?: boolean
    ltr?: boolean
    type: InputType
    isSucceeded: boolean
    hasError: boolean
    hint?: boolean | string
    isFloatedLabel?: boolean
}

const InternalInput = React.forwardRef<HTMLInputElement, InternalInputProps>(
    (props, ref) => {
        InternalInput.displayName = "InternalInput"
        const { isFloatedLabel = true, showPasswordEye = false, inputMode = "text" } = props
        const [password, setPassword] = useState(props.type === 'password')
        const hintRef = useRef<HTMLDivElement>(null)

        const { autoFocus,
            autoComplete = props.type === 'password' ? "new-password" : "off" } = props

        const app = useSelector((state: Store) => state.App)
        const { rtl } = app

        const onChange = (e: ChangeEvent<HTMLInputElement>) => {
            props.onChange && props.onChange(e)
        }

        const handleOnFocus = useCallback((e: FocusEvent<HTMLInputElement>) => {
            if (props.onFocus) props.onFocus(e)
        }, [props])

        const { onBlur } = props

        const handleOnBlur = useCallback((e: FocusEvent<HTMLInputElement>) => {
            if (onBlur) onBlur(e)
        }, [onBlur])

        const ltrInput = useMemo(() => {
            return props.ltr !== undefined ?
                props.ltr
                : props.type && (props.type === 'email' ||
                    props.type === 'password' ||
                    props.type === 'id')
        }, [props.ltr, props.type])

        const className = cn(
            styles.input,
            props.className,
            {
                [styles.success]: !props.disabled && props.isSucceeded,
                [styles.error]: !props.disabled && props.hasError,
                [styles.floatedInput]: isFloatedLabel,
                [styles.stickedInput]: !isFloatedLabel,
                [styles.ltr]: ltrInput,
                [styles.password]: props.type === 'password'
            }
        )

        const tooltipPlace = useCallback(() => {
            const w = document.documentElement.clientWidth
            const level = 1200
            if (rtl) return w > level ? 'left' : 'right'
            else return w > level ? 'right' : 'left'
            // eslint-disable-next-line react-hooks/exhaustive-deps
        }, [rtl])

        const placeholder = ie11 && props.disabled ? ''
            : props.placeholder
                ? props.placeholder
                : ' '

        const id = props.id ? props.id : props.name

        return (
            <div className={styles['input-field']}>
                <input
                    className={className}
                    name={props.name}
                    id={`${id}-input`}
                    ref={ref}
                    inputMode={inputMode}
                    value={props.value}
                    autoFocus={autoFocus}
                    autoComplete={autoComplete}
                    autoCapitalize='off'
                    type={password ? 'password' : undefined}
                    disabled={props.disabled}
                    // hack with empty string to make label work when no placeholder at all
                    placeholder={placeholder}
                    onChange={onChange}
                    onFocus={handleOnFocus}
                    onBlur={handleOnBlur}
                    onClick={props.onClick}
                    onKeyDown={props.onKeyDown}
                    onCompositionStart={props.onCompositionStart}
                    onCompositionEnd={props.onCompositionEnd}
                    onCompositionUpdate={props.onCompositionUpdate}
                />
                <label htmlFor={id}>{props.label}</label>
                {props.type === 'password' && showPasswordEye ? (
                    <div
                        className={cn(styles.passwordButton, styles.ltr, {
                            [styles.hidden]: !props.value
                        })}
                        onClick={() => setPassword(value => !value)}
                    >
                        <SVG name={password ? 'eyeSlash' : 'eye'} className={styles.eye} />
                    </div>
                ) : null}
                {props.hint && <>
                    <div ref={hintRef} data-tip data-for={props.name} className={styles.hint}>
                        ?
                    </div>
                    <ReactTooltip
                        id={`${id}-tooltip`}
                        className={'tooltip'}
                        place={tooltipPlace()}
                        effect="solid"
                    >
                        {props.hint}
                    </ReactTooltip>
                </>}
            </div >
        )
    })

export default InternalInput