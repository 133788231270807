import { withTransaction } from '@elastic/apm-rum-react'
import { FC, useMemo } from 'react'
import { useTranslation } from 'react-i18next'
import { useAppState } from '../../../../utils/hooks'
import PageLayout from '../../../PageLayout'
import DashboardSideBar from '../../Dashboard/DashboardSideBar'
import BaseView from '../Base'
import Card from './Card'


const Layout: FC<{
    stickWarning?: boolean
    className?: string
}> = ({ children, className, stickWarning }) => {
    const { t } = useTranslation()
    const { userInsidePortal, applicationToCreate } = useAppState()

    const title = useMemo(() => {
        switch (applicationToCreate) {
            case 'partner': return t('ib:ib.applicationTitle')
            default: return t('dashboard.createLive')
        }
    }, [applicationToCreate, t])

    return userInsidePortal
        ? (<PageLayout sidebar={<DashboardSideBar />}>
            <Card title={title}>
                {children}
            </Card>
        </PageLayout>)
        : (<BaseView className={className}
            stickWarning={stickWarning}
        >
            {children}
        </BaseView>)
}


export default withTransaction('Layout', 'component')(Layout)