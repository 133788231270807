import React, { useState, useMemo, useCallback } from 'react'
import { useTranslation } from 'react-i18next'

import Modal from '../Modal'
import Input from '../Input'

import styles from './style.module.scss'
import { appConfig } from '../../../config'
import { __STORAGE } from '../../../utils/enums'

const useCaptcha = (onSubmit: () => void) => {
  const [isCaptchaShown, setIsCaptchaShown] = useState(false)
  const [captcha, setCaptcha] = useState<string>()

  const closeCaptcha = useCallback((value?: string) => {
    setCaptcha(value)
    setIsCaptchaShown(false)
  }, [])

  const showCaptcha = useCallback(() => {
    setIsCaptchaShown(true)
    setCaptcha('')
  }, [])

  const handleOKeyClick = useCallback((value: string) => {
    closeCaptcha(value)
    onSubmit()
  }, [onSubmit, closeCaptcha])

  const captchaModal = isCaptchaShown ? (
    <CaptchaModal
      onModalClose={closeCaptcha}
      onOkeyClick={handleOKeyClick}
    />
  ) : null

  return { closeCaptcha, showCaptcha, captcha, isCaptchaShown, captchaModal }
}

interface CaptchaModalProps {
  onModalClose(): void
  onOkeyClick(value: string): void
}

const CaptchaModal = ({ onModalClose, onOkeyClick }: CaptchaModalProps) => {
  const [captcha, setCaptcha] = useState('')
  const [error, setError] = useState('')
  const { t } = useTranslation()
  const handleSubmit = (e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault()
    if (!captcha) {
      setError(t('common:challengeRequired'))
    } else {
      onOkeyClick(captcha)
    }
  }
  const captchaUrl = useMemo(() => `${appConfig.CAPTCHA_URL}?sessionId=${localStorage[__STORAGE.session]}&v=${Date.now()}`, [])
  return (
    <Modal
      isOpen
      onModalClose={() => onModalClose()}
      cardClassName={styles['modal-wrapper']}
      className={styles['modal']}
    >
      <form autoComplete="off" className={styles['captcha-modal']} onSubmit={handleSubmit}>
        <img
          src={captchaUrl}
          alt="captchaImg"
        />
        <Input
          caption={t('common:challengeCaption')}
          name="challenge"
          wrapperClassName={styles.input}
          value={captcha}
          onChange={(e) => setCaptcha(e.target.value)}
          autoFocus
          validation={error}
        />
        <div className={styles.buttons}>
          <button
            className="link"
            type="button"
            onClick={(_: any) => onModalClose()}
          >
            {t(`common:cancel`)}
          </button>
          <button
            className="green"
            type="submit"
          >
            {t(`common:ok`)}
          </button>
        </div>
      </form>
    </Modal>
  )
}

export { useCaptcha }
export default CaptchaModal