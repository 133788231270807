import classNames from 'classnames/bind'
import { FC, useMemo } from 'react'
import { useTranslation } from 'react-i18next'

import qrCode from '../../../resources/images/ThinkPortal_app.png'
import { isAndroid } from '../../../utils/constants/browser'
import { useOrganization, useScreen } from '../../../utils/hooks'
import { AppStoreButton, GooglePlayButton } from '../../../utils/StoresButtons'
import { DownloadButton, QRCode } from '../../Container/Dashboard/Downloads/components'

import styles from './portal-app.module.scss'

const cx = classNames.bind(styles)

const androidUrl = 'https://play.google.com/store/apps/details?id=com.thinkmarkets.cportal&hl=en_US&gl=US'
const iOsUrl = 'https://apps.apple.com/mu/app/thinkmarkets-portal/id6450599547'

interface PortalAppProps {
    withText?: boolean
    className?: string
    qrClassName?: string
}

const usePortalAppMobileButton = () => {
    return useMemo(() => {
        return <DownloadButton
            image={isAndroid ? <GooglePlayButton /> : <AppStoreButton />}
            link={isAndroid ? androidUrl : iOsUrl}
        />
    }, [])
}

const PortalApp: FC<PortalAppProps> = ({ withText = true, className, qrClassName }) => {
    const { t } = useTranslation()
    const { isMobile } = useScreen()
    const { isTMJP } = useOrganization()
    const mobileButton = usePortalAppMobileButton()

    const content = useMemo(() => {
        return isMobile
            ? <>
                {mobileButton}
                {withText && t('common:portalApp')}
            </>
            : <>
                <QRCode image={qrCode} text={false} classname={cx('qr', qrClassName)} />
                {withText && t('common:portalApp')}
            </>
    }, [isMobile, mobileButton, withText, t, qrClassName])

    return isTMJP
        ? null
        : <div className={cx('qrCode', className, { 'mobile': isMobile, 'desktop': !isMobile })}>
            {content}
        </div>

}

export default PortalApp
export { usePortalAppMobileButton }