import React, { ComponentProps, VFC } from "react"
import { ReactComponent as NewAccount } from '../../../resources/images/edit-plus-circle-outline-black.svg'
import { ReactComponent as DepositWallet } from '../../../resources/images/deposit-wallet.svg'
import { ReactComponent as TransactionHistory } from '../../../resources/images/transaction_history.svg'
import { ReactComponent as BasicDone } from '../../../resources/images/basic-done-green.svg'
import { ReactComponent as TakePhoto } from '../../../resources/images/basic-image-alt.svg'
import { ReactComponent as FileFolder } from '../../../resources/images/file-folder-plus.svg'
import { ReactComponent as InfoCircle } from '../../../resources/images/attention-info-circle.svg'
import { ReactComponent as Download } from '../../../resources/images/basic-download.svg'
import { ReactComponent as EmptyRatingStar } from '../../../resources/images/empty-rating-star.svg'
import { ReactComponent as FilledRatingStar } from '../../../resources/images/filled-rating-star.svg'
import { ReactComponent as Chevron } from '../../../resources/images/arrow-chevron-left.svg'
import { ReactComponent as ChevronLeft } from '../../../resources/images/chevron-left.svg'
import { ReactComponent as ChevronRight } from '../../../resources/images/chevron-right.svg'
import { ReactComponent as Arrow } from '../../../resources/images/arrow-short-left.svg'
import { ReactComponent as UserIcon } from '../../../resources/images/user-icon.svg'
import { ReactComponent as DesktopIcon } from '../../Container/Dashboard/Downloads/images/Desktop.svg'
import { ReactComponent as MobileIcon } from '../../Container/Dashboard/Downloads/images/Mobile.svg'
import { ReactComponent as CongratsDesktop } from '../../../resources/images/desktop.svg'
import { ReactComponent as CongratsMobile } from '../../../resources/images/mobile.svg'
import { ReactComponent as WebIcon } from '../../Container/Dashboard/Downloads/images/Web.svg'
import { ReactComponent as Exclamation } from '../../../resources/images/exclamation.svg'
import { ReactComponent as Checkmark } from '../../../resources/images/checkmark.svg'
import { ReactComponent as ArrowCircleLeft } from '../../../resources/images/arrow-circle-left.svg'
import { ReactComponent as ArrowCircleRight } from '../../../resources/images/arrow-circle-right.svg'
import { ReactComponent as BankCardIcon } from '../../../resources/images/bank-card.svg'
import { ReactComponent as BankIcon } from '../../../resources/images/bank.svg'
import { ReactComponent as CoinIcon } from '../../../resources/images/coin.svg'
import { ReactComponent as Cross } from '../../../resources/images/cross.svg'
import { ReactComponent as ImageStub } from '../../../resources/images/document.svg'
import { ReactComponent as PassReset } from '../../../resources/images/pass_reset.svg'
import { ReactComponent as Close } from '../../../resources/images/menu-close-big.svg'
import { ReactComponent as Bell } from '../../../resources/images/empty-notification-bell.svg'
import { ReactComponent as Search } from '../../../resources/images/search-icon.svg'
import { ReactComponent as Plus } from '../../../resources/images/edit-plus-circle-outline.svg'
import { ReactComponent as Trash } from '../../../resources/images/basic-trash-full.svg'
import { ReactComponent as AccountPending } from '../../../resources/images/account-pending.svg'
import { ReactComponent as AccountPendingDark } from '../../../resources/images/account-pending-dark.svg'
import { ReactComponent as DepositAlert } from '../../../resources/images/deposit-alert.svg'
import { ReactComponent as DepositAlertDark } from '../../../resources/images/deposit-alert-dark.svg'
import { ReactComponent as Unsecure } from '../../../resources/images/unsecure.svg'
import { ReactComponent as UnsecureDark } from '../../../resources/images/unsecure-dark.svg'
import { ReactComponent as UserDenied } from '../../../resources/images/user-denied.svg'
import { ReactComponent as UserNew } from '../../../resources/images/user-new.svg'
import { ReactComponent as Filters } from '../../../resources/images/basic-slider.svg'
import { ReactComponent as GreenCalendar } from '../../../resources/images/green-calendar.svg'
import { ReactComponent as GreenMail } from '../../../resources/images/mail.svg'
import { ReactComponent as Mail } from '../../../resources/images/mail-icon.svg'
import { ReactComponent as MailOpen } from '../../../resources/images/mail_open.svg'
import { ReactComponent as MailOpenDark } from '../../../resources/images/mail_open_dark.svg'
import { ReactComponent as EmailSent } from '../../../resources/images/email-sent.svg'
import { ReactComponent as Users } from '../../../resources/images/users.svg'
import { ReactComponent as RiskWarning } from '../../../resources/images/risk-warning.svg'
import { ReactComponent as AppDenied } from '../../../resources/images/application-denied.svg'
import { ReactComponent as Calendar } from '../../../resources/images/calendar.svg'
import { ReactComponent as Alert } from '../../../resources/images/alert.svg'
import { ReactComponent as Transfer } from '../../../resources/images/transfer.svg'
import { ReactComponent as QuestionMark } from '../../../resources/images/question-mark.svg'
import { ReactComponent as Copy } from '../../../resources/images/copy.svg'
import { ReactComponent as Edit } from '../../../resources/images/edit.svg'
import { ReactComponent as Pause } from '../../../resources/images/pause.svg'
import { ReactComponent as Exit } from '../../../resources/images/basic-exit.svg'
import { ReactComponent as AttentionWarning } from '../../../resources/images/attention-warning-outline.svg'
import { ReactComponent as ErrorOutline } from '../../../resources/images/error_outline.svg'
import { ReactComponent as CircleCheck } from '../../../resources/images/circle-check.svg'
import { ReactComponent as UserMini } from '../../../resources/images/user-mini.svg'
import { ReactComponent as Lock } from '../../../resources/images/lock.svg'
import { ReactComponent as Server } from '../../../resources/images/server-line.svg'
import { ReactComponent as Camera } from '../../../resources/images/camera.svg'
import { ReactComponent as PdfFile } from '../../../resources/images/pdf-file.svg'
import { ReactComponent as ChevronDown } from '../../../resources/images/chevron-down.svg'
import { ReactComponent as CreditCard } from '../../../resources/images/credit-card.svg'
import { ReactComponent as CircleCross } from '../../../resources/images/circle-cross.svg'
import { ReactComponent as Clock } from '../../../resources/images/basic-clock.svg'
import { ReactComponent as MoneyBill } from '../../../resources/images/money-bill.svg'
import { ReactComponent as Pencil } from '../../../resources/images/pencil.svg'
import { ReactComponent as PortalApp } from '../../../resources/images/platforms/tt-logo-black.svg'
import { ReactComponent as Eye } from '../../../resources/images/eye.svg'
import { ReactComponent as EyeSlash } from '../../../resources/images/eye-slash.svg'
import { ReactComponent as AccountCreated } from '../../../resources/images/created-1.svg'
import { ReactComponent as Devices } from '../../../resources/images/devices.svg'
import { ReactComponent as UserIdCard } from '../../../resources/images/user-id-card.svg'
import { ReactComponent as Help } from '../../../resources/images/help.svg'
import { ReactComponent as Phone } from '../../../resources/images/phone-icon.svg'
import { ReactComponent as Chart } from '../../../resources/images/chart.svg'
import { ReactComponent as Timer } from '../../../resources/images/time.svg'
import { ReactComponent as Logout } from '../../../resources/images/logout.svg'
import { ReactComponent as TradersGym } from '../../../resources/images/traders-gym.svg'
import { ReactComponent as SignalCentre } from '../../../resources/images/signal-centre.svg'
import { ReactComponent as Support } from '../../../resources/images/support.svg'
import { ReactComponent as DrawingTool } from '../../../resources/images/drawing-tool.svg'
import { ReactComponent as Watch } from '../../../resources/images/watch.svg'
import { ReactComponent as IB } from '../../../resources/images/ib.svg'
import { ReactComponent as SolidPlay } from '../../../resources/images/solid-play.svg'
import { ReactComponent as SaphireTier } from '../../../resources/images/saphire_tier.svg'
import { ReactComponent as RubyTier } from '../../../resources/images/ruby_tier.svg'
import { ReactComponent as DiamondTier } from '../../../resources/images/diamond_tier.svg'
import { ReactComponent as ThinkCopy } from '../../Container/Dashboard/CopyTrading/images/thinkCopy-app-card.svg'
import { ReactComponent as ThinkCopyDark } from '../../Container/Dashboard/CopyTrading/images/card-dark.svg'
import { ReactComponent as SmartCharts } from '../../../resources/images/smartchart.svg'

import { useThemes } from "../../../utils/themes"

export type SVGName =
    'accountPending' |
    'arrow' |
    'arrowCircleLeft' |
    'arrowCircleRight' |
    'bankCardIcon' |
    'bankIcon' |
    'saphire_tier' |
    'ruby_tier' |
    'diamond_tier' |
    'basicDone' |
    'bell' |
    'checkmark' |
    'chevron' |
    'chevronLeft' |
    'chevronRight' |
    'close' |
    'coinIcon' |
    'depositAlert' |
    'depositWallet' |
    'desktop' |
    'download' |
    'emptyRatingStar' |
    'exclamation' |
    'fileFolder' |
    'filledRatingStar' |
    'filters' |
    'greenCalendar' |
    'imageStub' |
    'infoCircle' |
    'mobile' |
    'newAccount' |
    'passReset' |
    'plus' |
    'search' |
    'takePhoto' |
    'transactionHistory' |
    'trash' |
    'unsecure' |
    'user' |
    'web' |
    'greenMail' |
    'mailOpen' |
    'emailSent' |
    'riskWarning' |
    'appDenied' |
    'transfer' |
    'questionMark' |
    'copy' |
    'edit' |
    'pause' |
    'exit' |
    'attentionWarning' |
    'alert' |
    'errorOutline' |
    'calendar' |
    'circleCheck' |
    'userMini' |
    'lock' |
    'server' |
    'camera' |
    'pdfFile' |
    'cross' |
    'thinkCopy' |
    'chevronDown' |
    'creditCard' |
    'circleCross' |
    'clock' |
    'moneyBill' |
    'pencil' |
    'eye' |
    'eyeSlash' |
    'devices' |
    'userIdCard' |
    'mail' |
    'help' |
    'phone' |
    'chart' |
    'timer' |
    'logout' |
    'drawingTool' |
    'tradersGym' |
    'signalCentre' |
    'support' |
    'portalApp' |
    'userDenied' |
    'userNew' |
    'users' |
    'accountCreated' |
    'congratsDesktop' |
    'congratsMobile' |
    'watch' |
    'solidPlay' |
    'ib' |
    'smartchart'


type SvgProps = ComponentProps<'svg'> & {
    name?: SVGName | null
}

const SVG: VFC<SvgProps> = ({ name, ...rest }) => {
    const { theme } = useThemes()

    if (!name) return null

    if (theme === 'light') {
        switch (name) {
            case 'newAccount': return <NewAccount {...rest} />
            case 'depositWallet': return <DepositWallet {...rest} />
            case 'transactionHistory': return <TransactionHistory {...rest} />
            case 'basicDone': return <BasicDone {...rest} />
            case 'takePhoto': return <TakePhoto {...rest} />
            case 'fileFolder': return <FileFolder {...rest} />
            case 'infoCircle': return <InfoCircle {...rest} />
            case 'download': return <Download {...rest} />
            case 'emptyRatingStar': return <EmptyRatingStar {...rest} />
            case 'filledRatingStar': return <FilledRatingStar {...rest} />
            case 'chevron': return <Chevron {...rest} />
            case 'chevronLeft': return <ChevronLeft {...rest} />
            case 'chevronRight': return <ChevronRight {...rest} />
            case 'arrow': return <Arrow {...rest} />
            case 'user': return <UserIcon {...rest} />
            case 'desktop': return <DesktopIcon {...rest} />
            case 'mobile': return <MobileIcon {...rest} />
            case 'web': return <WebIcon {...rest} />
            case 'exclamation': return <Exclamation {...rest} />
            case 'checkmark': return <Checkmark {...rest} />
            case 'arrowCircleLeft': return <ArrowCircleLeft {...rest} />
            case 'arrowCircleRight': return <ArrowCircleRight {...rest} />
            case 'bankCardIcon': return <BankCardIcon {...rest} />
            case 'bankIcon': return <BankIcon {...rest} />
            case 'coinIcon': return <CoinIcon {...rest} />
            case 'imageStub': return <ImageStub {...rest} />
            case 'passReset': return <PassReset {...rest} />
            case 'close': return <Close {...rest} />
            case 'bell': return <Bell {...rest} />
            case 'search': return <Search {...rest} />
            case 'plus': return <Plus {...rest} />
            case 'trash': return <Trash {...rest} />
            case 'accountPending': return <AccountPending {...rest} />
            case 'depositAlert': return <DepositAlert {...rest} />
            case 'unsecure': return <Unsecure {...rest} />
            case 'filters': return <Filters {...rest} />
            case 'greenCalendar': return <GreenCalendar {...rest} />
            case 'greenMail': return <GreenMail {...rest} />
            case 'mailOpen': return <MailOpen {...rest} />
            case 'emailSent': return <EmailSent {...rest} />
            case 'riskWarning': return <RiskWarning {...rest} />
            case 'appDenied': return <AppDenied {...rest} />
            case 'transfer': return <Transfer {...rest} />
            case 'questionMark': return <QuestionMark {...rest} />
            case 'copy': return <Copy {...rest} />
            case 'edit': return <Edit {...rest} />
            case 'pause': return <Pause {...rest} />
            case 'exit': return <Exit {...rest} />
            case 'attentionWarning': return <AttentionWarning {...rest} />
            case 'alert': return <Alert {...rest} />
            case 'errorOutline': return <ErrorOutline {...rest} />
            case 'calendar': return <Calendar {...rest} />
            case 'circleCheck': return <CircleCheck {...rest} />
            case 'userMini': return <UserMini {...rest} />
            case 'lock': return <Lock {...rest} />
            case 'server': return <Server {...rest} />
            case 'camera': return <Camera {...rest} />
            case 'pdfFile': return <PdfFile {...rest} />
            case 'chevronDown': return <ChevronDown {...rest} />
            case 'cross': return <Cross {...rest} />
            case 'thinkCopy': return <ThinkCopy {...rest} />
            case 'creditCard': return <CreditCard {...rest} />
            case 'circleCross': return <CircleCross {...rest} />
            case 'clock': return <Clock {...rest} />
            case 'moneyBill': return <MoneyBill {...rest} />
            case 'pencil': return <Pencil {...rest} />
            case 'eye': return <Eye {...rest} />
            case 'eyeSlash': return <EyeSlash {...rest} />
            case 'devices': return <Devices {...rest} />
            case 'userIdCard': return <UserIdCard {...rest} />
            case 'mail': return <Mail {...rest} />
            case 'help': return <Help {...rest} />
            case 'phone': return <Phone {...rest} />
            case 'chart': return <Chart {...rest} />
            case 'timer': return <Timer {...rest} />
            case 'logout': return <Logout {...rest} />
            case 'drawingTool': return <DrawingTool {...rest} />
            case 'tradersGym': return <TradersGym {...rest} />
            case 'signalCentre': return <SignalCentre {...rest} />
            case 'support': return <Support {...rest} />
            case 'portalApp': return <PortalApp {...rest} />
            case 'userDenied': return <UserDenied {...rest} />
            case 'userNew': return <UserNew {...rest} />
            case 'users': return <Users {...rest} />
            case 'accountCreated': return <AccountCreated {...rest} />
            case 'congratsDesktop': return <CongratsDesktop {...rest} />
            case 'congratsMobile': return <CongratsMobile {...rest} />
            case 'watch': return <Watch {...rest} />
            case 'solidPlay': return <SolidPlay {...rest} />
            case 'ib': return <IB {...rest} />
            case 'saphire_tier': return <SaphireTier {...rest} />
            case 'diamond_tier': return <DiamondTier {...rest} />
            case 'ruby_tier': return <RubyTier {...rest} />
            case 'smartchart': return <SmartCharts {...rest} />
        }
    } else {
        switch (name) {
            case 'newAccount': return <NewAccount {...rest} />
            case 'depositWallet': return <DepositWallet {...rest} />
            case 'transactionHistory': return <TransactionHistory {...rest} />
            case 'basicDone': return <BasicDone {...rest} />
            case 'takePhoto': return <TakePhoto {...rest} />
            case 'fileFolder': return <FileFolder {...rest} />
            case 'infoCircle': return <InfoCircle {...rest} />
            case 'download': return <Download {...rest} />
            case 'emptyRatingStar': return <EmptyRatingStar {...rest} />
            case 'filledRatingStar': return <FilledRatingStar {...rest} />
            case 'chevron': return <Chevron {...rest} />
            case 'chevronLeft': return <ChevronLeft {...rest} />
            case 'chevronRight': return <ChevronRight {...rest} />
            case 'arrow': return <Arrow {...rest} />
            case 'user': return <UserIcon {...rest} />
            case 'desktop': return <DesktopIcon {...rest} />
            case 'mobile': return <MobileIcon {...rest} />
            case 'web': return <WebIcon {...rest} />
            case 'exclamation': return <Exclamation {...rest} />
            case 'checkmark': return <Checkmark {...rest} />
            case 'arrowCircleLeft': return <ArrowCircleLeft {...rest} />
            case 'arrowCircleRight': return <ArrowCircleRight {...rest} />
            case 'bankCardIcon': return <BankCardIcon {...rest} />
            case 'bankIcon': return <BankIcon {...rest} />
            case 'coinIcon': return <CoinIcon {...rest} />
            case 'imageStub': return <ImageStub {...rest} />
            case 'passReset': return <PassReset {...rest} />
            case 'close': return <Close {...rest} />
            case 'bell': return <Bell {...rest} />
            case 'search': return <Search {...rest} />
            case 'plus': return <Plus {...rest} />
            case 'trash': return <Trash {...rest} />
            case 'accountPending': return <AccountPendingDark {...rest} />
            case 'depositAlert': return <DepositAlertDark {...rest} />
            case 'unsecure': return <UnsecureDark {...rest} />
            case 'filters': return <Filters {...rest} />
            case 'greenCalendar': return <GreenCalendar {...rest} />
            case 'greenMail': return <GreenMail {...rest} />
            case 'mailOpen': return <MailOpenDark {...rest} />
            case 'emailSent': return <EmailSent {...rest} />
            case 'riskWarning': return <RiskWarning {...rest} />
            case 'appDenied': return <AppDenied {...rest} />
            case 'transfer': return <Transfer {...rest} />
            case 'questionMark': return <QuestionMark {...rest} />
            case 'copy': return <Copy {...rest} />
            case 'edit': return <Edit {...rest} />
            case 'pause': return <Pause {...rest} />
            case 'exit': return <Exit {...rest} />
            case 'attentionWarning': return <AttentionWarning {...rest} />
            case 'alert': return <Alert {...rest} />
            case 'errorOutline': return <ErrorOutline {...rest} />
            case 'calendar': return <Calendar {...rest} />
            case 'circleCheck': return <CircleCheck {...rest} />
            case 'userMini': return <UserMini {...rest} />
            case 'lock': return <Lock {...rest} />
            case 'server': return <Server {...rest} />
            case 'camera': return <Camera {...rest} />
            case 'pdfFile': return <PdfFile {...rest} />
            case 'chevronDown': return <ChevronDown {...rest} />
            case 'cross': return <Cross {...rest} />
            case 'thinkCopy': return <ThinkCopyDark {...rest} />
            case 'creditCard': return <CreditCard {...rest} />
            case 'circleCross': return <CircleCross {...rest} />
            case 'clock': return <Clock {...rest} />
            case 'moneyBill': return <MoneyBill {...rest} />
            case 'pencil': return <Pencil {...rest} />
            case 'eye': return <Eye {...rest} />
            case 'eyeSlash': return <EyeSlash {...rest} />
            case 'devices': return <Devices {...rest} />
            case 'userIdCard': return <UserIdCard {...rest} />
            case 'mail': return <Mail {...rest} />
            case 'help': return <Help {...rest} />
            case 'phone': return <Phone {...rest} />
            case 'chart': return <Chart {...rest} />
            case 'timer': return <Timer {...rest} />
            case 'logout': return <Logout {...rest} />
            case 'drawingTool': return <DrawingTool {...rest} />
            case 'tradersGym': return <TradersGym {...rest} />
            case 'signalCentre': return <SignalCentre {...rest} />
            case 'support': return <Support {...rest} />
            case 'portalApp': return <PortalApp {...rest} />
            case 'userDenied': return <UserDenied {...rest} />
            case 'userNew': return <UserNew {...rest} />
            case 'users': return <Users {...rest} />
            case 'accountCreated': return <AccountCreated {...rest} />
            case 'congratsDesktop': return <CongratsDesktop {...rest} />
            case 'congratsMobile': return <CongratsMobile {...rest} />
            case 'watch': return <Watch {...rest} />
            case 'solidPlay': return <SolidPlay {...rest} />
            case 'ib': return <IB {...rest} />
            case 'saphire_tier': return <SaphireTier {...rest} />
            case 'diamond_tier': return <DiamondTier {...rest} />
            case 'ruby_tier': return <RubyTier {...rest} />
            case 'smartchart': return <SmartCharts {...rest} />
        }

    }

}

export default SVG
