import { createSlice } from '@reduxjs/toolkit';

export enum AccountView {
    Login,
    Loading,
    CreateAccountStep1,
    CreateAccountStep2,
    CreateAccountStep3
}

export type LoginState = {
    view: AccountView;
};

const initialState: LoginState = {
    view: AccountView.Loading
};

const slice = createSlice({
    name: 'login',
    initialState: initialState,
    reducers: {
        setView: (state, action) => {
            state.view = action.payload
        }
    }
});

export const { setView } = slice.actions;

export default slice.reducer;