import cn from 'classnames'
import React, { useMemo } from 'react'
import { useTranslation } from 'react-i18next'
import { useAppState, useDangerousHTML, useScreen } from '../../../../utils/hooks'
import { Copyright, Version } from '../../../Footer'
import styles from './login.module.scss'

const ls = 'account:login.footer.'

const Footer = () => {
    const { t } = useTranslation()
    const { d } = useDangerousHTML()


    return <>
        <div className={styles.footer}>
            {/* {phones} */}
            {d(t(`${ls}text`))}
            <Copyright />
            <Version />
        </div>
    </>
}


export default Footer