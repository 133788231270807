import cn from "classnames"
import { useCallback, useEffect } from 'react'

import Collapse from '../../../Collapse'
import SVG from '../../SVG'

import { MenuItem, useMatchUrlUtils, useMenuEntryCaption, useRetrieveIcon } from '../utils'

import styles from "./submenu.module.scss"

export interface SubMenuProps<T> {
  languagePrefix: string
  item: MenuItem<T>
  children?: React.ReactNode
  openedMenu: string | null
  setOpenedMenu(value: string | null): void
  onMenuItemClick: () => void
}

const SubMenu = <T extends string>(props: SubMenuProps<T>) => {
  const { languagePrefix, onMenuItemClick, item, children, openedMenu, setOpenedMenu } = props
  const { checkForAnyChildMatchUrl } = useMatchUrlUtils()
  const retrieveIcon = useRetrieveIcon(item)
  const caption = useMenuEntryCaption(languagePrefix, item, true)

  const hasActiveChild = checkForAnyChildMatchUrl(item.children || [])
  const isOpen = !item.disabled && openedMenu === item.name
  const active = !item.disabled && hasActiveChild
  const icon = retrieveIcon(active, item.disabled)

  const handleHeaderClick = useCallback((item: MenuItem<T>) => {
    if (!item.disabled)
      setOpenedMenu(openedMenu !== item.name ? item.name : null)
    onMenuItemClick()
  }, [onMenuItemClick, openedMenu, setOpenedMenu])

  useEffect(() => {
    if (hasActiveChild) setOpenedMenu(item.name)
  }, [hasActiveChild, setOpenedMenu, item.name])

  const headerClasses = cn(styles.submenu,
    {
      [styles.open]: isOpen,
      [styles.active]: active,
      [styles.disabled]: item.disabled
    })

  const renderSubmenu = () => (
    <div className={cn({ [styles['active-submenu']]: hasActiveChild })}>
      {children}
    </div>
  )

  return (
    <div>
      <div
        className={headerClasses}
        onClick={() => handleHeaderClick(item)}
      >
        <div className={styles.caption}>
          <span className={styles.icon}>
            {icon ?
              <img src={icon} alt={`icon for ${item.name}`} /> : null}
          </span>
          <span data-text={caption}>{caption}</span>
        </div>
        <div className={styles.chevron}>
          <SVG name="chevronDown" />
        </div>
      </div>
      {!item.disabled
        ? <Collapse open={isOpen}>{renderSubmenu()}</Collapse>
        : isOpen ? renderSubmenu() : null
      }
    </div>
  )
}

export default SubMenu
