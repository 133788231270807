import { apm, init as initApm } from '@elastic/apm-rum'
import { appConfig } from '../config'
import versionFile from '../VERSION.json'
import { __STORAGE } from './enums'
import { LoginResponse, UserProfile } from './types'
import Utils from './utils'


export const initAPM = () => {
    if (appConfig.APM_URL) {
        initApm({
            serviceName: 'TP3',
            serverUrl: appConfig.APM_URL,
            environment: appConfig.ENV,
            serviceVersion: `${versionFile.version}-${versionFile.build}` || '',
            breakdownMetrics: true,
            distributedTracingOrigins: [
                'https://portalappqa.thinkmarkets.com',
                'https://portalmstv2.thinkmarkets.com',
                'https://portalmuatv2.thinkmarkets.com',
            ],
            // logLevel: 'debug',
        })
    }
    const info = Utils.loadEncryptedObject<LoginResponse>(__STORAGE.userLoginInfo)
    if (Object.entries(info).length > 0) {
        apm.setUserContext({
            id: info?.id,
            username: `${info?.first_name} ${info?.last_name}`,
            email: Utils.loadEncryptedString(__STORAGE.user)
        })
    }
}

export const setUserContextForAPM = (user: UserProfile | string) => {
    if (typeof user === 'string')
        apm.setUserContext({
            email: user
        })
    else apm.setUserContext({
        id: user.id,
        username: user.fullName,
        email: user.email
    })
}
