import React, { ChangeEvent, FocusEvent, memo, PropsWithChildren, useCallback, useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { StringFunc } from "../../../utils/types"
import Utils from '../../../utils/utils'
import InternalInput, { InputType } from '../InternalInput'
import Wrapper from '../ItemWrapper'

type InputProps = {
    name: string
    className?: string
    tip?: React.ReactNode
    wrapperClassName?: string
    errorClassName?: string
    mainAreaClassName?: string
    value?: string
    caption?: string
    placeholder?: string
    autoComplete?: string
    disabled?: boolean
    noSpecialChars?: boolean
    type?: InputType
    validation?: string | React.ReactElement
    ltr?: boolean
    validate?: boolean
    optional?: boolean
    hint?: boolean | string
    validateSecure?: boolean
    showPasswordEye?: boolean
    autoFocus?: boolean
    isFloatedLabel?: boolean
    showError?: boolean
    numOfDigits?: number
    icon?: JSX.Element
    validationError?: string | StringFunc
    onChange?: (e: ChangeEvent<HTMLInputElement>) => void
    onBlur?: (name: string, value: any, e?: FocusEvent<HTMLInputElement>) => void
    onPlainBlur?: (e: FocusEvent<HTMLInputElement>) => void
    onFocus?: (e: FocusEvent<HTMLInputElement>) => void
    onClick?: (e: React.MouseEvent<HTMLInputElement>) => void
}



const Input = React.forwardRef<HTMLDivElement, PropsWithChildren<InputProps>>(
    (props, ref) => {

        const [error, seterror] = useState<string>('')

        const { onBlur, onPlainBlur, onChange, value, optional, disabled, validate, onFocus, onClick } = props
        const { type: typeProps, validationError: validationErrorProps } = props


        const { t } = useTranslation()

        const validationError = useCallback(() => {
            if (typeProps === 'email') {
                if (!value || !value.trim()) return t('common:errors:required')
                else if (!Utils.isValidEmail(value!))
                    return t('common:email.errors.notValid')
            }
            if (typeProps === 'password') {
                return Utils.validatePassword(value, t, props.validateSecure)
            }
            if (typeProps === 'integer') {
                if (!value) return validationErrorProps
                else if (!Utils.isValidInteger(value!))
                    return t('common:integer.notValid')
            }
            if (typeProps === 'id') {
                if (!value) return validationErrorProps
                else if (props.numOfDigits) {
                    const testExpr = new RegExp(`^[0-9]{${props.numOfDigits}}$`)
                    if (!testExpr.test(props.value!))
                        return t('common:id.notValidLength', { numOfDigits: props.numOfDigits })
                }
                else {
                    if (!/^\d$/.test(value!))
                        return t('common:id.notValid')
                }
            }
            if (props.noSpecialChars) {
                if (Utils.hasSpecialChars(value!))
                    return t('common:invalidChars')
            }
            if (validationErrorProps && typeof validationErrorProps === 'function') {
                return validationErrorProps()
            }
            if (!value && !props.optional && validationErrorProps) {
                return validationErrorProps
            }
            return !value && !props.optional
                ? t('common:errors.required') : ''
            // eslint-disable-next-line react-hooks/exhaustive-deps
        }, [props])

        const blur = useCallback((e: FocusEvent<HTMLInputElement>) => {
            if (!optional) seterror(validationError())
            if (onBlur) onBlur(props.name, props.value, e)
            if (onPlainBlur) onPlainBlur(e)
        }, [onBlur, onPlainBlur, optional, props.name, props.value, validationError])

        const change = useCallback((e: ChangeEvent<HTMLInputElement>) => {
            seterror('')
            if (onChange) {
                onChange(e)
            }
        }, [onChange])

        useEffect(() => {
            if (!props.optional) {
                let result = validationError()
                if (validate)
                    seterror(result)
            }
            // eslint-disable-next-line        
        }, [props])

        useEffect(() => {
            if (optional || disabled) seterror('')
        }, [disabled, optional])


        const inputError = props.validation !== undefined ? props.validation : error

        const type: InputType = props.type ? props.type : 'text'

        return (
            <Wrapper
                name={props.name}
                ref={ref}
                error={inputError}
                className={props.wrapperClassName}
                errorClassName={props.errorClassName}
                mainAreaClassName={props.mainAreaClassName}
                insideCaption={false}
                tip={props.tip}
                showError={props.showError}
                caption={props.caption}
            >
                <>
                    <InternalInput
                        className={props.className}
                        name={props.name}
                        value={props.value ? props.value : ''}
                        ltr={props.ltr}
                        // check if it's optional - not to show success state on initial empty input
                        isSucceeded={optional ? Boolean(props.value && validationError() === '') : validationError() === ''}
                        hasError={inputError !== ''}
                        placeholder={props.placeholder}
                        // label={props.caption}
                        disabled={props.disabled}
                        optional={props.optional}
                        // isFloatedLabel={props.isFloatedLabel}
                        type={type}
                        autoFocus={props.autoFocus}
                        autoComplete={props.autoComplete}
                        hint={props.hint}
                        showPasswordEye={props.showPasswordEye}
                        onChange={change}
                        onBlur={blur}
                        onClick={onClick}
                        onFocus={onFocus}
                    />
                    {props.icon ? props.icon : null}
                </>
            </Wrapper>
        )
    })

export default memo(Input)