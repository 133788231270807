import React from 'react'
import { useTranslation } from 'react-i18next'
export const IndividualTMSY = () => {
    const { t } = useTranslation()
    const prefix = 'footer:individual.TMSY'
    return <>
        <p>
            <strong>{t(`${prefix}.warning.header`)} </strong>
            {t(`${prefix}.warning.text`)}
        </p>
        <p>
            <strong>{t(`${prefix}.0.header`)} </strong>
            {t(`${prefix}.0.text1`)}
        </p>
        <p>{t(`${prefix}.0.text3`)}</p>
    </>
}
