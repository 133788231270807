import classNames from 'classnames/bind'
import React from 'react'
import { useTranslation } from 'react-i18next'
import styles from './style.module.scss'

const cx = classNames.bind(styles)

const NewBadge = () => {
    const { t } = useTranslation()
    return <span className={cx('badge')}>
        {t('common:new')}
    </span>
}

export default NewBadge