import { APIResponse } from './../../utils/types'
import { createSlice } from '@reduxjs/toolkit'

import * as Enums from '../../utils/enums'

type SliceParams = {
    name: string
    defaultData?: any
}

export type ApiSliceState = {
    state: Enums.RequestState
    data?: any
    request: any
    response: APIResponse<any>
    error?: string
}

function produceAsyncSlice(params: SliceParams) {
    return createSlice({
        name: params.name,
        initialState: {
            state: Enums.RequestState.None,
            data: params.defaultData,
            request: undefined,
            response: undefined,
            error: undefined
        },
        reducers: {
            clear: (state) => {
                state.state = Enums.RequestState.None
            },
            start: (state) => {
                state.state = Enums.RequestState.InProgress
            },
            success: (state, action) => {
                state.state = Enums.RequestState.Success
                state.data = action.payload
            },
            error: (state, action) => {
                state.state = Enums.RequestState.Error
                state.error = action.payload.error
                state.request = action.payload.request
                state.response = action.payload.response
            }
        }
    })
}

export const login = produceAsyncSlice({ name: 'login' })
export const logout = produceAsyncSlice({ name: 'logout' })
export const getAccounts = produceAsyncSlice({ name: 'getAccounts' })
export const createTradingAccount = produceAsyncSlice({ name: 'createTradingAccount' })
export const createAccount = produceAsyncSlice({ name: 'createAccount' })
export const getCountries = produceAsyncSlice({ name: 'getCountries' })
export const getProcessorsByAccount = produceAsyncSlice({ name: 'getProcessorsByAccount' })
export const getWithdrawalOptions = produceAsyncSlice({ name: 'getWithdrawalOptions' })
export const getUserCreditCards = produceAsyncSlice({ name: 'getUserCreditCards' })
export const getBankAccounts = produceAsyncSlice({ name: 'getBankAccounts' })
export const getAppInfo = produceAsyncSlice({ name: 'getAppInfo' })
export const getOrganizationSettings = produceAsyncSlice({ name: 'getOrganizationSettings' })
export const getAppStatus = produceAsyncSlice({ name: 'getAppStatus' })
export const sendLastField = produceAsyncSlice({ name: 'sendLastField' })


