import cn from 'classnames'
import React, { FC, useCallback, useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { IndexedObject } from '../../utils/types'
import Utils from '../../utils/utils'
import { hideGlobalLoader } from '../GlobalLoader'
import styles from './style.module.scss'

//expected URL
//payment-result.html#;status=DENIED&refId=5F1AA&transaction_id=tfx-12c87050-cc30-4104-81ad-6e2cf3abb345&amount=10.00&cy=JPY

type Params = {
    status: string
    refId: string
    transaction_id: string
    amount: string
    cy: string
    currency: string
}

const Page: FC<any> = () => {
    const { t } = useTranslation()
    const [params, setparams] = useState<Partial<Params>>({
        status: "-",
        refId: "-",
        transaction_id: "-",
        amount: "-",
        cy: "-"
    })

    useEffect(() => {
        try {
            hideGlobalLoader()
            if (window.location.hash.length) {
                var hasSemicolon = window.location.hash.indexOf(";") !== -1
                var p = window.location.hash.substring(hasSemicolon ? 2 : 1)
                    .split("&").reduce(function (result: IndexedObject, param) {
                        var [key, value] = param.split("=")
                        result[key] = value
                        return result
                    }, {})
                setparams(p)
            }
        } catch { }
    }, [])

    const status = params.status?.toLowerCase()

    const statusClass = cn({
        [styles.confirmed]: status === "confirmed",
        [styles.denied]: status === "denied"
    })

    const row = useCallback((label: string, value?: string, valueClass?: string) => {
        return <div className={styles.row}>
            <div className={styles.label}>{label}</div>
            <div className={cn(styles.value, valueClass)}>{value}</div>
        </div>
    }, [])

    return <div className={styles.page}>
        <div className={styles.header}>{t('dashboard.deposit.depositConfirmation')}</div>
        <div className={styles.data}>
            {row(t('dashboard.deposit.status'), t(`dashboard.deposit.payment.${status?.toUpperCase()}`), statusClass)}
            {row(t('dashboard.deposit.referenceID'), params.refId)}
            {row(t('dashboard.deposit.transactionID'), params.transaction_id)}
            {row(t('dashboard.deposit.amount'), params.amount)}
            {row(t('dashboard.transactionDetails.currency'),
                Utils.getCurrencySign(params.cy || params.currency))}
        </div>
    </div>
}


export default Page